import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createMemoryHistory } from "history";

import { routesAPI } from "./apis/routesAPI";
import { placesAPI } from "./apis/placesAPI";
import { timestampAPI } from "./apis/timestampAPI";
import { userAPI } from "./apis/userAPI";
import { settingsAPI } from "./apis/settingsAPI";
import { mailingListAPI } from "./apis/mailingListAPI";
import { purchasesAPI } from "./apis/purchasesAPI";
import { accessCodeAPI } from "./apis/accessCodeAPI";
import { userActionsAPI } from "./apis/userActionsAPI";
import { uriAPI } from "./apis/uriAPI";
import { offlineAPI } from "./apis/offlineAPI";

import { timestampReducer } from "./reducers/timestampReducer";
import { userReducer } from "./reducers/userReducer";
import { mapDataReducer } from "./reducers/mapDataReducer";
import { settingsReducer } from "./reducers/settingsReducer";
import { mailingListReducer } from "./reducers/mailingListReducer";
import { purchasesReducer } from "./reducers/purchasesReducer";
import { accessCodesReducer } from "./reducers/accessCodesReducer";
import { offlineReducer } from "./reducers/offlineReducer";

import getEnvVars from 'environment';
const envVars = getEnvVars();

export const history = createMemoryHistory();

const initialState = {};
const enhancers = [
];
const middleware = [
    thunk,
    routerMiddleware(history),
    timestampAPI,
    userAPI,
    routesAPI,
    placesAPI,
    settingsAPI,
    mailingListAPI,
    purchasesAPI,
    accessCodeAPI,
    userActionsAPI,
    uriAPI,
    offlineAPI
];

if (envVars.env === "dev") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
        enhancers.push(devToolsExtension());
    }
}

const rootReducer = combineReducers({
    router: connectRouter(history),
    settings: settingsReducer,
    timestamp: timestampReducer,
    user: userReducer,
    mapData: mapDataReducer,
    mailingList: mailingListReducer,
    purchases: purchasesReducer,
    accessCodes: accessCodesReducer,
    offline: offlineReducer
});

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
);

export default store;