import React, { useContext } from 'react';
import { Text, View, TouchableWithoutFeedback } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';

import { getPlaceMarkerStyles } from "styles/markers";
import { MarkerContext } from "./MarkerContext";
import { colors } from "styles/shared"
import LocalImage from '../shared/LocalImage';
import { BaseMarker } from './BaseMarker';
import { isWeb } from 'utils/platform';

const PlaceMarker = ({ place }) => {

    const {
        markerOnPress,
        tracksViewChanges,
        viewBox,
        getMarkerX
    } = useContext(MarkerContext);

    const { slug, title, subtitle, latlng, displayDir, zIndex = 1, categories, useLogo, logo } = place;
    
    const thisMarkerStyles = getPlaceMarkerStyles(displayDir);

    const onPress = () => markerOnPress({...place, color: colors.place});

    const markerX = getMarkerX(displayDir)
    let textY = 1;
    if (slug === "forbidden-vancouver") {
        textY = 0.5; // this could be moved to the place properties, but it's not high priority
    }
    return <>
        <BaseMarker
            key={`${slug}-marker-text`}
            coordinate={latlng}
            anchor={{x: markerX, y: textY}}
            style={{
                zIndex: (zIndex * 10) + 4
            }}
            onPress={onPress}
            tracksViewChanges={tracksViewChanges}
        >
            { renderMarkerText({ title, slug, subtitle, useLogo, logo, thisMarkerStyles, onPress}) }
        </BaseMarker>
        <BaseMarker
            key={`${slug}-marker`}
            coordinate={latlng}
            anchor={{x: 0.5, y: 1}}
            style={{
                ...thisMarkerStyles.marker,
                zIndex: (zIndex * 10) + 5
            }}
            onPress={onPress}
            tracksViewChanges={tracksViewChanges}
        >
            <View style={ isWeb ? thisMarkerStyles.markerContainerWeb : thisMarkerStyles.markerContainer}>
                <Svg
                    style={thisMarkerStyles.markerBG}
                    viewBox={viewBox}
                >
                    <Path d="M11.4577 47.8808C14.2545 50.8535 17.0432 53.2707 19.13 54.9439L24 58L28.87 54.9439C30.9568 53.2707 33.7455 50.8535 36.5423 47.8808C42.0378 42.0397 48 33.5964 48 24.1667C48 17.7573 45.4714 11.6104 40.9706 7.07825C36.4697 2.54612 30.3652 0 24 0C17.6348 0 11.5303 2.54612 7.02944 7.07825C2.52856 11.6104 0 17.7573 0 24.1667C0 33.5964 5.96223 42.0397 11.4577 47.8808Z"
                        fill={thisMarkerStyles.markerBG.color}
                        stroke="none"
                    />
                </Svg>
                { renderMarkerInner(categories[0] || "", thisMarkerStyles)}
            </View>
        </BaseMarker>
    </>
}
export default React.memo(PlaceMarker);

const renderMarkerText = ({title, slug, subtitle, useLogo, logo, thisMarkerStyles, onPress}) => {

    let showText = true;

    // if (
    //   renderDistance > 10 ||
    //   (longitudeDelta > 0.004023313522353078 && renderDistance > 6)
    // ) {
    //   showText = false;
    // }

    if (useLogo) {
        return <View style={isWeb ? thisMarkerStyles.logoFlyoutContainerWeb : thisMarkerStyles.logoFlyoutContainer}>
            <View style={{
                ...thisMarkerStyles.logoContainer,
                ...(isWeb && slug === "forbidden-vancouver" && {marginBottom:-28})
                // top: 10
            }}>
                <LocalImage
                    style={thisMarkerStyles.logo}
                    imageURL={logo}
                />
                <Text style={thisMarkerStyles.subHeader}>{subtitle}</Text>
            </View>
        </View>
    }

    if (showText) {
        return <View style={isWeb ? thisMarkerStyles.flyoutContainerWeb : thisMarkerStyles.flyoutContainer}>
            <TouchableWithoutFeedback onPress={onPress}>
                <View style={thisMarkerStyles.textContainer}>
                    <Text style={thisMarkerStyles.header}>{title}</Text>
                    <Text style={thisMarkerStyles.subHeader}>{subtitle}</Text>
                </View>
            </TouchableWithoutFeedback>
        </View>
    }
}

const renderMarkerInner = (category, thisMarkerStyles) => {
    let icon = "map-marker";

    if(category.indexOf("restaurant") > -1) {
        return <MaterialIcons name={"restaurant"} size={thisMarkerStyles.iconSize.height} style={thisMarkerStyles.icon} />
    } else if(category.indexOf("shopping") > -1) {
        return <FontAwesome name={"shopping-bag"} size={thisMarkerStyles.iconSize.height - 2} style={thisMarkerStyles.icon} />
    }

    return <FontAwesome name={icon} size={thisMarkerStyles.iconSize.height} style={thisMarkerStyles.icon} />
}