import { types } from "../types";
import Purchases from 'react-native-purchases';
import { SentryPlatform } from 'utils/sentry';

import { routeMocks } from "utils/iapMocks";
import { UserActionDuration } from "utils/userActions";
import getEnvVars from 'environment';

const envVars = getEnvVars();

export const purchasesAPI = () => next => action => {

    const loadOfferings = async () => {
        const uad = new UserActionDuration();
        try {
            const loadedOfferings = await Purchases.getOfferings();
            const duration = uad.duration;
            if (loadedOfferings.all !== null && Object.keys(loadedOfferings.all).length > 0) {  
                // Display current offering with offerings.current
                return next({
                    type: types.LOAD_OFFERINGS_SUCCESS,
                    offerings: { ...loadedOfferings.all },
                    duration
                });
            } else {
                if(envVars.env === "dev") {
                    return next({
                        type: types.LOAD_OFFERINGS_SUCCESS,
                        offerings: routeMocks,
                        duration
                    });
                } else {
                    next({
                        type: types.LOAD_OFFERINGS_FAILURE,
                        duration
                    });
                    SentryPlatform.captureMessage("Error loading offerings");
                    throw new Error("Error loading offerings");
                }
            }
        } catch (e) {
            const duration = uad.duration;
            console.log("LOAD OFFERINGS FAILURE");
            console.log(e);
            next({
                type: types.LOAD_OFFERINGS_FAILURE,
                duration
            });
            SentryPlatform.captureMessage("Error loading offerings");
            throw new Error("Error loading offerings");
        }
    }

    const loadPurchases = async () => {
        const uad = new UserActionDuration();
        try {
            const purchaserInfo = await Purchases.getCustomerInfo();
            const duration = uad.duration;
            if (purchaserInfo?.entitlements?.all) {
                // we only really need the key of the returned object for now, to know that it's purchased
                const keys = Object.keys(purchaserInfo.entitlements.all);
                return next({
                    type: types.LOAD_PURCHASES_SUCCESS,
                    purchases: keys,
                    duration,
                    userActionData: { purchases: keys }
                });
            } else {
                if(envVars.env === "dev") {
                    return next({
                        type: types.LOAD_PURCHASES_SUCCESS,
                        purchases: ["gastown_route"],
                        duration
                    });
                } else {
                    next({
                        type: types.LOAD_PURCHASES_FAILURE,
                        duration
                    });
                    SentryPlatform.captureMessage("Error loading purchases");
                    throw new Error("Error loading purchases");
                }
            }
            // access latest purchaserInfo
        } catch (e) {
            // Error fetching purchaser info
            console.log("IN PURCHASE ERROR");
            console.log(e);
            const duration = uad.duration;
            next({
                type: types.LOAD_PURCHASES_FAILURE,
                duration
            });
            SentryPlatform.captureMessage("Error loading purchases");
            throw new Error("Error loading purchases");
        }
    }

    const purchaseRoute = async({ purchasePackage }) => {
        const uad = new UserActionDuration();
        try {
            const { purchaserInfo } = await Purchases.purchasePackage(purchasePackage);
            if (typeof purchaserInfo.entitlements.active[purchasePackage.offeringIdentifier] !== "undefined") {
                const duration = uad.duration;
                return next({
                    type: types.PURCHASE_ROUTE_SUCCESS,
                    purchase: purchasePackage.offeringIdentifier,
                    duration,
                    userActionData: { purchase: purchasePackage.offeringIdentifier }
                });
            }
        } catch (e) {
            const duration = uad.duration;
            if (!e.userCancelled) {
                return next({
                    type: types.PURCHASE_ROUTE_FAILURE,
                    duration,
                    userActionData: { purchase: purchasePackage.offeringIdentifier }
                });
            } else {
                return next({
                    type: types.PURCHASE_ROUTE_CANCELLED,
                    duration,
                    userActionData: { purchase: purchasePackage.offeringIdentifier }
                });
            }
        }

    }

    const restorePurchases = async() => {
        const uad = new UserActionDuration();
        try {
            const restore = await Purchases.restorePurchases();
            const duration = uad.duration;
            // ... check restored purchaserInfo to see if entitlement is now active
            if (restore?.entitlements?.all) {
                // we only really need the key of the returned object for now, to know that it's purchased
                const keys = Object.keys(restore?.entitlements?.all);
                return next({
                    type: types.RESTORE_PURCHASES_SUCCESS,
                    purchases: keys,
                    duration,
                    userActionData: { purchases: keys }
                });
            }
        } catch (e) {
            console.log("RESTORE FAILED")
            console.log({e})
            const duration = uad.duration;
            next({
                type: types.RESTORE_PURCHASES_FAILURE,
                duration
            });
            throw new Error("Error restoring purchases");
        }
    }

    next(action);
    switch (action.type) {
        case types.LOAD_OFFERINGS: {
            loadOfferings();
            break;
        }
        case types.LOAD_PURCHASES: {
            loadPurchases();
            break;
        }
        case types.PURCHASE_ROUTE: {
            purchaseRoute(action);
            break;
        }
        case types.RESTORE_PURCHASES: {
            restorePurchases();
            break;
        }
        default:
            break;
    }
};