import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { isRetina } from "utils/isRetina";
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
    viewRow,
    colors,
    colorNames,
    dropShadow,
    screenContainer
} from "styles/shared";
import {
    listStyles
} from "styles/listStyles"
import { types } from "redux/types";
import { getLangTerm } from "lang";
import { Gear } from "../SVGs";
import { WalkingHeader } from  "./WalkingHeader";
import LocalImage from "../shared/LocalImage";

const headerThumbSize = isRetina ? "thumblarge" : "thumbmedium";

const headerStyles = StyleSheet.create({
    container: {
        position: "absolute",
        backgroundColor: "transparent",
        width: "100%", //win.width,
        flex: 1,
        zIndex: 1,
    },
    containerInner: {
        backgroundColor: "#FFFFFF",
        position: "relative",
        ...dropShadow(0, 4, 4, .25)
    },
    contentContainer: {
        ...screenContainer,
        ...viewRow
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: 80,
        marginLeft: 10,
    },
    gear: {
        width: 32,
        height: 32,
        color: colors.headerGrey
    },
    aboutText: {
        lineHeight: 32,
        fontSize: 32,
        fontFamily: "bold",
        color: colors.headerGrey
    },
    dropDown: {
        flex: 1,
        height: 50,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    headerText: {
        fontFamily: "bold",
        fontSize: 18,
        flex: 1
    },
    chevron: {
        width: 14,
        height: 8,
        marginLeft: 12,
        color: "#000000"
    },
    description: {
        paddingHorizontal: 10,
        paddingBottom: 10,
        fontSize: 18
    },
    ...listStyles,
    borderContainer: {
        height: 30,
        borderRadius: 8,
        marginBottom: -22
    },
    headerThumb: {
        ...listStyles.thumb,
        height: 40,
        width: 40
    },
    closeButton: {
        height: 48,
        width: 48,
        marginRight: 10,
        justifyContent: "center",
        alignItems: "center"
    },
    closeButtonText: {
        color: colorNames.actionRed,
        fontFamily: "bold",
        fontSize: 16,
        lineHeight: 16,
        textAlign: "center"
    },
    premiumContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 5,
        marginBottom: 5
    },
    premiumIcon: {
        width: 20,
        height: 18,
        marginRight: 7
    },
    premiumText: {
        fontSize: 15
    },
    showPurchaseButtonContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10
    },
    showPurchaseButton: {
        borderRadius: 15,
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    showPurchaseButtonText: {
        color: "#fff",
        fontSize: 18,
        fontFamily: "bold"
    }
});

const MapHeader = ({ toggleShowSettingsPage, toggleShowAboutPage }) => {

    const lang = useSelector(state => state.settings.lang);

    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const offlineMode = useSelector(state => state.mapData.offlineMode);

    const dispatch = useDispatch();
    const exitWalkingRoute = () => {
        dispatch({
            type: types.EXIT_WALKING_ROUTE,
            lang
        });
    }
    
    const insets = useSafeAreaInsets();

    if (!selectedRouteData) {
        return null;
    }

    let routeThumb = null;
    if (selectedRouteData.thumbnail) {
        routeThumb = <LocalImage
            style={headerStyles.headerThumb}
            image={selectedRouteData.thumbnail}
            imageSize={headerThumbSize}
            timestamp={selectedRouteData.timestamp}
        />
    }

    return <>
        <View style={headerStyles.container}>
            <View style={{
                ...headerStyles.containerInner,
                paddingTop: insets.top
            }}>
                <View style={headerStyles.contentContainer}>
                    <View style={headerStyles.buttonContainer}>
                        <View>
                            <TouchableOpacity
                                onPress={toggleShowSettingsPage}
                            >
                                <View>
                                    <Gear style={headerStyles.gear} />
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View>
                            <TouchableOpacity
                                onPress={toggleShowAboutPage}
                            >
                                <View>
                                    <Text style={headerStyles.aboutText}>?</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View
                        style={{
                            ...headerStyles.dropDown,
                        }}
                    >
                        { routeThumb }
                        <Text style={{
                            ...headerStyles.headerText,
                            fontSize: selectedRouteData.title.length > 30 ? 16 : 18
                        }}>{selectedRouteData.title}</Text>
                    </View>
                    <TouchableOpacity onPress={() => exitWalkingRoute()}>
                        <View style={{...headerStyles.closeButton, opacity: selectedRouteData ? 1 : 0 }}>
                            <Text style={headerStyles.closeButtonText}>{ getLangTerm("exit-route", lang) }</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
            { !offlineMode && <WalkingHeader /> }
        </View>
    </>;
};
export default MapHeader;
