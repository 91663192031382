import React from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet } from 'react-native';

import { BaseMarker } from './BaseMarker';
import { isWeb } from 'utils/platform';
import { colors } from 'styles/shared';

const UserPosition = () => {


    const userPosition = useSelector(state => state.mapData.userPosition);

    const landingPointStyles = StyleSheet.create({
        position: {
            width: 18,
            height: 18,
            borderRadius: 9,
            borderWidth: 2,
            borderColor: "#EEFCFF",
            backgroundColor: colors.userPosition ,
            justifyContent: "center",
            alignItems: "center"
        },
        positionWeb: {
            top: -7,
        }
    });

    if (!userPosition) {
        return null;
    }

    return <BaseMarker
        key={`user-position`}
        coordinate={userPosition}
        anchor={{x: 0.5, y: 0.5}}
        style={{
            zIndex: 2000
        }}
        tracksViewChanges={false}
    >
        <View style={{...landingPointStyles.position, ...(isWeb && landingPointStyles.positionWeb)}}>
            <View>
            </View>
        </View>
    </BaseMarker>
}
export default React.memo(UserPosition);