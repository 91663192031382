export const getParamsFromURI = () => {
    const pathParams = window.location.pathname.split('/');
    let routeParamIndex = -1;
    let placeParamIndex = -1;
    let offlineParamIndex = -1;
    pathParams.forEach((param, i) => {
        if (param === 'route') {
            routeParamIndex = i;
        }
        if (param === 'place') {
            placeParamIndex = i;
        }
        if (param === 'offline') {
            offlineParamIndex = i;
        }
    });
    let routeSlug = null;
    let stopSlug = null;

    let placeSlug = null;

    let details = false;
    let lang = null;
    let offlineMode = false;

    if (routeParamIndex > -1) {
        routeSlug = pathParams[routeParamIndex + 1];
        if (pathParams[routeParamIndex + 2] === 'details') {
            details = true;
        } else {
            stopSlug = pathParams[routeParamIndex + 2];
            details = pathParams[routeParamIndex + 3] === "details";
        }
    }
    if (placeParamIndex > -1) {
        placeSlug = pathParams[placeParamIndex + 1];
        if (pathParams[placeParamIndex + 2] === 'details') {
            details = true;
        }
    }
    if (offlineParamIndex > -1) {
        offlineMode = true;
    }
    if (pathParams[1] === 'fr') {
        // TODO: add other languages
        lang = 'fr';
    }
    return { routeSlug, stopSlug, lang, details, placeSlug, offlineMode };
}