import { getLangTerm } from "lang";

const toRad = (Value) => {
    return Value * Math.PI / 180;
}

export const getDistance = (startLatLng, endLatLng) => {
    if (!startLatLng || !endLatLng) return null;
    const {latitude: lat1, longitude: lon1} = startLatLng;
    const {latitude: lat2, longitude: lon2} = endLatLng;

    var R = 6371e3; // metres
    var φ1 = toRad(lat1);
    var φ2 = toRad(lat2);
    var Δφ = toRad(lat2-lat1);
    var Δλ = toRad(lon2-lon1);

    var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    var d = R * c;
    return d;
}

export const getWalkingTime = (startLatLng, endLatLng) => {
    const distance = getDistance(startLatLng, endLatLng);
    const distancePerMinute = 60;
    return {
        distance,
        minutes: Math.ceil(distance / distancePerMinute)
    }
}

export const getDistanceStr = (minutes, lang) => {
    if (minutes == 0) {
        return getLangTerm("you-are-here", lang);
    }
    if (minutes < 120) {
        return getLangTerm(`min${minutes != 1 ? "s" : ""}-away`, lang).replace("{0}", minutes);
    }
    return "";
}