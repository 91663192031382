import { types } from "../types";

const initialState = {
    staticAccessCodes: [],
    purchasedAccessCodes: [],
    accessCodesLoaded: false,
    accessCodesLoadFailure: false,
    submitting: false,
    isValid: false,
    isInvalid: false,
    isInactive: false,
    didRedemption: false,
    didEmail: false,
    errorMessage: null,
    urlInvalid: []
};


export const accessCodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_ACCESS_CODES_SUCCESS: {
            return {
                ...state,
                staticAccessCodes: action.staticAccessCodes || [],
                purchasedAccessCodes: action.purchasedAccessCodes || [],
                accessCodesLoaded: true,
                accessCodesLoadFailure: false
            }
        }
        case types.LOAD_ACCESS_CODES_FAILURE: {
            return {
                ...state,
                accessCodesLoaded: false,
                accessCodesLoadFailure: true
            }
        }
        case types.ACCESS_CODE_SUBMIT: {
            return {
                ...state,
                submitting: true,
                isValid: false,
                isInvalid: false,
                isInactive: false,
                didRedemption: false,
                didEmail: false,
                errorMessage: null
            }
        }
        case types.ACCESS_CODE_SUBMIT_SUCCESS: {
            return {
                ...state,
                staticAccessCodes: action.staticAccessCodes,
                submitting: false,
                isValid: true,
                isInvalid: false,
                isInactive: false,
                errorMessage: null
            };
        }
        case types.ACCESS_CODE_SUBMIT_FAILURE: {
            return {
                ...state,
                submitting: false,
                isValid: false,
                isInvalid: true,
                isInactive: action.isInactive,
                errorMessage: null
            }
        }
        case types.ACCESS_CODE_RESET: {
            return {
                ...state,
                submitting: false,
                isValid: false,
                isInvalid: false,
                isInactive: false,
                errorMessage: null
            }
        }
        case types.ACCESS_CODE_LOOKUP_FAILURE: {
            return {
                ...state,
                submitting: false,
                isValid: false,
                isInvalid: false,
                isInactive: false,
                didRedemption: false,
                didEmail: false,
                errorMessage: action.errorMessage || "generic-error"
            }

        }
        case types.ACCESS_CODE_LOOKUP_SUCCESS: {
            return {
                ...state,
                submitting: false,
                isValid: false,
                isInvalid: false,
                isInactive: false,
                errorMessage: null,
                didRedemption: true,
                didEmail: false,
                purchasedAccessCodes: action.purchasedAccessCodes
            }

        }
        case types.ACCESS_CODE_BY_EMAIL_LOOKUP_SUCCESS: {
            return {
                ...state,
                submitting: false,
                isValid: false,
                isInvalid: false,
                isInactive: false,
                errorMessage: null,
                didRedemption: false,
                didEmail: true,
            }
        }
        case types.ACCESS_CODE_BY_URL_SUCCESS: {
            return {
                ...state,
                purchasedAccessCodes: action.purchasedAccessCodes,
                urlInvalid: action.invalid
            }
        }
        default:
            return state;
    }
}