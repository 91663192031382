import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { SentryPlatform } from 'utils/sentry';

import config from "./apiConfig";

import { types } from "../types";

export const getUserUniqueID = async () => {
    let userUniqueID = await AsyncStorage.getItem('@userUniqueID');

    if (!userUniqueID) {
        userUniqueID = uuidv4();
        await AsyncStorage.setItem('@userUniqueID', userUniqueID);
    }

    return userUniqueID;
}

export const userActionsAPI = () => next => action => {

    const postUserAction = async (action) => {
        const { type, duration, userActionData, target } = action;

        const apiClient = config.getInstance();
        const post = (postData) => apiClient.post("/user-actions", postData);
        
        if (!navigator.onLine) {
            return;
        }

        if (
            (type === types.SHOW_DETAILS_PAGE && (!userActionData || !userActionData.slug)) ||
            (type === types.SET_WALKING_ROUTE_STOP && (!userActionData || !userActionData.routeSlug))
        ) {
            return;
        }

        const userID = await getUserUniqueID()

        const currentDate =  new Date();
        const properMonthNumber = currentDate.getMonth()+1;

        const postData = {
            userID: userID,
            timestampUser: `${currentDate.getTime()}-${userID}`,
            yearMonth: `${currentDate.getFullYear()}-${properMonthNumber}`,
            date: `${currentDate.getFullYear()}-${properMonthNumber}-${currentDate.getDate()}`,
            action: type,
            platform: Platform.OS,
            ...(duration && { duration }),
            ...(target && { target }),
            ...(userActionData && userActionData), // any extra data that we may want to see
        }

        await post(postData).catch((e) => {
            console.log("Error posting user action");
            console.log(e);
            SentryPlatform.captureMessage(`Error Posting User Action: ${type}`);
        });
    }

    

    next(action);
    switch (action.type) {
        case types.APP_OPENED:
        case types.TIMESTAMP_LOOKUP_SUCCESS:
        case types.TIMESTAMP_LOOKUP_FAILURE:
        case types.ROUTE_DATA_LOOKUP_SUCCESS:
        case types.ROUTE_DATA_LOOKUP_FAILURE:
        case types.SINGLE_ROUTE_LOOKUP_SUCCESS:
        case types.SINGLE_ROUTE_LOOKUP_FAILURE:
        case types.PLACES_LOOKUP_SUCCESS:
        case types.PLACES_LOOKUP_FAILURE:
        case types.LOAD_OFFERINGS_SUCCESS:
        case types.LOAD_OFFERINGS_FAILURE:
        case types.LOAD_PURCHASES_SUCCESS:
        case types.LOAD_PURCHASES_FAILURE:
        case types.PURCHASE_ROUTE_SUCCESS:
        case types.PURCHASE_ROUTE_FAILURE:
        case types.PURCHASE_ROUTE_CANCELLED:
        case types.RESTORE_PURCHASES_SUCCESS:
        case types.RESTORE_PURCHASES_FAILURE:
        case types.LOAD_ACCESS_CODES_SUCCESS:
        case types.LOAD_ACCESS_CODES_FAILURE:
        case types.ACCESS_CODE_SUBMIT_SUCCESS:
        case types.ACCESS_CODE_SUBMIT_FAILURE:
        case types.ACCESS_CODE_LOOKUP_SUCCESS:
        case types.ACCESS_CODE_LOOKUP_FAILURE:
        case types.ACCESS_CODE_BY_EMAIL_LOOKUP_SUCCESS:
        case types.ACCESS_CODE_BY_URL_SUCCESS:
        case types.IMAGES_FILE_LOAD_SUCCESS:
        case types.IMAGES_FILE_LOAD_FAILURE:
        case types.AUDIO_FILE_LOAD_SUCCESS:
        case types.AUDIO_FILE_LOAD_FAILURE:
        case types.LANG_LOADED:
        case types.LANG_CHANGED:
        case types.USER_SET_LOCATION_PREFERENCE:
        case types.SHOW_DETAILS_PAGE:
        case types.SHOW_FOOTER_LIST:
        case types.SET_WALKING_ROUTE_STOP:
        case types.EXIT_WALKING_ROUTE:
        case types.SET_MAP_TO_POINT:
        case types.CENTER_MAP_ON_USER:
        case types.MAILING_LIST_SIGNUP_SUCCESS:
        case types.OPEN_URL:
        case types.URI_CHANGED:
        case types.FILE_DOWNLOADED:
        case types.PLAY_AUDIO:
        case types.STOP_AUDIO:
        case types.OFFLINE_MODE_ENABLE:
        case types.OFFLINE_MODE_ABORT:
        case types.OFFLINE_MODE_RESET:


        /*

    // USER
    "USER_LOCATION_CHANGE": null, // think about this

        
        */
        {
            postUserAction(action);
            break;
        }
        default:
            break;
    }
};