import { staticAccessCodesList } from "utils/accessCodes";

export const formatProductTitle = (title) => {
    // the titles of the products come from Android in this format:
    // Gastown Premium Route (VanWalks - Vancouver Walking App)
    // This function removes the second half
    // This might not work for different locales, but it's not a big deal
    return title.split("(")[0].trim();
}

export const getRouteButtonTextColor = (slug) => {
    // since the route color can be light or dark, the text color for a button may need to be either black or white depending on what looks best for that color background
    let buttonColor = "#000";
    if (slug === "stanley-park-seawall" || slug === "stanley-park-inner" || slug == "bc-entertainment-hof" || slug == "police-museum-strathcona") {
        buttonColor = "#fff";
    }
    return buttonColor;
}

export const getRouteIsPurchased = ({route, purchases, staticAccessCodes, purchasedAccessCodes}) => {
    let isPurchased = false;

    const isTest = window?.location?.search?.indexOf("t=test") > -1;
    if (!route.published && isTest) {
        isPurchased = true;
    }

    if(route.slug === "police-museum-strathcona" && isTest) {
        isPurchased = true;
    }
                
    route.offerings?.forEach(offering => {
        if (purchases?.indexOf(offering) > -1) {
            isPurchased = true;
        }
    });

    staticAccessCodes.forEach(accessCode => {
        if (staticAccessCodesList[accessCode]) {
            const unlockedRoutes = staticAccessCodesList[accessCode].routes;
            if (unlockedRoutes.indexOf(route.routeID) > -1 ) {
                isPurchased = true;
            }
        }
    })

    purchasedAccessCodes.forEach(purchasedAccessCode => {
        purchasedAccessCode.offerings?.forEach(offering => {
            if ( route.offerings?.indexOf(offering) > -1 ) {
                isPurchased = true;
            }
        });
    });

    return isPurchased
}