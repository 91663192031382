import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Text, View, TouchableWithoutFeedback, StyleSheet, ActivityIndicator } from 'react-native';

import { types } from "redux/types";
import { getLangTerm } from "lang";
import { colors, colorNames, getRouteColor } from "styles/shared";
import { getRouteButtonTextColor, getRouteIsPurchased } from "utils/purchases";

const buttonStyles = StyleSheet.create({
    verticalContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        // display: "flex",
        alignItems: "center"
    },
    startButton: {
        flexDirection: "row",
        backgroundColor: colorNames.actionOrange,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginHorizontal: "auto",
        marginBottom: 10
    },
    startButtonText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center",
        cursor: "pointer"
    },
    offlineModeButton: {
        display: "flex",
        flexDirection: "row",
        marginTop: 10,
        marginBottom: 20,
        cursor: "pointer"
    },
    offlineModeButtonText: {
        color: colors.subtitleColor,
        fontFamily: "semi-bold",
        fontSize: 16,
    },
    comingSoonContainer: {
        marginBottom: 20,
        display: "flex",
        alignItems: "center"
    },
    comingSoonText: {
        color: "#000000",
        fontFamily: "bold",
        fontSize: 24,
    },
});

const RouteStartButton = ({ route }) => {

    const routeLoading = useSelector(state => state.mapData.routeLoading);
    const offlineMode = useSelector(state => state.mapData.offlineMode);
    const lang = useSelector(state => state.settings.lang);
    const purchases = useSelector(state => state.purchases.purchases);
    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);

    const { slug, timestamp, published } = route;

    const isTest = window?.location?.search?.indexOf("t=test") > -1;

    const isPurchased = getRouteIsPurchased({ route, purchases, staticAccessCodes, purchasedAccessCodes });

    const dispatch = useDispatch();
    const loadSingleRoute = (offlineMode = false) => {
        dispatch({
            type: types.SINGLE_ROUTE_LOOKUP,
            offlineMode,
            routeSlug: slug,
            stopSlug: null,
            lang,
            timestamp,
            animationDuration: 1000,
            userActionData: { routeSlug: slug }
        });
    }

    let buttonLangSlug = "start-this-route";
    let showOfflineButton = true;
    if(route.isPremium && !isPurchased ) {
        buttonLangSlug = "preview-this-route"
        showOfflineButton = false;
    }

    if (!published && !isTest) {
        return <View style={buttonStyles.comingSoonContainer}>
            <Text style={buttonStyles.comingSoonText}>{ getLangTerm("coming-soon", lang) }</Text>
        </View>
    }

    const buttonColor = getRouteButtonTextColor(slug)

    return <View style={buttonStyles.verticalContainer}>
        <TouchableWithoutFeedback onPress={() => loadSingleRoute()}>
            <View style={buttonStyles.container}>
                <View style={{
                    ...buttonStyles.startButton,
                    backgroundColor: getRouteColor(route.slug)
                }}>
                    <Text style={{
                        ...buttonStyles.startButtonText,
                        color: buttonColor
                    }}>
                        { getLangTerm(buttonLangSlug, lang) }
                    </Text>
                    {
                        routeLoading && routeLoading === slug && !offlineMode && <ActivityIndicator style={{marginLeft: 10 }} size="small" color={buttonColor} />
                    }
                </View>
            </View>
        </TouchableWithoutFeedback>
        { showOfflineButton && <TouchableWithoutFeedback onPress={() => {loadSingleRoute(true)}}>
            <View style={buttonStyles.container}>
                <View style={{
                    ...buttonStyles.offlineModeButton
                }}>
                    <Text style={{
                        ...buttonStyles.offlineModeButtonText
                    }}>
                        { getLangTerm("offline-mode-button", lang) }
                    </Text>
                    {
                        routeLoading && routeLoading === slug && offlineMode && <ActivityIndicator style={{marginLeft: 10 }} size="small" color={buttonStyles.offlineModeButtonText.color} />
                    }
                </View>
            </View>
        </TouchableWithoutFeedback>
        }
    </View>
}
export default RouteStartButton