import React, { useState, useRef, createContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Animated } from 'react-native';

import { types } from "redux/types";
import { getPageStyles, getInfoPageStyles, getPlacePageStyles } from "./styles/detailsStyles";
import { getRouteColor } from "styles/shared";
import { getRouteIsPurchased } from "utils/purchases";
import { isWeb } from "utils/platform";

export const DetailsPageContext = createContext();

const DetailsPageContextProvider = ({children, stopType, slugIndex}) => {

    const lang = useSelector(state => state.settings.lang);
    const purchases = useSelector(state => state.purchases.purchases);
    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);

    const routeData = useSelector(state => state.mapData.routeData);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const detailsPageData = useSelector(state => state.mapData.detailsPageData);
    const offlineMode = useSelector(state => state.mapData.offlineMode);
    const color = selectedRouteData ? getRouteColor(selectedRouteData.slug) : detailsPageData.color;

    
    const dispatch = useDispatch();

    const onCloseButtonPress = () => {
        dispatch({
            type: types.SHOW_DETAILS_PAGE,
            detailsPageData: null,
            offlineMode
        });
    }

    const isInfoPage = () => {
        return stopType && (stopType === "direction" || stopType === "info" || stopType === "endpoint");
    }
    const isPlacePage = () => {
        return slugIndex && slugIndex === "place";
    }

    const getIsPremium = () => {
        let isPremium = false;
        if (!detailsPageData?.routeRNG1) {
            // routes and stops will have a routeRNG1 key
            // places won't, don't show anything if it's a place
            return false;
        }
        if (detailsPageData.stopType == "route-start" && detailsPageData?.isPremium) {
            isPremium = true;
        }
        if (detailsPageData.stopType == "stop") {
            const parentRoute = routeData.filter(route => route.slug == detailsPageData.routeID)[0];
            if (parentRoute.isPremium) {
                isPremium = true;
            }
        }
        
        return isPremium;
    }
    const getOfferings = () => {
        let offerings = [];
        if (!detailsPageData.routeRNG1) {
            // routes and stops will have a routeRNG1 key
            // places won't, don't show anything if it's a place
            return null;
        }
        
        // get the offerings from the route slug
        if(detailsPageData.stopType == "route-start" && detailsPageData.offerings) {
            offerings = detailsPageData.offerings
        }
        // get the offerings from the route stops, using the parent
        if (detailsPageData.stopType == "stop") {
            const parentRoute = routeData.filter(route => route.slug == detailsPageData.routeID)[0];
            if (parentRoute.offerings) {
                offerings = parentRoute.offerings;
            }
        }
        return offerings;
    }
    const getIsPurchased = () => {

        if (!detailsPageData?.routeRNG1) {
            // routes and stops will have a routeRNG1 key
            // places won't, don't show anything if it's a place
            return false;
        }

        let route = detailsPageData;
        if (detailsPageData.stopType == "stop") {
            route = routeData.filter(route => route.slug == detailsPageData.routeID)[0];
        }

        return getRouteIsPurchased({ route, purchases, staticAccessCodes, purchasedAccessCodes });
    }

    let detailsStyles = null;
    if (isInfoPage()) {
        detailsStyles = getInfoPageStyles(color);
    } else if (isPlacePage()) {
        detailsStyles = getPlacePageStyles(color);
    } else {
        detailsStyles = getPageStyles(color);
    }
    
    const [ carouselHeight, setCarouselHeight] = useState(0);
    
    const scrollY = useRef(new Animated.Value(0)).current;

    const handleScroll = Animated.event(
        [
            {
                nativeEvent: {
                    contentOffset: {y: scrollY},
                },
            },
        ],
        {
            useNativeDriver: !isWeb,
        },
    );

    let markerIconScale = scrollY.interpolate({
        inputRange: [carouselHeight, carouselHeight + 50],
        outputRange: [1, .75],
        extrapolate: "clamp"
    });

    let headerDropShadowOpacity = scrollY.interpolate({
        inputRange: [carouselHeight, carouselHeight + 50],
        outputRange: [0, 1],
        extrapolate: "clamp"
    })

    let headerElevation = scrollY.interpolate({
        inputRange: [carouselHeight, carouselHeight+25],
        outputRange: [0, 10],
        extrapolate: "clamp"
    })


    return <DetailsPageContext.Provider
        value={{
            lang,
            detailsPageData,
            detailsStyles,
            onCloseButtonPress,
            isInfoPage,
            isPlacePage,
            isPremium: getIsPremium(),
            isPurchased: getIsPurchased(),
            offerings: getOfferings(),
            setCarouselHeight,
            handleScroll,
            markerIconScale,
            headerDropShadowOpacity,
            headerElevation,
            color,
            offlineMode
        }}
    >
        { children }
    </DetailsPageContext.Provider>
}
export default DetailsPageContextProvider;