import React from 'react';
window.React = React;
import { Platform } from 'react-native';
import { Provider } from "react-redux";
import { ApplicationProvider } from '@ui-kitten/components';
import { mapping, light as lightTheme } from '@eva-design/eva';
import Constants from "expo-constants";
import * as Sentry from 'sentry-expo';
import Purchases from 'react-native-purchases';
import * as NavigationBar from "expo-navigation-bar";
import { setStatusBarBackgroundColor, setStatusBarStyle, setStatusBarHidden } from "expo-status-bar";
import { Helmet } from "react-helmet"

import store from "./src/redux/root.js";
import { default as appTheme } from './src/custom-theme.json';
import getEnvVars from 'environment';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

const envVars = getEnvVars();

import AppRoot from "./src/AppRoot";
import { isAndroid } from 'utils/platform.js';
import { checkAndSetSalesRepId } from 'utils/salesRep.js';

const theme = { ...lightTheme, ...appTheme };
const releaseName = `vanwalks-${Constants.manifest.version}-${envVars.env}`;

if (envVars.env === "production") {
    Sentry.init({
        dsn: 'https://f81edac644484b439efce3649d308c71@o510341.ingest.sentry.io/5605830',
        release: releaseName,
        enableInExpoDevelopment: true,
        environment: envVars.env,
        debug: envVars.env !== "production", // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
    });
}

checkAndSetSalesRepId();


if (isAndroid) {
    try {
        NavigationBar.setPositionAsync("relative");
        NavigationBar.setVisibilityAsync("hidden");
        NavigationBar.setBehaviorAsync("inset-swipe");
        setStatusBarStyle("dark");
        setStatusBarBackgroundColor("#e8f2f6", false);
        setStatusBarHidden(true, "fade");
    } catch (e) {
        Sentry.captureMessage("Error setting Android NavigationBar");
    }
}
if (Platform.OS !== 'web') {
    Purchases.setDebugLogsEnabled(true);
    Purchases.configure({ apiKey: "pNIiLyYlkipmBXYGTspNNCdpgkJkbcAY"});
}

export const TrackingScript = () => {
    return <Helmet>
        <script defer data-domain="map.vanwalks.ca" src="https://plausible.io/js/script.manual.js"></script>
        <script type="text/javascript">{`
        window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
    `}</script>
        <link rel="canonical" href="https://map.vanwalks.ca/" />
    </Helmet>
}

export default function App() {
    return (
        <Provider store={store}>
            <ApplicationProvider mapping={mapping} theme={theme}>
                <TrackingScript />
                <AppRoot />
            </ApplicationProvider>
        </Provider>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
