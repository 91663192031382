import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import * as FileSystem from 'expo-file-system';
import shorthash from 'shorthash';

import { isWeb } from "utils/platform";

const LocalImage = ({style, image, imageSize, imageURL, timestamp, placeholder, onLoad}) => {
    // the stops have an image object, the places have an image url
    // the image object has an property for each size

    const [uri, setUri] = useState(null);

    useEffect(() => {
        checkCache();
    }, [])

    const checkCache = async() => {
        const remoteImageURL = imageURL || image && `${image[imageSize]}${timestamp ? `?t=${timestamp}` : "" }`;

        if (isWeb) {
            // web doesn't need to check the cache, just use the url
            setUri(remoteImageURL);
            return;
        }

        const imageHash = shorthash.unique(remoteImageURL);
        const path = `${FileSystem.cacheDirectory}${imageHash}`;

        const cachedImage = await FileSystem.getInfoAsync(path);

        if (cachedImage.exists) {
            setUri(cachedImage.uri);
            return;
        }
        
        setUri("placeholder");
        const newImage = await FileSystem.downloadAsync(remoteImageURL, path);
        setUri(newImage.uri);
    }
    if ( !uri || uri === "placeholder") {
        if (placeholder) {
            return placeholder;
        }
        return <View style={style}>
        </View>
    }

    const imageLoaded = () => {
        if (onLoad) {
            onLoad();
        }
    }

    return <Image
        resizeMode="contain"
        style={style}
        source={{ uri: uri }}
        onLoad={imageLoaded}
    />
};
export default LocalImage;