import React, { useState, useEffect, createContext } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { types } from "redux/types";

export const MarkerContext = createContext();

const MarkerContextProvider = ({children}) => {

    const renderTS = useSelector(state => state.mapData.renderTS);
    const isWalkingRoute = useSelector(state => state.mapData.isWalkingRoute);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedStopData = useSelector(state => state.mapData.selectedStopData);

    const lang = useSelector(state => state.settings.lang);

    const [tracksViewChanges, setTracksViewChanges] = useState(true);

    let isMounted = false;

    const setFalse = () => {
        setTimeout(() => {
            if (isMounted) {
                setTracksViewChanges(false);
            }
        }, 1)
    };

    useEffect(() => {
        isMounted = true;
        setTracksViewChanges(true);
        setFalse();
        return () => {
            isMounted = false;
        }
    }, [renderTS]);

    const getIsActive = (slug) => {
        return isWalkingRoute && selectedStopData?.slug == slug
    }

    // the viewBox is the dimensions of the original image, then you can scale it with width and height
    // the marker is being scaled for direction stops that only have icons
    var viewBox = "0 0 48 58";

    const getMarkerX = (displayDir) => {
        return displayDir == "left" ? 1 : 0;
    }

    const dispatch = useDispatch();
    const markerOnPress = (markerData) => {
        dispatch({
            type: types.SHOW_DETAILS_PAGE,
            detailsPageData: markerData,
            userActionData: { slug: markerData.slug },
            target: markerData.slug
        });
    }
    return <MarkerContext.Provider
        value={{
            markerOnPress,
            lang,
            isWalkingRoute,
            selectedRouteData,
            selectedStopData,
            tracksViewChanges,
            viewBox,
            getMarkerX,
            getIsActive
        }}
    >
        { children }
    </MarkerContext.Provider>
}
export default MarkerContextProvider;