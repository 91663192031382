import { types } from "../types";

const initialState = {
    offerings: null,
    offeringsLoaded: false,
    offeringsLoadedError: false,

    purchases: null,
    purchasesLoaded: false,
    purchasesLoadError: false,

    makingPurchase: null,
    purchaseError: false,
    purchaseSuccess: false,

    restoringPurchases: false,
    restoredPurchases: false,
    restorePurchasesError: false,
};

export const purchasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOAD_OFFERINGS_SUCCESS: {
            return { ...state, offeringsLoaded: true, offerings: action.offerings };
        }
        case types.LOAD_OFFERINGS_FAILURE: {
            return { ...state, offeringsLoaded: false, offeringsLoadedError: true };
        }
        case types.LOAD_PURCHASES: {
            return { ...state, purchasesLoaded: false, purchasesLoadError: false };
        }
        case types.LOAD_PURCHASES_SUCCESS: {
            return { ...state, purchasesLoaded: true, purchases: action.purchases };
        }
        case types.LOAD_PURCHASES_FAILURE: {
            return { ...state, purchasesLoadError: true };
        }
        case types.PURCHASE_ROUTE: {
            return {
                ...state,
                makingPurchase: action.routeSlug,
                purchaseError: false,
                purchasesLoadError: false
            }
        }
        case types.PURCHASE_ROUTE_SUCCESS: {
            return {
                ...state,
                makingPurchase: null,
                purchases: [...state.purchases, action.purchase],
                purchaseSuccess: action.purchase
            }
        }
        case types.PURCHASE_ROUTE_FAILURE: {
            return {
                ...state,
                makingPurchase: null,
                purchaseError: true
            }
        }
        case types.PURCHASE_ROUTE_CANCELLED: {
            return {
                ...state,
                makingPurchase: null,
                purchaseError: false
            }
        }
        case types.SHOW_DETAILS_PAGE: {
            return {
                ...state,
                makingPurchase: null,
                purchaseError: false,
                purchaseSuccess: false,
                restoredPurchases: false,
                restorePurchasesError: false
            }
        }
        case types.RESTORE_PURCHASES: {
            return { ...state, restoringPurchases: true, restoredPurchases: false, restorePurchasesError: false, purchasesLoadError: false }
        }
        case types.RESTORE_PURCHASES_SUCCESS: {
            return { ...state, restoringPurchases: false, restoredPurchases: true, purchases: action.purchases }
        }
        case types.RESTORE_PURCHASES_FAILURE: {
            return { ...state, restoringPurchases: false, restorePurchasesError: true }
        }
        default:
            return state;
    }
};