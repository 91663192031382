import * as Location from 'expo-location';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import { SentryPlatform } from 'utils/sentry';

import { types } from "../types";
import { isWeb } from "utils/platform";

let hasLocationWatcher = false;
let locationWatcher = null;

Location.installWebGeolocationPolyfill();

const getLocationForegroundPermissions = async() => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    return status;
}

export const enableLocationWatcher = async(next) => {
    // there should only be one location watcher. If a new one is created, the old one can't be removed.
    if(!hasLocationWatcher) {
        hasLocationWatcher = true;
        let locationPreference = await getLocationPreference();

        if (locationPreference) {

            // check for permissions first
            const locationPermissionGranted = await getLocationForegroundPermissions();

            if(locationPermissionGranted === "denied") {
                return next({
                    type: types.USER_LOCATION_LOOKUP_FAIL
                })
            }

            if(locationPermissionGranted === "granted") {
                // console.log(`about to watchPosition`)
                if (isWeb) {
                    locationWatcher = navigator.geolocation.watchPosition((res) => {
                        const {latitude, longitude, accuracy} = res.coords;
                        // console.log(`DID watchPositionAsync ${res.coords}`)
                        // console.log(`DID watchPositionAsync ${accuracy}`)
                        // console.log(`DID watchPositionAsync ${JSON.stringify({latitude, longitude})}`)
                        const userPosition = {latitude, longitude};
                        return next({
                            type: types.USER_LOCATION_CHANGE,
                            userPosition,
                            accuracy
                        })
                    }, (err) => {
                        SentryPlatform.captureMessage(`ERROR watchPositionAsync WEB ${JSON.stringify(err)}`);
                        return next({
                            type: types.USER_LOCATION_LOOKUP_FAIL
                        })
                        
                    }, {
                        enableHighAccuracy: true,
                        timeout: 20000,
                        maximumAge: 5000
                    });
                } else {

                    locationWatcher = await Location.watchPositionAsync({
                        accuracy: Location.Accuracy.BestForNavigation,
                        timeInterval: 5000,
                        distanceInterval: 5
                    }, (res) => {
                        const {latitude, longitude} = res.coords;
                        // console.log(`DID watchPositionAsync ${JSON.stringify({latitude, longitude})}`)
                        const userPosition = {latitude, longitude};
                        return next({
                            type: types.USER_LOCATION_CHANGE,
                            userPosition
                        });
                    });
                }
            }
        }

    }
}
const disableLocationWatcher = async () => {
    if (locationWatcher) {
        // console.log(`about to clearWatch`)
        if (isWeb) {
            navigator.geolocation.clearWatch(locationWatcher);
        } else {
            await locationWatcher.remove();
        }
        locationWatcher = null;
    }
    hasLocationWatcher = false;
}

const getLocationPreference = async() => {
    let locationPreference = await AsyncStorage.getItem('@locationPreference');
    if (locationPreference !== null) {
        // the value is saved as a string, this is forcing a boolean
        locationPreference = locationPreference === "true";
    }
    return locationPreference
}

export const userAPI = () => next => action => {

    const setLocationPreference = async(locationPreference) => {
        await AsyncStorage.setItem('@locationPreference',`${locationPreference}`);
        if (locationPreference) {
            // console.log("ABOUT TO requestForegroundPermissionsAsync")
            const res =  await Location.requestForegroundPermissionsAsync();
            const { status } = res;
            const isGranted = status === "granted";
            // console.log(`DID requestForegroundPermissionsAsync ${JSON.stringify(isGranted)}`)
            await AsyncStorage.setItem('@locationPreference',`${isGranted}`);
            userGetCurrentPosition();
        }
        
    }

    const userGetCurrentPosition = async() => {
        let locationPreference = await getLocationPreference();
        if (locationPreference) {


            // check for permissions first
            const locationPermissionGranted = await getLocationForegroundPermissions();

            if(locationPermissionGranted === "denied") {
                return next({
                    type: types.USER_LOCATION_LOOKUP_FAIL
                })
            }

            if(locationPermissionGranted === "granted") {
                const res = await Location.getCurrentPositionAsync({
                    enableHighAccuracy: true,
                    accuracy: Location.Accuracy.High
                });

                const {latitude, longitude} = res.coords;
                const userPosition = {latitude, longitude};
                // console.log(`DID getCurrentPositionAsync ${JSON.stringify(userPosition)}`)
                return next({
                    type: types.USER_LOCATION_CHANGE,
                    userPosition
                });
            }
        }
    }

    const checkLocationPreference = async() => {
        let locationPreference = await getLocationPreference();
        if (locationPreference) {
            await userGetCurrentPosition();
        }
        return next({
            type: types.USER_CHECKED_LOCATION_PREFERENCE,
            locationPreference
        });
    }

    const openURL = async(action) => {
        const { url } = action;

        if(Platform.OS == 'web'){
            window.open(url, '_blank');
        } else {
            Linking.openURL(url);
        }
    }

    next(action);
    switch (action.type) {
        case types.USER_SET_LOCATION_PREFERENCE: {
            setLocationPreference(action.locationPreference)
            break;
        }
        case types.USER_CHECK_LOCATION_PREFERENCE: {
            checkLocationPreference();
            break;
        }
        case types.SINGLE_ROUTE_LOOKUP_SUCCESS:
        case types.SET_WALKING_ROUTE_STOP:
        case types.SET_MAP_TO_POINT:
        {
            if (!action.offlineMode) {
                enableLocationWatcher(next);
            }
            break;
        }
        case types.EXIT_WALKING_ROUTE: {
            disableLocationWatcher();
            break;
        }
        case types.SHOW_DETAILS_PAGE:
        {
            if(action.detailsPageData) {
                disableLocationWatcher();
            } else {
                enableLocationWatcher(next);
            }
            break;
        }
        case types.SHOW_FOOTER_LIST:
        {
            if (action.showFooterList) {
                disableLocationWatcher();
            } else {
                enableLocationWatcher(next);
            }
            break;
        }
        case types.OPEN_URL: {
            openURL(action)
            break;
        }
        default:
            break;
    }
};