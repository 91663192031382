import React, { useRef } from "react";
import { ActivityIndicator, Text, View, TouchableOpacity } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    useScrollIntoView,
} from 'react-native-scroll-into-view';

import {
    Input
} from '@ui-kitten/components';

import { settingsStyles } from "./styles/settingsStyles";
import { getLangTerm } from "lang";
import { types } from "redux/types";
import {
    textStyles
} from "styles/shared";

const MailingListForm = () => {

    const scrollIntoView = useScrollIntoView();

    const lang = useSelector(state => state.settings.lang);

    const dispatch = useDispatch();
    const isSubmitting = useSelector(state => state.mailingList.isSubmitting);
    const submitError = useSelector(state => state.mailingList.submitError);
    const didSubmit = useSelector(state => state.mailingList.didSubmit);

    const { control, handleSubmit, formState: { errors } } = useForm();
    const submitFormRef = useRef();

    const onMailingListSignup = (data) => {
        if (!isSubmitting) {
            data.language = lang;
            dispatch({
                type: types.MAILING_LIST_SIGNUP,
                data
            });
        }
    };

    const inputOnFocus = (target) => {
        // scroll so the top of the form is at the top of the screen
        scrollIntoView(target, {
            align: "top"
        })
    }

    return <>
        <View style={settingsStyles.signupContainer}
            ref={submitFormRef}
        >
            <Text style={settingsStyles.signupHeader}>
                { getLangTerm("sign-up-for-mailing-list", lang) }
            </Text>
            <Text style={settingsStyles.signupSubHeader}>
                { getLangTerm("wont-spam", lang) }
            </Text>
        </View>
        <Controller
            control={control}
            render={({ field: { onChange, onBlur }, value }) => (
                <Input
                    size="large"
                    label={<><Text style={{fontFamily:"semi-bold"}}>{getLangTerm("email", lang)}<Text style={settingsStyles.labelRequired}>*</Text></Text></>}
                    onFocus={() => inputOnFocus(submitFormRef.current)}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value}
                />
            )}
            name="email"
            rules={{
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
            }}
            defaultValue=""
        />
        {errors.email && <><Text style={settingsStyles.requiredWarning}>{getLangTerm("email-is-required", lang)}</Text></>}
        <Controller
            control={control}
            render={({ field: { onChange, onBlur }, value }) => (
                <Input
                    size="large"
                    label={<><Text style={{fontFamily:"semi-bold"}}>{getLangTerm("first-name", lang)}</Text></>}
                    onFocus={() => inputOnFocus(submitFormRef.current)}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value}
                />
            )}
            name="firstName"
            defaultValue=""
        />
        <Controller
            control={control}
            render={({ field: { onChange, onBlur }, value }) => (
                <Input
                    size="large"
                    label={<><Text style={{fontFamily:"semi-bold"}}>{getLangTerm("last-name", lang)}</Text></>}
                    onFocus={() => inputOnFocus(submitFormRef.current)}
                    onBlur={onBlur}
                    onChangeText={value => onChange(value)}
                    value={value}
                />
            )}
            name="lastName"
            defaultValue=""
        />
        <View style={settingsStyles.buttonContainer}>
            <TouchableOpacity onPress={handleSubmit(onMailingListSignup)}>
                <View style={settingsStyles.button}>
                    <Text style={settingsStyles.buttonText}>{ getLangTerm("sign-up", lang)}</Text>
                    {isSubmitting && <ActivityIndicator size="small" style={{marginLeft: 10}} color="#fff" /> }
                </View>
            </TouchableOpacity>
        </View>
        { didSubmit && <View style={textStyles.centerText}>
            <Text style={settingsStyles.submitThanks}>
                { getLangTerm("signup-thanks", lang) }
            </Text>
        </View> }
        { submitError && <View style={textStyles.centerText}>
            <Text style={settingsStyles.error}>{getLangTerm("signup-error", lang)}</Text>
        </View>}
    </>
};

export default MailingListForm;