import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text, View, StyleSheet, TouchableWithoutFeedback, ActivityIndicator  } from "react-native";
import * as FileSystem from 'expo-file-system';
import { Slider } from '@miblanchard/react-native-slider';
import { FontAwesome } from '@expo/vector-icons';
import { Audio } from 'expo-av';

import { types } from "redux/types";
import { dropShadow } from "styles/shared";
import { getLangTerm } from "lang";
import { isWeb } from "utils/platform";
import { screenContainer } from "../styles/shared";

const playerStyles = StyleSheet.create({
    container: {
        ...dropShadow(0, -2, 4, .25),
        height: 80,
        paddingVertical: 20,
        backgroundColor: "#fff",
        justifyContent: "center"
    },
    contentContainer: {
        ...screenContainer,
        paddingHorizontal: 28,
        flexDirection: "row",
        alignItems: "center"
    },
    button: {
        width: 24
    },
    slider: {
        flex: 1,
        marginHorizontal: 10
    },
    track: {
        height: 4,
        borderRadius: 4,
        borderWidth: 4,
        borderColor: "#30C0D3"
    },
    thumb: {
        width: 24,
        height: 24,
        borderRadius: 20,
        borderWidth: 5,
        borderColor: "#30C0D3",
        backgroundColor: "#FFF",
    },
    loading: {
        marginRight: 20
    }
});

function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

const soundObject = new Audio.Sound();
Audio.setAudioModeAsync({
    playsInSilentModeIOS: true
});

const AudioPlayer = ({slug, routeSlug, audioURL, color, timestamp}) => {

    if (!audioURL) {
        return null;
    }

    const lang = useSelector(state => state.settings.lang);

    const [uri, setUri] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);

    const isMounted = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false);
    // const [audioLoading, setAudioLoading] = useState(false);
    // const [audioLoaded, setAudioLoaded] = useState(false);
    const [duration, setDuration] = useState(null);
    const [positionStr, setPositionStr] = useState("0:00");
    const [position, setPosition] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        checkCache();
        return async() => {
            // prevent state updates after the audio player is unmounted
            isMounted.current = false;
            try {
                await soundObject.unloadAsync();
            } catch (e) {
                console.log("Unmount Audio Error");
            }
        }
    }, [])

    const checkCache = async() => {
        isMounted.current = true;
        setFileLoading(true);

        const remoteURL = `${audioURL}${timestamp ? `?t=${timestamp}` : "" }`;

        if (isWeb) {
            // web doesn't need to check the cache, just use the url
            setUri(remoteURL);
            return;
        }

        const audioHash = `${routeSlug ? `${routeSlug}-` : ""}${slug}`;
        const path = `${FileSystem.cacheDirectory}${audioHash}.mp3`;

        const cachedAudio = await FileSystem.getInfoAsync(path);

        if (cachedAudio.exists) {
            setUri(cachedAudio.uri);
            return;
        }
        
        const newAudio = await FileSystem.downloadAsync(remoteURL, path);
        setUri(newAudio.uri);
    }

    const seek = async (time) => {
        time = Math.round(time);
        await soundObject.setPositionAsync(time);
        setPosition(time);
        setIsPlaying(wasPlaying);
        setWasPlaying(false);
    }

    const onSlidingStart = () => {
        setWasPlaying(isPlaying);
        setIsPlaying(false);
    }

    const updatePositionStr = (p, d) => {
        setPositionStr(`${millisToMinutesAndSeconds(p)}/${millisToMinutesAndSeconds(d)}`)
    };

    const onPlaybackStatusUpdate = async (status) => {
        const { positionMillis: p, durationMillis: d } = status;
        if (isNaN(p) || isNaN(d)) return;
        if(isMounted.current) {
            setPosition(status.positionMillis);
            setDuration(d);
            updatePositionStr(p, d);
        }
    }

    // const setDurationFromPlayback = async(status) => {
    //     // this should only happen once, when the audio is loaded
    //     const { positionMillis: p, durationMillis: d } = status;
    //     if (isNaN(p) || isNaN(d)) return;
    //     await soundObject.pauseAsync();
    //     await soundObject.setPositionAsync(0);
    //     await soundObject.setStatusAsync({ volume: 1 });
    //     setDuration(d);
    //     updatePositionStr(0, d);

    //     await soundObject.setStatusAsync({ progressUpdateIntervalMillis: 500 })
    //     await soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
    //     setFileLoading(false);
    // }

    const startPlaying = async() => {
        dispatch({
            type: types.PLAY_AUDIO,
            userActionData: { slug, audioURL, positionStr }
        });
        setIsPlaying(true);
        await soundObject.playAsync();
    }
    const stopPlaying = async() => {
        dispatch({
            type: types.PLAY_AUDIO,
            userActionData: { slug, audioURL, positionStr, stop: true }
        });
        setIsPlaying(false);
        await soundObject.pauseAsync();
    }

    useEffect(() => {
        const loadAudio = async() => {
            try {
                await soundObject.loadAsync({ uri });
                const status = await soundObject.getStatusAsync();
                // debugger;
                if(isMounted.current) {
                    if (status.durationMillis !== null && !isNaN(status.durationMillis)) {
                        setDuration(status.durationMillis);
                        updatePositionStr(0, status.durationMillis);
                    }
                    // setAudioLoaded(true);
                    await soundObject.setStatusAsync({ progressUpdateIntervalMillis: 4000 })
                    await soundObject.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
                    setFileLoading(false);

                    // took this out because it was causing the audio to play on load briefly sometimes
                    // await soundObject.setStatusAsync({ volume: 0 });
                    // await soundObject.playAsync(); // trigger the audio to start playing to get the duration (needed on web)                    
                    // await new Promise(resolve => setTimeout(resolve, 50));
                    // await soundObject.pauseAsync();
                    // debugger;
                }
            } catch (error) {
                // An error occurred!
                console.log("loadAudio error",);
                console.log(error);

            }
        }
        if (uri !== null) {
            loadAudio();
        }
    }, [uri])

    const togglePlaying = (newPlaying) => {
        if (newPlaying) {
            startPlaying();
        } else {
            stopPlaying();
        }
    }
    
    return <View style={playerStyles.container}>
        <View style={playerStyles.contentContainer}>
            { fileLoading && <>
                <ActivityIndicator size="small" color="#287773" style={playerStyles.loading} />
                <Text>{getLangTerm("audio-loading", lang)}</Text>
            </> }
            { uri && !fileLoading && <>
                <View style={playerStyles.button}>
                    <TouchableWithoutFeedback onPress={() => { togglePlaying(!isPlaying)}}>
                        <FontAwesome name={(isPlaying || wasPlaying ) ? "pause" : "play"} size={26} style={{color: color}} />
                    </TouchableWithoutFeedback>
                </View>
                <View style={playerStyles.slider}>
                    <Slider
                        maximumValue={Math.max(duration || 0, 1, position + 1)}
                        onSlidingStart={onSlidingStart}
                        onSlidingComplete={seek}
                        value={position || 0}
                        thumbStyle={playerStyles.thumb}
                        trackStyle={playerStyles.track}
                    />
                </View>
                <Text>{positionStr}</Text>
            </>}
        </View>
    </View>

};
export default AudioPlayer;