import { StyleSheet } from 'react-native';
import { colors, colorNames, fullscreenView, flexCenter, dropShadow } from "styles/shared";

const getSharedPageStyles = (routeColor = colorNames.seaBlue) => {
    return {
        headerContainer:{
            // rgb(0 0 0 / 0%) 0px 4px 4px
            // rgb(0 0 0 / 25%) 0px 4px 4px
        },
        headerContainerShadow: {
            backgroundColor: "#fff", // required to make the shadow work in the app
            ...dropShadow(0, 4, 4, 0.25),
            width: "100%",
            height: 8,
            position: "absolute",
            bottom: 0,
        },
        headerContainerInner: {
            paddingTop: 8,
            paddingBottom: 5,
            backgroundColor: "#fff",
            flex: 1,
            flexDirection: "row",
            paddingRight: 28,
            alignItems: "center",
            justifyContent: "center"
        },
        headerCloseSize: {
            height: 28
        },
        headerCloseContainer: {
            width: 38,
            height: 32,
            alignItems: "center",
            justifyContent: "center"
        },
        header: {
            fontFamily: "bold",
            fontSize: 24,
            lineHeight: 24,
            color: "#000",
            paddingTop: 7,
            marginBottom: 0
        },
        headerText: {
            flexGrow: 1,
            flexShrink: 1,
            marginRight: 10
        },
        premiumContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 5
        },
        premiumIcon: {
            width: 20,
            height: 18,
            marginRight: 7
        },
        premiumText: {
            fontSize: 14
        },
        description: {
            marginVertical: 10,
            fontSize: 16,
            lineHeight: 20,
            marginHorizontal: 28
        },
        link: {
            marginVertical: 10,
            fontSize: 18,
            lineHeight: 20,
            marginHorizontal: 28,
            color: colors.userActions,
            fontFamily: "bold"
        },
        walkingIconSize: {
            height: 16
        },
        contentHeader: {
            marginTop: 10,
            fontSize: 24,
            lineHeight: 24,
            marginHorizontal: 28,
            fontFamily: "bold",
            color: "#000",
            display: "sticky",
            top: 0,
        },
        markerIcon: {
            ...flexCenter,
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: routeColor
        },
        icon: {
            color: "#fff"
        },
        iconSize: {
            height: 24
        },
        audioPlayer: {
            ...dropShadow(0, -2, 4, .25),
            height: 80,
            paddingHorizontal: 28,
            paddingTop: 20,
            backgroundColor: "#fff"
        }
    };
};

export const getPageStyles = (routeColor) => {
    const sharedDetailsStyles = getSharedPageStyles(routeColor);
    return StyleSheet.create({
        ...sharedDetailsStyles,
        container: {
            ...fullscreenView,
            zIndex: 2
        },
        scrollView: {
            flex: 1,
            backgroundColor: "#FFF"
        },
        closeButton: {
            ...flexCenter,
            ...dropShadow(),
            position: "absolute",
            top: 50,
            left: 12,
            width: 50,
            height: 50,
            borderRadius: 25,
            backgroundColor: "#fff",
            zIndex: 11
        },
        closeButtonIcon: {
            height: 14,
            width: 14
        },
        thumb: {
            borderRadius: 5,
            marginRight: 14
        },
        subtitle: {
            color: colors.subtitleColor,
            fontSize: 14,
            marginLeft: 8
        },
        stopList: {
            marginHorizontal: 28
        },
        stopListHeader: {
            fontFamily: "bold",
            fontSize: 16,
            marginBottom: 10
        },
        stopListItem: {
            borderWidth: 2,
            borderColor: routeColor,
            borderRadius: 10,
            width: "100%",
            padding: 10,
            marginBottom: 10,
            flexDirection: "row"
        },
        stopListItemThumb: {
            borderRadius: 5,
            marginRight: 14,
            width: 50,
            height: 50
        },
        stopListContent: {
            display: "flex",
            flexDirection: "column",
            flex: 1
        },
        stopListItemTitle: {
            fontFamily: "bold",
            fontSize: 18
        },
        stopListItemSubtitle: {
            fontFamily: "semi-bold",
            fontSize: 16
        }
    })
};

export const getPlacePageStyles = (color) => {
    const styles = getPageStyles(color);
    return {
        ...styles,
        subtitle: {
            ...styles.subtitle,
            marginLeft: 0
        },
        articleHeader: {
            marginHorizontal: 28,
            color: color,
            fontFamily: "bold"
        },
        articleTitle: {
            marginHorizontal: 28,
            fontSize: 16,
        }
    }

}

export const getInfoPageStyles = (routeColor = colorNames.seaBlue) => {
    const sharedDetailsStyles = getSharedPageStyles(routeColor);
    return StyleSheet.create({
        ...sharedDetailsStyles,
        container: {
            ...fullscreenView,
            zIndex: 2,
            ...dropShadow(0, -2, 4, .25),
            top:0,
            // paddingTop:40
        }
    });
};