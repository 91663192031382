import { types } from "../types";

const initialState = {
    lang: null,
    langLoaded: false
};

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LANG_LOADED: {
            return { lang: action.lang, langLoaded: true };
        }
        case types.LANG_CHANGED: {
            return { ...state, lang: action.lang };
        }
        default:
            return state;
    }
};