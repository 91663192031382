export const staticAccessCodesList = {
    "chrisplus1": {
        routes: ["gastown", "stanley-park-seawall", "stanley-park-inner"],
    },
    "steamclock77": {
        routes: ["gastown"], //unlocks gastown
    },
    "thecambie": {
        routes: ["gastown"], //unlocks gastown
    },
    "seawall30": {
        routes: ["stanley-park-seawall", "stanley-park-inner"], //unlocks stanley park
    },
    "carfreeday": {
        routes: ["gastown", "stanley-park-seawall", "stanley-park-inner"], //unlocks all routes
        inactive: true, // inactive routes can still be accessed by people who entered the code, but not new people
    },
    "test":{
        routes: ["stanley-park-seawall"],
        inactive: true,
    },
    "soulfood32": {
        routes: ["police-museum-strathcona"], //unlocks police museum
    }
}