
const debug = true; 

const log = (content, local, style) => {
    if (debug && local) {
        if (style) {
            console.log(`%c ${content}`, style);
        } else {
            console.log(content);
        }
        
    }
}

export const fancylog = {
    log: (content, local) => {
        log(content, local)
    },
    yellow: (content, local) => {
        log(content, local, 'color: #e9bc62')
    },
    green: (content, local) => {
        log(content, local, 'color: #9AB973')
    }
}