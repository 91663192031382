import React, { useContext } from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

import { getDirectionMarkerStyles } from "styles/markers";
import { colorNames, getRouteColor } from "styles/shared";
import { BaseMarker } from './BaseMarker';
import { MarkerContext } from "./MarkerContext";
import { isWeb } from 'utils/platform';
import { MarkerText } from './MarkerText';

const DirectionMarker = ({ stop }) => {


    // const { slug, routeID, latlng, onPress, color, zIndex, displayDir, isWalkingRoute, selectedRoute, selectedStop } = props
    const { slug, stopType, title, displayDir, latlng, zIndex = 1 } = stop;

    const {
        markerOnPress,
        selectedStopData,
        tracksViewChanges,
        viewBox,
        getMarkerX,
        getIsActive
    } = useContext(MarkerContext);


    // let markerSize = "small";
    // // make large when the route is selected and this marker is in the route
    // if (isWalkingRoute && routeID == selectedRouteData.slug) {
    //     // this marker is part of the selectedRoute, make the size bigger
    //     // a large marker will also have the styles for the distance string for now
    //     markerSize = "large";
    // }
    let markerSize = "large";
    
    let thisMarkerStyles = getDirectionMarkerStyles({ markerSize, displayDir, isActive, color: colorNames.actionGreen });

    const isActive = getIsActive(slug);
    const markerX = getMarkerX(displayDir);
    const onPress = () => {
        markerOnPress(stop);
    }

    return <>

        <BaseMarker
            key={`${slug}-marker-text`}
            coordinate={latlng}
            anchor={{x: markerX, y: 1}}
            style={{
            // ...thisMarkerStyles.marker,
                zIndex: (zIndex * 10) + 4 
            }}
            onPress={onPress}
            tracksViewChanges={tracksViewChanges}
        >
            <MarkerText
                onPress={onPress}
                title={title}
                routeMarkerStyles={thisMarkerStyles}
            />
        </BaseMarker>

        <BaseMarker
            key={`${slug}-marker`}
            coordinate={latlng}
            anchor={{x: 0.5, y: 1}}
            style={{
                ...thisMarkerStyles.marker,
                zIndex: (zIndex * 10) + 5 
            }}
            onPress={onPress}
            tracksViewChanges={tracksViewChanges}
        >
            <View 
                style={{
                    ...(isWeb ? thisMarkerStyles.markerContainerWeb : thisMarkerStyles.markerContainer)
                }}>
                <Svg
                    style={thisMarkerStyles.markerBG}
                    viewBox={viewBox}
                >
                    <Path d="M11.4577 47.8808C14.2545 50.8535 17.0432 53.2707 19.13 54.9439L24 58L28.87 54.9439C30.9568 53.2707 33.7455 50.8535 36.5423 47.8808C42.0378 42.0397 48 33.5964 48 24.1667C48 17.7573 45.4714 11.6104 40.9706 7.07825C36.4697 2.54612 30.3652 0 24 0C17.6348 0 11.5303 2.54612 7.02944 7.07825C2.52856 11.6104 0 17.7573 0 24.1667C0 33.5964 5.96223 42.0397 11.4577 47.8808Z"
                        fill={selectedStopData?.slug === slug ? colorNames.actionGreen : getRouteColor(stop.routeID)}
                        stroke="none"
                    />
                </Svg>
                { renderMarkerInner(stopType, thisMarkerStyles)}
            </View>
        </BaseMarker>
    </>
}
export default React.memo(DirectionMarker);

const renderMarkerInner = (stopType, thisMarkerStyles) => {

    if (stopType === "direction") {
        return <FontAwesome name="map-signs" size={thisMarkerStyles.iconSize.height} style={thisMarkerStyles.icon} />
    }
    if (stopType === "info") {
        return <FontAwesome name="info" size={thisMarkerStyles.iconSize.height} style={thisMarkerStyles.icon} />
    }
    if (stopType === "endpoint") {
        return <FontAwesome5 name="flag-checkered" size={thisMarkerStyles.iconSize.height} style={thisMarkerStyles.icon} />
    }
}