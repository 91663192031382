import React, { useContext } from 'react';
import { useSelector } from "react-redux";
import { Text, View } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { FontAwesome5 } from '@expo/vector-icons';
import { getLangTerm } from "lang";

import { getPointMarkerStyles } from "styles/markers";
import { getRouteColor } from "styles/shared";
import { MarkerContext } from "./MarkerContext";
import { BaseMarker } from './BaseMarker';
import { isWeb } from 'utils/platform';

const PointMarker = ({ route, point, i }) => {
    const {
        tracksViewChanges,
        viewBox,
        getMarkerX
    } = useContext(MarkerContext);

    const { slug } = route;
    const { type, label, latitude, longitude, text, displayDir, zIndex = 1 } = point;

    const latlng = {
        latitude,
        longitude
    }

    const lang = useSelector(state => state.settings.lang);
    
    const thisMarkerStyles = getPointMarkerStyles({color: getRouteColor(route.slug), displayDir});

    return <>
        <BaseMarker
            key={`${slug}-point-${i}-marker-text`}
            coordinate={latlng}
            anchor={{x: getMarkerX(displayDir), y: 1}}
            style={{
                zIndex: zIndex ? (zIndex * 10) + 4 : 4
            }}
            tracksViewChanges={tracksViewChanges}
        >
            { renderMarkerText({type, label, text, lang, thisMarkerStyles}) }
        </BaseMarker>
        <BaseMarker
            key={`${slug}-point-${i}-marker`}
            coordinate={latlng}
            anchor={{x: 0.5, y: 1}}
            style={{
                ...thisMarkerStyles.marker,
                zIndex: zIndex ? (zIndex * 10) + 5 : 5
            }}
            tracksViewChanges={tracksViewChanges}
        >
            <View style={isWeb ? thisMarkerStyles.markerContainerWeb : thisMarkerStyles.markerContainer}>
                <Svg
                    style={thisMarkerStyles.markerBG}
                    viewBox={viewBox}
                >
                    <Path d="M11.4577 47.8808C14.2545 50.8535 17.0432 53.2707 19.13 54.9439L24 58L28.87 54.9439C30.9568 53.2707 33.7455 50.8535 36.5423 47.8808C42.0378 42.0397 48 33.5964 48 24.1667C48 17.7573 45.4714 11.6104 40.9706 7.07825C36.4697 2.54612 30.3652 0 24 0C17.6348 0 11.5303 2.54612 7.02944 7.07825C2.52856 11.6104 0 17.7573 0 24.1667C0 33.5964 5.96223 42.0397 11.4577 47.8808Z"
                        fill={thisMarkerStyles.markerBG.color}
                        stroke="none"
                    />
                </Svg>
                { renderMarkerInner({type, thisMarkerStyles})}
            </View>
        </BaseMarker>
    </>
}
export default React.memo(PointMarker);

const renderMarkerText = ({type, label, text, lang, thisMarkerStyles}) => {

    let showText = true;

    // if (
    //   renderDistance > 10 ||
    //   (longitudeDelta > 0.004023313522353078 && renderDistance > 6)
    // ) {
    //   showText = false;
    // }

    let title;

    if (text) {
        title = text[lang] || text["en"];
    } else {
        switch(type) {
            case "right-turn":
                title = `${getLangTerm("right-turn", lang)}${label ? `${getLangTerm("on", lang)} ${label}` : ""}`;
                break;
            case "left-turn":
                title = `${getLangTerm("left-turn", lang)}${label ? `${getLangTerm("on", lang)} ${label}` : ""}`;
                break;
            case "cross":
                title = `${getLangTerm("cross", lang)}${label}`;
                break;
            case "cross-both":
                title = `${getLangTerm("cross-both", lang)}`;
                break;
            case "traffic":
                title = `${getLangTerm("traffic", lang)}`;
                break;
            case "bikes":
                title = `${getLangTerm("bikes", lang)}`;
                break;
            case "down-stairs":
                title = `${getLangTerm("down-stairs", lang)}`;
                break;
            default:
                title = `${getLangTerm(type, lang)}`;
                break;
        }
    }

    if (showText) {
        return <View style={ isWeb ? thisMarkerStyles.flyoutContainerWeb : thisMarkerStyles.flyoutContainer}>
            <View style={thisMarkerStyles.textContainer}>
                <Text style={thisMarkerStyles.header}>{title}</Text>
            </View>
        </View>
    }
}

const renderMarkerInner = ({type, thisMarkerStyles}) => {
    let icon = "map-marker";
    let iconStyles = {...thisMarkerStyles.icon};

    switch(type) {
        case "right-turn":
            icon = "directions"
            break;
        case "left-turn":
            icon = "directions"
            iconStyles.transform = [{ scaleX: -1 }];
            break;
        case "cross":
            icon = "arrow-circle-up"
            break;
        case "cross-both":
            icon = "directions"
            break;
        case "traffic":
        case "traffic-bikes":
            return <FontAwesome5 name="car" size={thisMarkerStyles.iconSize.height} style={iconStyles} />
        case "bikes":
            return <FontAwesome5 name="bicycle" size={thisMarkerStyles.iconSize.height} style={iconStyles} />
        case "down-stairs":
            icon = "arrow-circle-up";
            break;
        case "fish-traps":
        case "hollow-tree":
            icon = "eye"
            break;
        case "zig-zags":
        case "high-road":
        case "low-road":
            icon = "exclamation"
            break;
        case "high-five-point":
            return <FontAwesome5 name="hand-paper" size={thisMarkerStyles.iconSize.height} style={iconStyles} />
    }

    return <FontAwesome5 name={icon} size={thisMarkerStyles.iconSize.height} style={iconStyles} />
}