import React from 'react';
import { useSelector } from "react-redux";
import { Text, View, StyleSheet } from 'react-native';

import { getLangTerm } from "lang";

const purchaseSuccessStyles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: 20
    },
    containerInner: {
        maxWidth: 300,
        borderWidth: 2,
        borderRadius: 15,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    text: {
        fontSize: 16
    }
});

const PurchaseSuccess = ({ routeSlug }) => {

    const lang = useSelector(state => state.settings.lang);
    const routeData = useSelector(state => state.mapData.routeData);
    const purchaseSuccess = useSelector(state => state.purchases.purchaseSuccess);

    // get the offerings of the route and see if it is the one that has been purchased
    // this is only needed on the routes list, in case more than one purchase button is visible
    const thisRouteData = routeData.filter(route => route.slug == routeSlug)[0];
    const isPurchased = thisRouteData?.offerings?.indexOf(purchaseSuccess) > -1;

    return isPurchased && <View style={purchaseSuccessStyles.container}>
        <View style={{
            ...purchaseSuccessStyles.containerInner,
            borderColor: thisRouteData.color
        }}>
            <Text style={purchaseSuccessStyles.text}>{ getLangTerm("purchase-successful", lang).replace("||title||", thisRouteData.title) }</Text>
        </View>
    </View>
}
export default PurchaseSuccess;