export const fr = {
    "min-away": "À {0} min",
    "mins-away": "À {0} mins",
    "2-hours-away": "2+ heures",
    "hours": "heures",
    "routes": "Itinéraires",
    "stops": "Arrêts",
    "show-route-stops": "Afficher les arrêts d'itinéraire",
    "near-me": "Proche de Moi",
    "back-to-map": "Retour à la Carte",
    "featured-in": "En vedette dans",
    "audio-loading": "Chargement de Contenu Audio",
    "start-here": "Commencer Ici",
    "walk-here-to-start": "Marchez Ici Pour Commencer",
    "you-are-here": "Tu es là!",
    "press-for-details": "Appuyez sur pour plus de détails",
    "press-next-stop": "Appuyez sur Next pour Continuer",
    "finished-route": "Route terminée!",
    "walk-to": "Marche jusqu'à",
    "start": "Début",
    "next": "Prochain",
    "previous": "Précédent",
    "start-this-route": "Commencer cet itinéraire",
    "preview-this-route": "Aperçu de cet itinéraire",
    "offline-mode-button": "Afficher en mode hors ligne",
    "show-on-map": "Afficher sur la carte",
    "make-next-stop": "Faire le prochain arrêt",
    "switch-to-this-route": "Basculer vers cet itinéraire",
    "get-directions-in-google-maps": "Obtenir des Itinéraires dans Google Maps",
    "exit-route": "Route de Sortie",
    "welcome-to-vanwalks": "Bienvenue à VanWalks",
    "select-a-route": "Sélectionnez un itinéraire pour commencer.",

    "left-turn": "Tournez à gauche",
    "right-turn": "Tournez à droite",
    "on": " sur",
    "cross": "Traverser ",
    "cross-both": "Traverser les Deux Rues",
    "traffic": "Surveillez le Trafic",
    "bikes": "Surveillez les Vélos",
    "down-stairs": "En bas des Escaliers",
    "fish-traps": "Pièges à poissons indigènes",
    "zig-zags": "Les zigzags sont approximatifs",
    "high-five-point": "Tape M'en Cinq Point",
    "hollow-tree": "Arbre Creux",
    "traffic-bikes": "Surveillez la Circulation et les Vélos",
    "bamboo-grove": "Bambouseraie",
    "chehalis-cross": "Chehalis Cross",
    "north-shore-mountains": "Belvédère des montagnes de la Côte-Nord",
    "shakespeare": "Statue de Shakespeare",
    "raven-spirit-of-transformation": "Corbeau: Esprit de Transformation",

    "checking-for-updates": "Vérification des mises à jour",
    "fetching-route-data": "Récupération des données d'itinéraire",
    "loading-cached-images": "Chargement des images mises en cache",
    "building-map": "Carte du bâtiment",
    "routes-loading-error": "Une erreur s'est produite lors du chargement des itinéraires.",
    "route-loading-error": "Une erreur s'est produite lors du chargement de l'itinéraire.",

    "welcome-header": "Bienvenue chez VanWalks!",
    "follow-us": "Suivez-nous pour toutes nos aventures et mises à jour!",
    "on-the-web": "Découvrez VanWalks sur le Web:",
    "contact-us": "Nous Contacter",
    "hear-from-you": "Nous voulons de vos nouvelles! N'hésitez pas à nous faire part de vos commentaires et remarques.",
    "email": "Email",
    "sign-up-for-mailing-list": "Inscrivez-vous à notre liste de diffusion",
    "wont-spam": "Nous promettons que nous ne vous enverrons pas de spam.",
    "email-is-required": "Une adresse e-mail valide est requise.",
    "first-name": "Prénom",
    "last-name": "Nom de famille",
    "sign-up": "S'inscrire",
    "signup-error": "Une erreur s'est produite lors de votre inscription. Veuillez réessayer.",
    "signup-thanks": "Merci! Nous vous enverrons des mises à jour sur l'application Vanwalks et le nouveau contenu.",
    "purchases": "Achats",
    "in-app-purchases": "Achats Intégrés",
    "in-app-purchases-warning": "VanWalks migre vers notre plateforme Web à map.vanwalks.ca. Veuillez contacter VanWalks pour transférer un achat intégré.",
    "in-app-purchases-web": "VanWalks n'utilise plus les achats intégrés. Si vous avez un ancien achat intégré, veuillez contacter VanWalks pour le transférer ici.",
    "access-codes": "Codes d'accès",
    "access-code-description": "Entrez votre code d'accès pour déverrouiller le contenu premium. Entrez votre email pour recevoir vos codes d'accès.",
    "submit": "Soumettre",
    "enter-access-code-or-email": "Entrez le code d'accès ou l'e-mail",
    "redemption-limit-reached": "Vous avez atteint la limite d'utilisation de ce code d'accès.", 
    "already-redeemed": "Ce code d'accès a déjà été utilisé dans ce navigateur. Le contenu est déverrouillé.",
    "access-code-redemption-success": "Le code d'accès a été utilisé. Le contenu a été déverrouillé.",
    "access-codes-emailed": "Les codes d'accès vous ont été envoyés par mail. Merci de consulter vos emails.",
    "valid-access-code": "Le code d'accès est valide. Le contenu a été déverrouillé",
    "invalid-access-code": "Code d'accès invalide",
    "inactive-access-code": "Le code d'accès est inactif.",
    "no-access-codes-found": "Code d'accès ou adresse e-mail invalide. Veuillez réessayer.",
    "error-loading-access-codes": "Une erreur s'est produite lors du chargement de vos codes d'accès.",
    "generic-error": "Il y avait une erreur. Veuillez réessayer ou contacter VanWalks.",
    "premium-content-unlocked": "Contenu premium débloqué",
    "access-code-error": "Il y a eu une erreur avec un code d'accès. Vérifiez le menu des paramètres.",
    "access-code-error-details": "Ces codes d'accès avaient une erreur",
    "enjoy": "Profitez!",
    "unlocks": "Déverrouille",
    "view-purchase-info": "Afficher Les Informations D'achat",
    "coming-soon": "Bientôt disponible",

    "location-services": "Services de Location",
    "location-header": "VanWalks est meilleur si vous activez votre localisation",
    "location-subheader": "Votre téléphone vous demandera ensuite des autorisations, si ce n'est pas encore le cas",
    "update-location-settings": "La préférence d'emplacement peut être modifiée sur la page des paramètres",
    "location-is-enabled": "La localisation est activée. Pour économiser la batterie, vous pouvez l'éteindre",
    "allow": "Autoriser",
    "turn-off": "Éteindre",
    "location-error": "Une erreur s'est produite avec les services de localisation. Veuillez vérifier dans les paramètres de votre appareil que ce navigateur est autorisé à accéder aux services de localisation.",

    "chris-intro": "Salut! Je suis Chris King, créateur de VanWalks. J'ai toujours pensé que la meilleure façon d'explorer une ville était à pied. J'ai erré dans certaines des plus grandes villes du monde! C'est maintenant à mon tour de partager ma maison avec le monde ! Vingt ans à jouer dans des groupes, à travailler de petits boulots, à faire du vélo au softball et à errer en général m'ont emmené dans de nombreux coins et recoins de cette grande ville. J'ai rencontré des artistes, des musiciens, des neurochirurgiens, des milliardaires et des étudiants. J'ai rassemblé ces itinéraires pour donner une excellente introduction à la ville que j'aime, ma maison, Vancouver.",
    "how-to-use": "Comment utiliser VanWalks",
    "how-to-select-route": "Sélectionnez un itinéraire dans la liste",
    "how-to-route-start": "Rendez-vous au départ de l'itinéraire.",
    "how-to-enjoy-at-home": "Les itinéraires peuvent également être appréciés dans le confort de votre maison.",
    "how-to-next-prev": "Utilisez les flèches Suivant et Précédent pour parcourir l'itinéraire",
    "how-to-stop-name": "Appuyez sur le nom de l'arrêt pour en savoir plus sur chaque emplacement",
    "how-to-enjoy": "Profitez!",
    "how-to-exit": "Appuyez sur Quitter l'itinéraire pour revenir au menu de l'itinéraire",
    "tips-for-success": "Conseils pour Réussir",
    "location": "Emplacement",
    "location-body": "L'activation de l'emplacement est un excellent moyen de tirer le meilleur parti de VanWalks, mais il y a quelques inconvénients. Même dans un endroit de haute technologie comme Vancouver, l'emplacement de votre téléphone est souvent approximatif. Les signaux rebondissent autour des bâtiments et votre téléphone peut basculer entre différentes tours et points d'accès. Même dans Google Maps, vous pouvez trouver votre position de l'autre côté de la rue ou à l'intérieur d'un bâtiment. Il est préférable d'utiliser également les noms de rue et les intersections pour vous assurer que vous êtes au bon endroit.",
    "battery": "Batterie",
    "battery-body": "Toute application qui affiche votre position sur une carte utilisera beaucoup de batterie. Même les applications avec des budgets énormes comme Google Maps ou Pokemon Go consomment beaucoup de batterie. VanWalks fait tout son possible pour réduire l'utilisation de la batterie, mais nous recommandons toujours d'utiliser une batterie externe. Lorsque vous êtes sur une longue portion de l'itinéraire, vous pouvez noter où se trouve le prochain arrêt ou virage et éteindre votre écran. Vous pouvez économiser la batterie et profiter de la ville en même temps !",
    "data": "Data",
    "data-body-1": "VanWalks fonctionne mieux avec une connexion de données. Heureusement, les itinéraires sont fortement optimisés pour ne pas utiliser beaucoup de données. Un itinéraire complet de VanWalks utilise moins de données que l'ouverture accidentelle d'Instagram.",
    "data-body-2": "Nous avons également le mode hors ligne. Vous pouvez télécharger l'itinéraire à l'avance en utilisant le wifi, puis en profiter sans connexion de données en utilisant des cartes statiques. Vous pouvez également utiliser le mode hors ligne pour enregistrer des données même avec une connexion de données.",
    "data-body-3": "La ville de Vancouver propose également un réseau Wifi gratuit. C'est peut-être un peu inégal, mais c'est suffisant pour les besoins en données de VanWalks. De nombreux restaurants de grandes chaînes proposent le wifi, mais cela ne signifie pas que vous devez y manger.",
    "city-of-vancouver-wifi-info": "Informations sur le réseau Wifi gratuit de la ville de Vancouver",
    "traffic-tip": "Circulation",
    "traffic-body-1": "Si vous venez d'Europe, vous remarquerez peut-être à quel point les véhicules nord-américains sont gros et inefficaces. Nos gros camions « légers » sont particulièrement problématiques. Ces camions ont souvent des capots aussi grands qu'une personne adulte et ils sont plus susceptibles de tuer ou de blesser des personnes. Faites attention à la circulation lorsque vous vous promenez et suivez les panneaux. À Vancouver, les voitures sont également très susceptibles de tourner à gauche au feu jaune, longtemps après le changement de feu, et elles ne vérifient souvent pas les piétons sur leur chemin. Enfin, ne suivez pas aveuglément les gens qui sautent les lumières, ils peuvent être prêts à esquiver entre les voitures, mais si vous commencez à traverser la rue après eux, vous pourriez marcher sur le chemin d'une voiture.",
    "traffic-body-2": "Cela dit, le centre-ville de Vancouver est également l'un des endroits les plus accessibles pour les piétons et les cyclistes. De nombreux visiteurs d'autres endroits en Amérique du Nord sont surpris d'apprendre que plus de 50 % des déplacements à Vancouver se font sans voiture. Vancouver est un excellent exemple de la mobilité d'une ville lorsque vous ne donnez pas la priorité aux voitures par rapport à tout le reste.",

    "premium-route": "Itinéraire Premium",
    "free-route": "Parcours Gratuit",
    "this-is-a-premium-route": "est un itinéraire premium",
    "purchase": "Acheter",
    "purchasing": "Achat",
    "error-loading-purchases": "Une erreur s'est produite lors du chargement de vos achats.",
    "purchase-error": "Une erreur s'est produite lors de votre achat.\nVeuillez réessayer.",
    "purchase-successful": "L'achat de ||title|| a réussi",
    "show-purchase-information": "Afficher les informations d'achat",
    "no-purchases": "Vous n'avez aucun itinéraire acheté.",
    "missing-purchases": "Si vous manquez un achat, essayez de «restaurer les achats».",
    "restore-purchases": "Restaurer les achats",
    "purchases-restored": "Vos achats ont été restaurés",
    "purchases-restore-error": "Une erreur s'est produite lors de la restauration de vos achats",
    "offerings-error": "Une erreur s'est produite lors du chargement des offres premium.",
    "visitor-information": "Informations aux Visiteurs",
    "and-more": "& Plus",

    "vanwalks-offline-mode" : "Mode hors ligne VanWalks",
    "offline-desc": "Activez le mode hors ligne lorsque vous êtes connecté au wifi. Cela vous permettra d'utiliser VanWalks sans connexion de données.",
    "data-saving-desc": "Le mode hors ligne peut être utilisé pour enregistrer des données. Les actifs de l'itinéraire seront téléchargés lorsqu'ils sont connectés au wifi, puis utilisés lorsque vous êtes sur l'itinéraire.",
    "enable-offline-mode": "Activer le mode hors ligne - ||title||",
    "assets": "Actifs",
    "verifying": "Vérifier",
    "abort": "Annuler",
    "offline-warning": "Assurez-vous d'avoir une connexion Wi-Fi lorsque vous activez le mode hors ligne. Vanwalks n'utilise pas beaucoup de données, chaque itinéraire est à peu près le même que l'ouverture accidentelle d'Instagram.",
    "offline-mode-ready": "Le mode hors ligne est prêt à être utilisé pour cet itinéraire. Veuillez tester avec le mode avion activé avant de quitter le wifi.",
    "offline-settings": "Paramètres hors ligne",
    "no-offline-downloads": "Vous n'avez aucun téléchargement hors ligne.",
    "offline-status-done": "Téléchargé",
    "offline-status-aborted": "Annulé",
    "reset-offline-status": "Réinitialiser l'état hors ligne",
    "offline-notice": "Une connexion de données est requise pour charger la carte dynamique. Essayez le mode hors ligne lorsque vous avez le wifi.",

    "audio-content-english": "Contenu audio disponible uniquement en anglais. Le contenu du texte a été traduit.",
}