import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import { SentryPlatform } from 'utils/sentry';

import { types } from "../types";
import { getParamsFromURI } from "utils/uri";

export const settingsAPI = () => next => action => {
    
    const langLookup = async() => {
        
        let value = await AsyncStorage.getItem('@lang2');
        if (!value) {
            // if lang isn't set, try to get from the url first
            const { lang } = getParamsFromURI();
            if (lang == "fr") {
                value = "fr";
            }
        }
        if (!value) {
            try {

                const locale = Localization.locale;
                console.log({locale});
                if (locale.toLowerCase().indexOf("fr") > -1) {
                    value = "fr";
                } else {
                    value = "en";
                }
            } catch (e) {
                console.log("COULDN'T GET LOCALE")
                SentryPlatform.captureMessage("COULDN'T GET LOCALE");
                value = "en";
            }
        }

        return next({
            type: types.LANG_LOADED,
            lang: value,
            userActionData: { lang: value }
        });
    };

    const setLang = async(lang) => {
        await AsyncStorage.setItem('@lang2', lang);
    }

    next(action);
    switch (action.type) {
        case types.LANG_LOOKUP: {
            langLookup();
            break;
        }
        case types.LANG_CHANGED: {
            setLang(action.lang);
            break;
        }
        default:
            break;
    }
};