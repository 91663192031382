import React from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { colorNames } from "styles/shared";

import { getLangTerm } from "lang";
import { openURL } from "utils/sharedActions";

const styles = StyleSheet.create({
    container: {
        maxWidth: 800,
        marginHorizontal: 10,

    },
    borderContainer: {
        borderRadius: 8,
        marginBottom: -22,
        backgroundColor: colorNames.actionGreen
    },
    containerInner: {
        // flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.actionGreen,
        paddingVertical: 12,
        fontSize: 14
    },
    header: {
        paddingTop: 6,
        height: 52,
    },
    headerText: {
        textAlign: "center",
        fontSize: 16,
        fontFamily: "bold",
        color: "#FFF"
    },
    contentText: {
        fontSize: 14
    },
    link: {
        backgroundColor: colorNames.actionGreen,
        marginTop: 4,
        paddingVertical: 6,
        paddingHorizontal: 12,
        borderRadius: 10,
    },
    linkText: {
        fontFamily: "bold",
        color: "#fff"
    }
});

const VisitorInformation = () => {

    const lang = useSelector(state => state.settings.lang);
    const detailsPageData = useSelector(state => state.mapData.detailsPageData);
    const dispatch = useDispatch();

    const { slug, visitorInformation } = detailsPageData;

    if (!visitorInformation) {
        return null;
    }

    return <View style={styles.container}>
        <View style={{
            ...styles.borderContainer,
            // backgroundColor: color
        }}>
            <View style={styles.header}>
                <Text style={styles.headerText}>{ getLangTerm("visitor-information", lang) }</Text>
            </View>
        </View>
        <View style={{
            ...styles.containerInner,
            // borderColor: color
        }}>
            { visitorInformation.map((item, i) => {
                switch (item.type) {
                    case "text":
                        return <View key={i}>
                            <Text style={styles.contentText}>{ item[lang] }</Text>
                        </View>
                    case "price":
                        return <View key={i}>
                            <Text style={styles.contentText}>{ item[lang] } {item.value}</Text>
                        </View>
                    case "link":
                        return <View key={i} style={styles.link}>
                            <TouchableWithoutFeedback key={i} onPress={()=> {
                                openURL({ url: item.url, target: slug}, dispatch);
                            }}>
                                <Text key={i} style={styles.linkText}>
                                    { item[lang] }
                                </Text>
                            </TouchableWithoutFeedback>                        
                        </View>
                }
            }) }
        </View>
    </View>
}

export default VisitorInformation;