import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Text, View, TouchableWithoutFeedback, StyleSheet } from 'react-native';

import { types } from "redux/types";
import { getLangTerm } from "lang";
import { colors, colorNames } from "styles/shared";
import { openURL } from "utils/sharedActions";

const buttonStyles = StyleSheet.create({
    routeActions: {
        marginVertical: 18,
        alignItems: "center",
    },
    startButton: {
        backgroundColor: colorNames.actionOrange,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginHorizontal: 20,
        marginBottom: 10
    },
    startButtonText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16
    },
    actionTextContainer: {
        marginVertical: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 15,
        alignItems: "center",
        borderWidth: 1,
        borderColor: "#666"
    },
    actionHeader: {
        fontSize: 15,
        lineHeight: 15,
        color: colors.subtitleColor
    },
    actionSubheader: {
        fontSize: 15,
        lineHeight: 15
    },
    actionTextButton: {
        marginTop: 4,
        borderRadius: 20,
        paddingTop: 12,
        paddingBottom: 12,
        paddingHorizontal: 20,
        backgroundColor: colorNames.actionOrange,
        lineHeight: 14
    },
    actionTextButtonText: {
        color: "#FFF",
        fontFamily: "bold",
        fontSize: 16,
    },
    googleMapsButton: {
        marginTop: 12,
        borderRadius: 20,
        paddingTop: 8,
        paddingBottom: 8,
        paddingHorizontal: 20,
        backgroundColor: colorNames.actionOrange,
        lineHeight: 14
    },
    googleMapsButtonText: {
        color: "#FFF",
        fontFamily: "bold",
        fontSize: 14,
    }
});

const RouteActionButtons = () => {

    const reachedDestination = useSelector(state => state.mapData.reachedDestination);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedStopData = useSelector(state => state.mapData.selectedStopData);
    const lang = useSelector(state => state.settings.lang);
    const detailsPageData = useSelector(state => state.mapData.detailsPageData);

    const { slug, routeSlug, routeID, routeRNG1, latlng, timestamp } = detailsPageData;

    const dispatch = useDispatch();
    const setWalkingRoute = (routeSlug, stopSlug) => {
        dispatch({
            type: types.SET_WALKING_ROUTE_STOP,
            routeSlug,
            stopSlug,
            lang,
            userActionData: { routeSlug, stopSlug }
        });
    }
    const loadSingleRoute = (routeSlug) => {
        dispatch({
            type: types.SINGLE_ROUTE_LOOKUP,
            routeSlug: routeSlug,
            stopSlug: null,
            lang,
            timestamp,
            animationDuration: 1000,
            userActionData: { routeSlug: slug }
        });
    }
    const setMapToPoint = () => {
        dispatch({
            type: types.SET_MAP_TO_POINT,
            point: latlng
        });
    }

    if (!routeRNG1) {
        // routes and stops will have a routeRNG1 key
        // places won't, don't show anything if it's a place
        return null;
    }

    const showOnMapButton = <TouchableWithoutFeedback  onPress={() => setMapToPoint(latlng)}>
        <View style={{
            ...buttonStyles.actionTextButton,
            // backgroundColor: color,
        }}>
            <Text style={{
                ...buttonStyles.actionTextButtonText
            }}>
                { getLangTerm("show-on-map", lang) }
            </Text>
        </View>
    </TouchableWithoutFeedback>

    let actionContent = <></>;

    if (routeID == selectedRouteData.slug) {
        // this is the route we are currently walking
        if (routeSlug) {
            // looking at the start of the current stop
            actionContent = showOnMapButton;

        } else if (selectedStopData && selectedStopData.slug == slug) {
            // this is the current stop
            if (reachedDestination) {
                actionContent = <View style={buttonStyles.actionTextContainer}>
                    <Text style={buttonStyles.actionHeader}>
                        { getLangTerm("you-are-here", lang) }
                    </Text>
                </View>;
            } else {
                actionContent = showOnMapButton;
            }
        } else {
            // this is a different stop
            actionContent = <TouchableWithoutFeedback key="start-here" onPress={() => setWalkingRoute(routeID, slug)}>
                <View style={buttonStyles.startButton}>
                    <Text style={buttonStyles.startButtonText}>
                        { getLangTerm("make-next-stop", lang) }
                    </Text>
                </View>
            </TouchableWithoutFeedback>;
        }
    } else {
        // slugs and stops from different routes
        actionContent = <TouchableWithoutFeedback  onPress={() => loadSingleRoute(routeID)}>
            <View style={buttonStyles.startButton}>
                <Text style={buttonStyles.startButtonText}>
                    { getLangTerm("switch-to-this-route", lang) }
                </Text>
            </View>
        </TouchableWithoutFeedback>
    }

    return <View style={buttonStyles.routeActions}>
        {actionContent}
        <TouchableWithoutFeedback onPress={()=> {
            openURL({
                url: `https://www.google.ca/maps/dir//${latlng.latitude},${latlng.longitude}/@${latlng.latitude},${latlng.longitude},16z/data=!4m2!4m1!3e2`,
                target: "google-maps"
            }, dispatch)
        }}>
            <View style={buttonStyles.googleMapsButton}>
                <Text style={buttonStyles.googleMapsButtonText}>
                    { getLangTerm("get-directions-in-google-maps", lang) }
                </Text>
            </View>
        </TouchableWithoutFeedback>
    </View>
}
export default RouteActionButtons;