import React from "react";
import { Text } from "react-native";
import { useSelector } from "react-redux";

import { getLangTerm } from "lang";
import {
    textStyles
} from "styles/shared";

const WelcomeHeader = () => {

    const lang = useSelector(state => state.settings.lang);

    return <Text style={textStyles.header}>
        { getLangTerm("welcome-header", lang) }
    </Text>
};

export default WelcomeHeader;