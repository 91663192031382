// This is a component to set up the sales rep id as a cookie to be read when the purchase it made.
// The sales rep ID is saved as a cookie in case the user comes back to the site at a later date, the rep can still get the credit
import Cookies from 'js-cookie';
import queryString from 'query-string';

const repIDToken = "repID";
const otherRepIDsToken = "otherRepIDs";

export const checkAndSetSalesRepId = () => {
    const parsedQS = queryString.parse(location.search);
    const newRepID = parsedQS.repID;
    // console.log(parsedQS);
    if (newRepID) {
        // check if the id is already in place
        const existingRepID = Cookies.get(repIDToken);
        // console.log({existingRepID})
        // get the rep id from the querystring

        if (existingRepID && existingRepID !== newRepID) {
        // check if the id is in the otherRepIDs cookie
            const otherIDs = Cookies.get(otherRepIDsToken);
            if (otherIDs) {
            // if it is, do nothing
                if (otherIDs.includes(existingRepID)) {
                    // console.log(`repID ${oldID} is already in otherRepIDs`);
                } else {
                // if it is not, add it to the otherRepIDs cookie
                    // console.log(`Adding repID ${oldID} to otherRepIDs`);
                    Cookies.set(otherRepIDsToken,  `${otherIDs},${existingRepID}`, { domain: '.vanwalks.ca' });
                }
            } else {
            // if it is not, add it to the otherRepIDs cookie
                // console.log(`Adding repID ${oldID} to otherRepIDs`);
                Cookies.set(otherRepIDsToken, existingRepID, { domain: '.vanwalks.ca' });
            }
        }
    
        // save it to the cookie
        // console.log(`Setting repID ${newRepID}`);
        Cookies.set(repIDToken, newRepID, { domain: '.vanwalks.ca' });
    }
}