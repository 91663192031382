import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import { getLangTerm } from "lang";
import { colorNames } from "styles/shared";
import { staticAccessCodesList } from "../../../utils/accessCodes";

export const unlockedCodesStyles = StyleSheet.create({
    accessCodeContainer: {
    // only on route list page
        width: "100%",
        maxWidth: 600,
        marginHorizontal: "auto",
        marginBottom: 10,
        paddingHorizontal: 10,
    },
    accessCodeBorderContainer: {
        height: 30,
        borderRadius: 8,
        marginBottom: -22,
        backgroundColor: colorNames.parksGreen
    },
    accessCodeContainerInner: {
        backgroundColor: "#FFF",
        borderRadius: 10,
        paddingTop: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    contentUnlockedText: {
        fontSize: 18,
        fontFamily: "bold",
        color: colorNames.parksGreen,
        marginBottom: 6,
    },
    routesText: {
        fontSize: 16,
        marginBottom: 6,
        marginHorizontal: 10,
        textAlign: "center",
    },
    enjoyText: {
        fontSize: 18,
        fontFamily: "bold",
        color: colorNames.parksGreen,
        marginBottom: 6,
    },
    accessCodeError: {
        borderWidth: 2,
        borderColor: colorNames.actionRed,
        borderRadius: 10,
        margin: 10,
        padding: 6,
    },
    accessCodeErrorText: {
        fontSize: 12
    }
});


const UnlockedAccessCodes = () => {

    const lang = useSelector(state => state.settings.lang);

    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);
    const routeData = useSelector(state => state.mapData.routeData);
    const urlInvalid = useSelector(state => state.accessCodes.urlInvalid);

    const getAllUnlockedRoutes = () => {
        let allUnlockedRoutes = [];
        staticAccessCodes.forEach(accessCode => {
            staticAccessCodesList[accessCode].routes.map(routeName => {
                const title = routeData.filter(route => route.routeSlug === routeName)[0]?.title;
                allUnlockedRoutes.push(title);
            });
        });

        // need to loop through route data and find the routes that have the offering
        purchasedAccessCodes.forEach(accessCode => {
            accessCode.offerings?.forEach(offering => {
                const routeTitles = routeData.filter(route => route.offerings?.indexOf(offering) > -1).map(route => route.title);
                allUnlockedRoutes.push(...routeTitles);
            });
        });

        return Array.from(new Set(allUnlockedRoutes)); // using Set removes duplicates
    }

    if (staticAccessCodes.length == 0 && purchasedAccessCodes.length == 0 && urlInvalid.length == 0) {
        return null;
    }

    const unlockedRoutes = getAllUnlockedRoutes();

    return <View style={unlockedCodesStyles.accessCodeContainer}>
        <View style={unlockedCodesStyles.accessCodeBorderContainer}></View>
        <View style={unlockedCodesStyles.accessCodeContainerInner}>

            <Text style={unlockedCodesStyles.contentUnlockedText}>{ getLangTerm("premium-content-unlocked", lang) }</Text>

            <Text style={unlockedCodesStyles.routesText}>{ unlockedRoutes.join(", ") }</Text>

            {unlockedRoutes.length > 0 && <Text style={unlockedCodesStyles.enjoyText}>{ getLangTerm("enjoy", lang) }</Text>}

            { urlInvalid.length > 0 && <View style={unlockedCodesStyles.accessCodeError}>
                <Text style={unlockedCodesStyles.accessCodeErrorText}>{ getLangTerm("access-code-error", lang) }</Text>
            </View> }
        </View>
    </View>
}

export default UnlockedAccessCodes