
import { types } from "../types";

const initialState = {
    loading: false,
    loaded: false,
    error: false
};

export const timestampReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TIMESTAMP_LOOKUP: {
            return { ...state, loading: true, loaded: false, error: false };
        }
        case types.TIMESTAMP_LOOKUP_SUCCESS: {

            return { ...state,
                loading: false,
                loaded: true,
                ...action
            }; 
        }
        case types.TIMESTAMP_LOOKUP_FAILURE: {
            return { ...state, loading: false, loaded: false, error: true };
        }
        default:
            return state;
    }
};