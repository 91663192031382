import React, { useRef } from "react";
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    Image,
    TouchableWithoutFeedback
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import {
    colors,
    colorNames,
    textStyles,
    fullscreenView
} from "styles/shared";
import { openURL } from "utils/sharedActions";

import { Close } from "../../SVGs";
import { getLangTerm } from "lang";
import { screenContainer } from "styles/shared";
import HowTo from "./HowTo";
import Tips from "./Tips";



const win = Dimensions.get('window');

const aboutStyles = StyleSheet.create({
    container: {
        ...fullscreenView,
        zIndex: 4,
        backgroundColor: "#ffffffff",
    },
    containerInner: {
        ...screenContainer,
        flex: 1,
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
        // marginHorizontal: 8,
        marginBottom: 8,
        borderRadius: 25,
        backgroundColor: "#ffffff",
    },
    closeButton: {
        margin: 13,
        alignSelf: "flex-start"
    },
    closeIcon: {
        color: colors.headerGrey
    },
    content: {
        paddingHorizontal: 20,
        paddingBottom: 30
    },
    chrisContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 12
    },
    chrisImage: {
        // height: "100%",
        // width: "100%",
        // maxHeight: 
        maxWidth: "100%",
    },
    chrisIntro: {
        fontSize: 16
    },
    socialIcons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    socialIcon: {
        marginHorizontal: 20,
        color: colorNames.parksGreen,
        cursor: "pointer"
    },
    webLink: {
        textAlign: "center",
        fontSize: 24,
        color: colorNames.actionOrange,
        fontFamily: "bold",
        cursor: "pointer"
    },
    buttonContainer: {
        marginTop: 20,
        paddingTop: 20,
        borderColor: colorNames.parksGreen,
        borderTopWidth: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        borderRadius: 15,
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
        paddingHorizontal: 20,
        paddingVertical: 12,
        alignSelf: "center",
        cursor: "pointer",

        fontSize: 20,
        fontFamily: "bold",
        textAlign: "center",
    },
    buttonActive: {
        backgroundColor: colorNames.parksGreen,
        color: "#FFFFFF"
    },
    howToStep: {
        fontSize: 22,
        color: colorNames.parksGreen,
        fontFamily: "bold",
        textAlign: "center",
        marginBottom: 10,
        marginTop: 30,
        marginLeft: 20
    },
    routeButton: {
        backgroundColor: colors["route-gastown"],
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 12,
        alignSelf: "center",
        display: "flex",
    },
    routeText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center",
        flex: 1
    },
    howToSubStep: {
        fontSize: 16,
        color: colors.subtitleColor,
        textAlign: "center",
        fontFamily: "bold",
    },
    howToImage: {
        alignSelf: "center"
    },
    screenshot: {
        alignSelf: "center",
        borderRadius: 15,
        borderWidth: 2,
        borderColor: colors.subtitleColor

    },
    exitRouteButton: {
        flex: 0,
        alignSelf: "center",
        width: 48,
    },
    exitRouteText: {
        color: colorNames.actionRed,
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center"
    },
    tipBody: {
        fontSize: 18,
        lineHeight: 24,
    }
});

const AboutPage = ({showAboutPage, toggleShowAboutPage}) => {

    const insets = useSafeAreaInsets();
    const scrollViewRef = useRef();
    const dispatch = useDispatch();

    const lang = useSelector(state => state.settings.lang);

    const [displayMode, setDisplayMode] = React.useState("how-to");

    let imageWidth = win.width - 60;
    if (imageWidth > 500) {
        imageWidth = 500;
    }
    let imageHeight = imageWidth * 0.7

    const openFacebookLink = () => {
        openURL({ url: "https://www.facebook.com/VanWalks", target: "facebook-about" }, dispatch);
    };

    const openInstagramLink = () => {
        openURL({ url: "https://www.instagram.com/VanWalks/", target: "instagram-about" }, dispatch);
    };

    const openWebLink = () => {
        openURL({ url: "https://vanwalks.ca/", target: "website-about"}, dispatch);
    };


    if (!showAboutPage) return null;

    return <View style={{
        ...aboutStyles.container,
        paddingTop: insets.top + 8
    }}>
        <View style={{
            ...aboutStyles.containerInner
        }} >
            <View style={{...aboutStyles.closeButton}}>
                <TouchableOpacity onPress={toggleShowAboutPage}>
                    <View>
                        <Close style={{...aboutStyles.closeIcon}} size={32} />
                    </View>
                </TouchableOpacity>
            </View>
            <ScrollView
                style={{flex: 1}}
                ref={scrollViewRef}
            >
                <View style={aboutStyles.content}>
                    <Text style={textStyles.header}>
                        { getLangTerm("welcome-header", lang) }
                    </Text>
                    <View style={aboutStyles.chrisContainer}>
                        <Image
                            source={require('../../../../assets/Chris.jpg')}
                            style={{
                                ...aboutStyles.chrisImage,
                                height: imageHeight,
                                width: imageWidth
                            }}
                            resizeMode="contain"
                        />
                    </View>
                    <Text style={aboutStyles.chrisIntro}>
                        { getLangTerm("chris-intro", lang) }
                    </Text>
                    <Text style={{
                        ...textStyles.header,
                        marginTop: 20
                    }}>
                        { getLangTerm("follow-us", lang) }
                    </Text>
                    <View style={aboutStyles.socialIcons}>
                        <TouchableWithoutFeedback onPress={openFacebookLink}>
                            <FontAwesome name={"facebook-official"} size={48} style={aboutStyles.socialIcon} />
                        </TouchableWithoutFeedback>
                            
                        <TouchableWithoutFeedback onPress={openInstagramLink}>
                            <FontAwesome name={"instagram"} size={48} style={aboutStyles.socialIcon} />
                        </TouchableWithoutFeedback>
                    </View>
                    <Text style={{
                        ...textStyles.header,
                        marginTop: 20
                    }}>
                        { getLangTerm("on-the-web", lang) }
                    </Text>
                    <TouchableWithoutFeedback onPress={openWebLink}>
                        <Text style={aboutStyles.webLink}>VanWalks.ca</Text>
                    </TouchableWithoutFeedback>
                    <View style={aboutStyles.buttonContainer}>
                        <TouchableWithoutFeedback onPress={() => setDisplayMode("how-to")}>
                            <Text style={{
                                ...aboutStyles.button,
                                ...(displayMode === "how-to" ? aboutStyles.buttonActive : {}),
                                marginRight: 20
                            }}>
                                { getLangTerm("how-to-use", lang) }
                            </Text>
                        </TouchableWithoutFeedback>
                        <TouchableWithoutFeedback onPress={() => setDisplayMode("tips")}>
                            <Text style={{
                                ...aboutStyles.button,
                                ...(displayMode === "tips" ? aboutStyles.buttonActive : {})
                            }}>
                                { getLangTerm("tips-for-success", lang) }
                            </Text>
                        </TouchableWithoutFeedback>
                    </View>
                    { displayMode === "how-to" && <HowTo aboutStyles={aboutStyles} lang={lang} imageWidth={imageWidth} /> }
                    { displayMode === "tips" && <Tips aboutStyles={aboutStyles} lang={lang} imageWidth={imageWidth} /> }
                </View>
            </ScrollView>
        </View>
    </View>;

};

export default AboutPage;