import React, { useEffect } from "react";
import { View, Text, StyleSheet, Image, TouchableWithoutFeedback, ActivityIndicator, Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesome5 } from '@expo/vector-icons';

import { types } from "redux/types";
import { colorNames, screenContainer, getRouteColor, fullscreenView } from "styles/shared";
import { getLangTerm } from "lang";
import { getRouteButtonTextColor } from "utils/purchases";
import { getStopImageorIcon } from "utils/stops";
import { isRetina } from "utils/isRetina";
import { Diamond } from "../SVGs";


const thumbSize = isRetina ? "thumblarge" : "thumbmedium";

const offlineStyles = StyleSheet.create({
    screenContainer,
    container: {
        ...fullscreenView,
        zIndex: 0,
        backgroundColor: colorNames.landLight
    },
    scrollView: {
        flex: 1,
        backgroundColor: colorNames.landLight,
        marginTop: 50,
        paddingTop: 20
    },
    title: {
        fontSize: 28,
        fontFamily: "bold",
        color: colorNames.parksGreen,
        textAlign: 'center',
        marginBottom: 20
    },
    subtitle: {
        maxWidth: 300,
        fontSize: 18,
        marginHorizontal: 10,
        color: colorNames.parksGreen,
        textAlign: 'center',
        marginBottom: 20,
        alignSelf: "center"
    },
    enableButton: {
        flexDirection: "row",
        alignSelf: "center",
        backgroundColor: colorNames.actionOrange,
        borderRadius: 15,
        marginHorizontal: 10,
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginBottom: 10
    },
    enableButtonText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center",
        cursor: "pointer"
    },
    assetCount: {
        fontSize: 18,
        fontFamily: "bold",
        alignSelf: "center",
    },
    warning: {
        marginTop: 32,
        maxWidth: 400,
        marginHorizontal: 10,
        alignSelf: "center"
    },
    offlineReady: {
        fontSize: 18,
        color: colorNames.parksGreen,
        maxWidth: 600,
        alignSelf: "center",
        marginHorizontal: 10,
        paddingBottom: 20
    },
    thumb: {
        width: 50,
        height: 50,
        borderRadius: 5,
        margin: 10,
        marginRight: 14,
    },
    iconThumb: {
        width: 50,
        height: 50,
        borderRadius: 5,
        margin: 10,
        marginRight: 14,
        justifyContent: "center",
        alignItems: "center"
    },
    icon: {
        color: "#fff"
    },
    stopRow: {
        flexDirection: "column",
        marginHorizontal: 5,
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
    },
    stopHeader: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    stopHeaderText: {
        fontFamily: "bold",
        fontSize: 24,
    },
    mapContainer: {
        marginHorizontal: 10,
        marginBottom: 20,
    },
    mapImage: {
        maxWidth: "100%",
    },
    detailsButton: {
        flexDirection: "row",
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginHorizontal: "auto",
        marginBottom: 10
    },
    detailsButtonText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center",
        cursor: "pointer"
    },
});

const OfflineMode = (() => {

    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedRouteStops = useSelector(state => state.mapData.selectedRouteStops);
    const routeStops = useSelector(state => state.mapData.routeStops);
    const offlineMode = useSelector(state => state.mapData.offlineMode);
    const offlineStatuses = useSelector(state => state.offline.offlineStatuses);
    const assetCount = useSelector(state => state.offline.assetCount);
    const finishedAssets = useSelector(state => state.offline.finishedAssets);
    const lang = useSelector(state => state.settings.lang);
    
    const dispatch = useDispatch();

    useEffect(() => {
        const checkOfflineStatus = () => {
            if (routeStops && selectedRouteData && offlineMode) {
                const routeSlug = selectedRouteData.slug;
                const routeToken = `${routeSlug}-${selectedRouteData.timestamp}`;
                if (!offlineStatuses[routeToken]) {
                    dispatch({
                        type: types.OFFLINE_MODE_LOOKUP,
                        routeSlug,
                        routeToken,
                        thumbSize
                    });
                }
            }
        }
        checkOfflineStatus();
    }, [routeStops, selectedRouteData, offlineMode]);

    const enableOfflineData = () => {

        const routeSlug = selectedRouteData.slug;
        const routeToken = `${routeSlug}-${selectedRouteData.timestamp}`;

        dispatch({
            type: types.OFFLINE_MODE_ENABLE,
            routeSlug,
            routeToken,
            thumbSize
        });
    }

    const abortOfflineDownloading = () => {

        const routeSlug = selectedRouteData.slug;
        const routeToken = `${routeSlug}-${selectedRouteData.timestamp}`;

        dispatch({
            type: types.OFFLINE_MODE_ABORT,
            routeSlug,
            routeToken
        })
    }

    const stopClick = (stop) => {
        dispatch({
            type: types.SHOW_DETAILS_PAGE,
            detailsPageData: stop,
            userActionData: { slug: stop.slug },
            target: stop.slug,
            offlineMode: true
        });
    }

    const { slug, title, isPremium } = selectedRouteData;
    // console.log(selectedRouteData)

    

    const getPathString = (stop) => {

        let pathPoints = selectedRouteData.pathArray;

        if (slug == "stanley-park-seawall") {

            // can't use the full path for the seawall, it's too long
            // so we'll just use a portion of it
            // we'll use the stop's pathIndex to determine the start and end points
            // and then we'll get a portion of the path array between those points
            // and use that for the path string
            // we'll use a fixed length of 175 points on each side of the stop
            // so the total length will be 350 points
            // this will be about 1.5km on each side of the stop
            // this should be enough to cover the entire seawall
            // and it should be enough to cover the entire path for any stop
            // since the longest path is about 1km
            // and the shortest path is about 100m
            // so 1.5km should be enough to cover all of them
            // and it should be short enough to not cause any performance issues
            // on the device
            // if we need to adjust this in the future, we can
            // but this should be a good starting point
            // and it should be good enough for the foreseeable future
            // since the seawall is the only route that's long enough to need this
            // and it's the only route that's likely to be used in offline mode
            // since it's the only route that's long enough to need it
            // and it's the only route that's popular enough to be used in offline mode

            const pathLength = 175;
            let startIndex = 0;
            let endIndex = selectedRouteData.pathArray.length;

            const pathIndex = stop.pathIndex || 0;

            startIndex = Math.max(pathIndex - pathLength, 0);
            endIndex = Math.min(pathIndex + pathLength, selectedRouteData.pathArray.length);

            pathPoints = selectedRouteData.pathArray.slice(startIndex, endIndex);
        }

        // get the path points before and after the central point
        const pathPointsStr = pathPoints.map(pathPoint => `${pathPoint.latitude},${pathPoint.longitude}`).join("|");

        return `&path=color:${getRouteColor(slug).replace("#","0x")}ff%7C${pathPointsStr}`;
    }

    const getMarkersString = (stop, index) => {
        let markerString = "";
        let otherMarkers = [];
 
        markerString = `&markers=color:${index == -1 ? colorNames.actionGreen.replace("#","0x") : `${getRouteColor(slug).replace("#","0x")}|size:mid` }|${selectedRouteData.latlng.latitude},${selectedRouteData.latlng.longitude}`;

        selectedRouteStops.forEach((routeStop, i) => {
            if (i == index) {
                otherMarkers.push(`&markers=color:${colorNames.actionGreen.replace("#","0x")}|${routeStop.latlng.latitude},${routeStop.latlng.longitude}`);
            } else if (Math.abs(i - index) <= 20) {
                otherMarkers.push(`&markers=size:mid|color:${getRouteColor(slug).replace("#","0x")}|${routeStop.latlng.latitude},${routeStop.latlng.longitude}`);
            }
        });

        return `${markerString}${otherMarkers.join("")}`;
    };

    const staticMap = (stop, index) => {
        // console.log(stop)

        let zoom = "16";
        if (slug == "gastown") {
            zoom = "17";
        }
        if (slug == "chinatown") {
            zoom = "17";
        }
        if (slug == "coal-harbour") {
            zoom = "17";
        }
        if (slug == "bc-entertainment-hof") {
            zoom = "19";
        }

        return <View style={offlineStyles.mapContainer}>
            <img style={offlineStyles.mapImage} src={`https://maps.googleapis.com/maps/api/staticmap?center=${stop.latlng.latitude},${stop.latlng.longitude}&zoom=${zoom}&size=800x500&map_id=248172d6617e90d2${getMarkersString(stop, index)}${getPathString(stop, index)}&key=AIzaSyD_8viZi14GplCN-MQvvEgtegVUhmXGgps`}/>
        </View>
        //&signature=YOUR_SIGNATURE
    }

    const buttonColor = getRouteButtonTextColor(slug)

    return <View style={offlineStyles.container}>
        <Animated.ScrollView
            style={offlineStyles.scrollView}
            scrollEventThrottle={1}
        >
            <View style={offlineStyles.screenContainer}>
                <View>
                    {   (   !offlineStatuses[slug]
                            || offlineStatuses[slug] === "none"
                            || offlineStatuses[slug] === "removed"
                            || offlineStatuses[slug] === "aborted"
                            || offlineStatuses[slug] === "downloading"
                            || offlineStatuses[slug] === "verifying") && <>
                        <Image
                            source={require('../../../assets/VanWalksLogo.png')}
                            style={offlineStyles.logo}
                            resizeMode="contain"
                        />
                        <View>
                            <Text style={offlineStyles.title}>{ getLangTerm("vanwalks-offline-mode", lang) }</Text>
                            <Text style={offlineStyles.subtitle}>{ getLangTerm("offline-desc", lang) }</Text>
                            <Text style={offlineStyles.subtitle}>{ getLangTerm("data-saving-desc", lang) }</Text>
                        </View>
                        <TouchableWithoutFeedback onPress={() => enableOfflineData()}>
                            <View style={{
                                ...offlineStyles.enableButton,
                                backgroundColor: getRouteColor(slug)
                            }}>
                                <Text style={{
                                    ...offlineStyles.enableButtonText,
                                    color: buttonColor
                                }}>
                                    { getLangTerm("enable-offline-mode", lang).replace("||title||", title) }
                                </Text>
                                {
                                    (offlineStatuses[slug] === "downloading" || offlineStatuses[slug] === "verifying") && <ActivityIndicator style={{marginLeft: 10 }} size="small" color={buttonColor} />
                                }
                            </View>
                        </TouchableWithoutFeedback>
                        {
                            (offlineStatuses[slug] === "downloading"
                            || offlineStatuses[slug] === "verifying")
                            && <Text style={offlineStyles.assetCount}>
                                { `${getLangTerm(offlineStatuses[slug] === "downloading" ? "assets": "verifying", lang)}: ${finishedAssets || 0} / ${assetCount || 0}` }
                            </Text>
                        }
                        { (offlineStatuses[slug] === "downloading" || offlineStatuses[slug] === "verifying") && <TouchableWithoutFeedback onPress={() => abortOfflineDownloading()}>
                            <View style={{
                                ...offlineStyles.enableButton,
                                marginTop: 10,
                                backgroundColor: getRouteColor(slug)
                            }}>
                                <Text style={{
                                    ...offlineStyles.enableButtonText,
                                    color: buttonColor
                                }}>
                                    { getLangTerm("abort", lang).replace("||title||", title) }
                                </Text>
                            </View>
                        </TouchableWithoutFeedback> }
                        <Text style={offlineStyles.warning}>{ getLangTerm("offline-warning", lang) }</Text>
                    </>
                    }
                    {   (offlineStatuses[slug] === "done") && <>
                        <Text style={offlineStyles.offlineReady}>{ getLangTerm("offline-mode-ready", lang) }</Text>
                        <View style={{
                            ...offlineStyles.stopRow,
                            borderColor: getRouteColor(slug)
                        }}>
                            <View style={offlineStyles.stopHeader}>
                                { isPremium ? <Diamond
                                    style={{
                                        ...offlineStyles.iconThumb,
                                        color: getRouteColor(slug)
                                    }}
                                /> : <FontAwesome5
                                    name="font-awesome-flag"
                                    size={offlineStyles.thumb.height}
                                    style={{
                                        ...offlineStyles.thumb,
                                        color: getRouteColor(slug)
                                    }}
                                />
                                }
                                <Text style={offlineStyles.stopHeaderText} >{ title } - {getLangTerm("walk-here-to-start", lang)}</Text>
                            </View>
                            { staticMap(selectedRouteData, -1) }
                            <TouchableWithoutFeedback onPress={() => stopClick(selectedRouteData)}>
                                <View style={{
                                    ...offlineStyles.detailsButton,
                                    backgroundColor: getRouteColor(slug)
                                }}>
                                    <Text style={{
                                        ...offlineStyles.detailsButtonText,
                                        color: buttonColor
                                    }}>
                                        { getLangTerm("press-for-details", lang) }
                                    </Text>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                        {
                            selectedRouteStops && selectedRouteStops.map((stop, index) => {
                                const stopImage = getStopImageorIcon({ stop, styles: offlineStyles, thumbSize, timestamp: selectedRouteData.timestamp });

                                return <View key={index} style={{
                                    ...offlineStyles.stopRow,
                                    borderColor: getRouteColor(slug)
                                }}>
                                    <View style={offlineStyles.stopHeader}>

                                        {stopImage}
                                        <Text style={offlineStyles.stopHeaderText} >{ stop.title }</Text>
                                    </View>
                                    { staticMap(stop, index) }
                                    <TouchableWithoutFeedback onPress={() => stopClick(stop)}>
                                        <View style={{
                                            ...offlineStyles.detailsButton,
                                            backgroundColor: getRouteColor(slug)
                                        }}>
                                            <Text style={{
                                                ...offlineStyles.detailsButtonText,
                                                color: buttonColor
                                            }}>
                                                { getLangTerm("press-for-details", lang) }
                                            </Text>
                                        </View>
                                    </TouchableWithoutFeedback>
                                </View>
                            })
                        }
                    </>
                    }
                </View>
            </View>
        </Animated.ScrollView>
    </View>
});
export default OfflineMode;