import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TouchableWithoutFeedback, View } from 'react-native';


import { getLangTerm } from "lang";
import { types } from "redux/types";
import { settingsStyles } from "./styles/settingsStyles";
import { isRetina } from "utils/isRetina";

const thumbSize = isRetina ? "thumblarge" : "thumbmedium";

const OfflineSettings = () => {

    const dispatch = useDispatch();

    const lang = useSelector(state => state.settings.lang);
    const routeData = useSelector(state => state.mapData.routeData);
    const offlineStatuses = useSelector(state => state.offline.offlineStatuses);

    useEffect(() => {
        const checkOfflineStatus = () => {
            routeData && routeData?.forEach((route) => {
                const routeSlug = route.routeID;
                const routeToken = `${routeSlug}-${route.timestamp}`;
                if (!offlineStatuses[routeToken]) {
                    dispatch({
                        type: types.OFFLINE_MODE_LOOKUP,
                        routeSlug,
                        routeToken,
                        thumbSize
                    });
                }
            });
        }
        checkOfflineStatus();
    }, [routeData]);

    const resetOfflineStatuses = () => {
        dispatch({
            type: types.OFFLINE_MODE_RESET,
            routeData
        });
    };

    const renderDownloadStatus = () => {
        let noDownloads = true;
        const statuses = [];
        routeData && routeData?.map((route, i) => {
            const routeOfflineStatus = offlineStatuses[route.routeID];
            if (routeOfflineStatus !== undefined && routeOfflineStatus !== "none" && routeOfflineStatus !== "removed") {
                noDownloads = false;
                statuses.push(<View key={i}>
                    <Text style={settingsStyles.offlineText}>{route.title} - { getLangTerm(`offline-status-${routeOfflineStatus}`, lang) }</Text>
                </View>);
            }
        });
        if (noDownloads) {
            return <Text style={settingsStyles.offlineText}>{ getLangTerm("no-offline-downloads", lang) }</Text>
        } else {
            return <View>
                {statuses}
                <TouchableWithoutFeedback onPress={() => resetOfflineStatuses()}>
                    <View style={{
                        ...settingsStyles.resetOfflineButton
                    }}>
                        <Text style={{
                            ...settingsStyles.resetOfflineButtonText
                        }}>
                            { getLangTerm("reset-offline-status", lang) }
                        </Text>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        }

    }

    return <View style={settingsStyles.offlineContainer}>
        <Text style={settingsStyles.offlineHeader}>{ getLangTerm("offline-settings", lang) }</Text>
        { renderDownloadStatus() }
    </View>
};

export default OfflineSettings;