import React from "react";
import {
    View,
    TouchableOpacity,
} from "react-native";
import { useDispatch } from "react-redux";
import { KeyboardSpacer } from 'react-native-keyboard-spacer-fixed';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { types } from "redux/types";
import { Close } from "../../SVGs";
import { settingsStyles } from "./styles/settingsStyles";
import SettingsScrollView from "./SettingsScrollView";
import WelcomeHeader from "./WelcomeHeader";
import LanguageSelector from "./LanguageSelector";
import LocationToggle from "./LocationToggle";
import ContactUs from "./ContactUs";
import MailingListForm from "./MailingListForm";
// import Purchases  from "./Purchases";
import AccessCodeInput from "./AccessCodeInput";
import AccessCodeDetails from "./AccessCodeDetails";
import OfflineSettings from "./OfflineSettings";
import DevMenu from "./DevMenu";

  
const SettingsPage = ({showSettingsPage, toggleShowSettingsPage}) => {

    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();

    if (!showSettingsPage) return null;

    return <View style={{
        ...settingsStyles.container,
        paddingTop: insets.top + 8
    }}>
        <View style={{
            ...settingsStyles.containerInner,
        }} >
            <View style={{...settingsStyles.closeButton}}>
                <TouchableOpacity onPress={() => {
                    dispatch({
                        type: types.ACCESS_CODE_RESET
                    });
                    toggleShowSettingsPage()
                }}>
                    <View>
                        <Close style={{...settingsStyles.closeIcon}} size={32} />
                    </View>
                </TouchableOpacity>
            </View>
            <SettingsScrollView
                style={{flex: 1}}
            >
                <View style={settingsStyles.content}>
                    <WelcomeHeader />
                    <LanguageSelector />
                    <LocationToggle />
                    <AccessCodeInput />
                    <AccessCodeDetails />
                    <OfflineSettings />
                    <MailingListForm />
                    <ContactUs />
                    {/* <Purchases /> */}
                    <DevMenu />
                    <KeyboardSpacer />
                </View>
            </SettingsScrollView>
        </View>
    </View>
};
export default SettingsPage;