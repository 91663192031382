import config from "./apiConfig";
import { types } from "../types";
import { fancylog } from "utils/fancylog";
import { UserActionDuration } from "utils/userActions";

const logLocal = false;
fancylog.log("mailingListAPI logLocal", logLocal);

export const mailingListAPI = () => next => action => {

    const submitMailingList = async (data) => {

        const uad = new UserActionDuration();
        fancylog.log("in submitMailingList", logLocal);
        const apiClient = config.getInstance();
        const post = () => apiClient.post("/email/signup", data);

        const res = await post().catch((e) => {
            fancylog.log("Error doing mailing list signup", true);
            fancylog.log(e, true); 
        });
        
        if (!res || !res.data) {
            return next({
                type: types.MAILING_LIST_SIGNUP_FAILURE
            });
        }

        const duration = uad.duration;

        return next({
            type: types.MAILING_LIST_SIGNUP_SUCCESS,
            duration,
            userActionData: { data }
        });
    }

    next(action);
    switch (action.type) {
        case types.MAILING_LIST_SIGNUP: {
            submitMailingList(action.data);
            break;
        }
        
        default:
            break;
    }
};