import React from "react";
import {
    Text,
    TouchableWithoutFeedback
} from "react-native";
import { useDispatch } from "react-redux";

import {
    colors
} from "styles/shared";
import { getLangTerm } from "lang";
import { openURL } from "utils/sharedActions";

const Tips = ({aboutStyles, lang}) => {

    const dispatch = useDispatch();
    const openWifiLink = () => {
        openURL({ url: "https://vancouver.ca/your-government/vanwifi.aspx", target: "vancouver-wifi" }, dispatch);
    };

    return <>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("location", lang) }
        </Text>
        <Text style={aboutStyles.tipBody}>
            { getLangTerm("location-body", lang) }
        </Text>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("battery", lang) }
        </Text>
        <Text style={aboutStyles.tipBody}>
            { getLangTerm("battery-body", lang) }
        </Text>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("data", lang) }
        </Text>
        <Text style={{...aboutStyles.tipBody, marginBottom: 20}}>
            { getLangTerm("data-body-1", lang) }
        </Text>
        <Text style={{...aboutStyles.tipBody, marginBottom: 20}}>
            { getLangTerm("data-body-2", lang) }
        </Text>
        <Text style={aboutStyles.tipBody}>
            { getLangTerm("data-body-3", lang) }
        </Text>
        <TouchableWithoutFeedback onPress={openWifiLink}>
            <Text style={{fontSize: 24, fontFamily: "bold", textAlign: "center", cursor: "pointer", color: colors.userActions }}>
                { getLangTerm("city-of-vancouver-wifi-info", lang) }
            </Text>
        </TouchableWithoutFeedback>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("traffic-tip", lang) }
        </Text>
        <Text style={{...aboutStyles.tipBody, marginBottom: 20}}>
            { getLangTerm("traffic-body-1", lang) }
        </Text>
        <Text style={aboutStyles.tipBody}>
            { getLangTerm("traffic-body-2", lang) }
        </Text>
    </>
}
export default Tips;