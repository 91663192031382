import AsyncStorage from '@react-native-async-storage/async-storage';
// import { Audio } from 'expo-av';

import { types } from "../types";
import store from "../root";
import { isRetina } from "utils/isRetina";

const getImageSize = (image) => {
    return isRetina && image.applarge ? "applarge" : "appsmall";
};

export const offlineAPI = () => next => action => {

    let fetchPromises = [];
    let finishedFetches = 0;
    let allQueued = false;


    const updateCount = async ({action, isVerify}) => {
        const { routeToken, routeSlug } = action;
        finishedFetches++;

        let returnString = isVerify ? "verifying" : "downloading";
        if (finishedFetches === fetchPromises.length && allQueued) {
            if (!isVerify) {
                downloadStopAssets({action, isVerify: true});
            } else {
                returnString = "done";
                const offlineMode = await AsyncStorage.getItem(`@offline-loaded-${routeToken}`);
                if (offlineMode !== "aborted") {
                    AsyncStorage.setItem(`@offline-loaded-${routeToken}`, returnString);
                }
            }
        }

        next({
            type: types.OFFLINE_MODE_DOWNLOAD_SUCCESS,
            returnString,
            assetCount: fetchPromises.length,
            finishedAssets: finishedFetches,
            routeSlug
        });
    };

    const downloadStopAssets = async ({action, isVerify = false}) => {
        const { routeSlug, thumbSize } = action;

        const storeState = store.getState();
        const selectedRouteData = storeState.mapData.selectedRouteData;
        const routeStops = storeState.mapData.routeStops;

        fetchPromises = [];
        finishedFetches = 0;
        allQueued = false;

        const audioCache = await caches.open("audio-cache");

        // download the route start audio first
        // const audioPromise = new Promise((resolve) => {
        //     const audioUrl = `${selectedRouteData.audioURL}?t=${selectedRouteData.timestamp}`;

        //     const soundObject = new Audio.Sound();
        //     soundObject.loadAsync({ uri: audioUrl }).then((res) => {
        //         console.log("got sound object", audioUrl);
        //         console.log("got sound object", res.isLoaded);
        //         // const audioRequest = new Request(audioUrl, { mode: 'no-cors' });
        //         audioCache.add(new Request(audioUrl, { mode: 'no-cors' }));
        //         updateCount({action, isVerify});
        //         resolve();
        //     });
        // });
        // fetchPromises.push(audioPromise);
        const audioUrl = `${selectedRouteData.audioURL}?t=${selectedRouteData.timestamp}`;
        const audioPromise = new Promise((resolve) => {
            // const soundObject = new Audio.Sound();
            // soundObject.loadAsync({ uri: audioUrl }).then((res) => {
            //     console.log("got sound object", audioUrl);
            //     console.log("got sound object", res.isLoaded);
            // });

            // const audioRequest = new Request(audioUrl, { mode: 'no-cors' });
            fetch(audioUrl, { headers: { "Origin": window.location.origin } }).then((response) => {
                console.log("got fetch response", response);
                if (!response.type == "opaque" && !response.ok) {
                    throw new TypeError("bad response status");
                }
                audioCache.put(audioUrl, response).then(() => {
                    console.log(`did cache ${audioUrl}`);
                    updateCount({action, isVerify});
                    resolve();
                }); 
            }).catch(err => {
                console.log("got fetch error", err);
            });
        });
        fetchPromises.push(audioPromise);


        routeStops[routeSlug].forEach(async (stop) => {
            // const audioPromise = fetch(`${stop.audioURL}?t=${selectedRouteData.timestamp}`,{ mode: 'no-cors'}).then((response) => {
            //     updateCount();
            //     return response.blob();
            // });
            // fetchPromises.push(audioPromise);
            const audioUrl = `${stop.audioURL}?t=${selectedRouteData.timestamp}`;
            const audioPromise = new Promise((resolve) => {
                // const soundObject = new Audio.Sound();
                // soundObject.loadAsync({ uri: audioUrl }).then((res) => {
                //     console.log("got sound object", audioUrl);
                //     console.log("got sound object", res.isLoaded);
                // });
                // const audioRequest = new Request(audioUrl, { mode: 'no-cors' });
                fetch(audioUrl, { headers: { "Origin": window.location.origin } }).then((response) => {
                    console.log("got fetch response", response);
                    if (!response.type == "opaque" && !response.ok) {
                        throw new TypeError("bad response status");
                    }
                    audioCache.put(audioUrl, response).then(() => {
                        console.log(`did cache ${audioUrl}`);
                        updateCount({action, isVerify});
                        resolve();
                    }); 
                }).catch(err => {
                    console.log("got fetch error", err);
                });
            });
            fetchPromises.push(audioPromise);

            // const audioPromise = new Promise((resolve) => {
            //     const audioUrl = `${stop.audioURL}?t=${selectedRouteData.timestamp}`;

            //     const soundObject = new Audio.Sound();
            //     // console.log("got sound object", soundObject);
            //     soundObject.loadAsync({ uri: audioUrl }).then((res) => {
            //         console.log("got sound object", audioUrl);
            //         console.log("got sound object", res.isLoaded);
            //         // const audioRequest = new Request(audioUrl, { mode: 'no-cors' });
            //         audioCache.add(new Request(audioUrl, { mode: 'no-cors' }));
            //         updateCount({action, isVerify});
            //         resolve();
            //     });
            // });
            // fetchPromises.push(audioPromise);

            
            stop.images && stop.images.forEach((image) => {
                const thumbnailPromise = fetch(`${image[thumbSize]}?t=${selectedRouteData.timestamp}`,{ mode: 'no-cors'}).then((response) => {
                    updateCount({action, isVerify});
                    return response.blob();
                });
                fetchPromises.push(thumbnailPromise);

                const imageSize = getImageSize(image);
                
                const imagePromise = fetch(`${image[imageSize]}?t=${selectedRouteData.timestamp}`,{ mode: 'no-cors'}).then((response) => {
                    updateCount({action, isVerify});
                    return response.blob();
                });
                fetchPromises.push(imagePromise);
            });

            next({
                type: types.OFFLINE_MODE_DOWNLOAD_SUCCESS,
                returnString: isVerify ? "verifying" : "downloading",
                assetCount: fetchPromises.length,
                finishedAssets: finishedFetches,
                routeSlug
            });
        });
        allQueued = true;
    };

    const checkRouteOfflineMode = async (action) => {
        const { routeToken, routeSlug } = action;

        let returnString = "none";
        const offlineMode = await AsyncStorage.getItem(`@offline-loaded-${routeToken}`);

        if (offlineMode) {
            returnString = offlineMode;

            // start again in case of page reload. Any assests that have been prevously downloaded will already be cached
            if (offlineMode === "downloading") {
                downloadStopAssets({action});
            }
        }

        return next({
            type: types.OFFLINE_MODE_LOOKUP_SUCCESS,
            returnString,
            routeSlug
        });
    };
    

    const startOfflineDownloads = async (action) => {
        const { routeToken, routeSlug } = action;

        await AsyncStorage.setItem(`@offline-loaded-${routeToken}`, "downloading");

        next({
            type: types.OFFLINE_MODE_LOOKUP_SUCCESS,
            returnString: "downloading",
            routeSlug
        });
        downloadStopAssets({action});

    };

    const abortOfflineDownload = async (action) => {
        const { routeToken, routeSlug } = action;

        await AsyncStorage.setItem(`@offline-loaded-${routeToken}`, "aborted");

        next({
            type: types.OFFLINE_MODE_ABORTED,
            returnString: "aborted",
            routeSlug
        });

    };

    const resetOfflineDownload = async (action) => {
        const { routeData } = action;
        routeData.forEach(async (route) => {
            const routeToken = `${route.routeID}-${route.timestamp}`
            await AsyncStorage.setItem(`@offline-loaded-${routeToken}`, "removed");
        });
    };

    next(action);
    switch (action.type) {
        case types.OFFLINE_MODE_LOOKUP: {
            checkRouteOfflineMode(action);
            break;
        }
        case types.OFFLINE_MODE_ENABLE: {
            startOfflineDownloads(action);
            break;
        }
        case types.OFFLINE_MODE_ABORT: {
            abortOfflineDownload(action);
            break;
        }
        case types.OFFLINE_MODE_RESET: {
            resetOfflineDownload(action);
            break;

        }
        default:
            break;
    }
};