import React, { useContext } from 'react';
import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { FontAwesome5 } from '@expo/vector-icons';
import { Diamond } from "../SVGs";

import { getRouteMarkerStyles } from "styles/markers";
import { MarkerContext } from "./MarkerContext";
import { BaseMarker } from './BaseMarker';
import { MarkerText } from './MarkerText';
import { getRouteColor } from '../../styles/shared';
import { isWeb } from 'utils/platform';

const RouteMarker = ({ route, mapObjects }) => {

    const context = useContext(MarkerContext)

    const {
        markerOnPress,
        isWalkingRoute,
        selectedRouteData,
        selectedStopData,
        tracksViewChanges,
        viewBox,
        getMarkerX
    } = context;

    const { slug, routeID, latlng, zIndex = 1, displayDir, title, isPremium } = route;
    const color = getRouteColor(route.slug);

    let markerSize = "small";
    // make large when the route is selected and this marker is in the route
    if (isWalkingRoute && routeID == selectedRouteData?.slug) {
        // this marker is part of the selectedRoute, make the size bigger
        // a large marker will also have the styles for the distance string for now
        markerSize = "large";
    }

    let isActive = false;
    // if (isWalkingRoute && selectedRouteData?.slug != route.slug ) {
    //     status = "inactive";
    // }
    if (isWalkingRoute && !selectedStopData && selectedRouteData?.slug == route.slug) {
        isActive = true;
    }

    const markerX = getMarkerX(displayDir);

    let routeMarkerStyles = getRouteMarkerStyles({markerSize, isActive, displayDir});

    const icon = isPremium ? <Diamond
        style={{
            ...routeMarkerStyles.premiumIcon,
            zIndex: (zIndex * 10) + 6
        }}
    /> : <FontAwesome5
        name="font-awesome-flag"
        size={routeMarkerStyles.iconSize.height}
        style={{
            ...routeMarkerStyles.icon,
            zIndex: (zIndex * 10) + 6
        }}
    />

    return <>
        <BaseMarker
            key={`${slug}-marker-text-${isActive ? "active" : "inactive"}`}
            mapObjects={mapObjects}
            coordinate={latlng}
            anchor={{x: markerX, y: 1}}
            style={{
                zIndex: (zIndex * 10) + 4 
            }}
            onPress={() => markerOnPress(route)}
            tracksViewChanges={tracksViewChanges}
        >
            <MarkerText
                onPress={() => markerOnPress(route)}
                title={title}
                isActive={isActive}
                routeMarkerStyles={routeMarkerStyles}
            />
        </BaseMarker>
        <BaseMarker
            key={`${slug}-marker-${isActive ? "active" : "inactive"}`}
            mapObjects={mapObjects}
            coordinate={latlng}
            anchor={{x: 0.5, y: 1}}
            style={{
                ...routeMarkerStyles.marker,
                zIndex: (zIndex * 10) + 5 
            }}
            onPress={() => markerOnPress(route)}
            tracksViewChanges={tracksViewChanges}
        >
            <View
                style={{
                    ...(isWeb ? routeMarkerStyles.markerContainerWeb : routeMarkerStyles.markerContainer)
                }}>
                <Svg
                    style={{
                        ...routeMarkerStyles.markerBG
                    }}
                    viewBox={viewBox}
                >
                    <Path d="M11.4577 47.8808C14.2545 50.8535 17.0432 53.2707 19.13 54.9439L24 58L28.87 54.9439C30.9568 53.2707 33.7455 50.8535 36.5423 47.8808C42.0378 42.0397 48 33.5964 48 24.1667C48 17.7573 45.4714 11.6104 40.9706 7.07825C36.4697 2.54612 30.3652 0 24 0C17.6348 0 11.5303 2.54612 7.02944 7.07825C2.52856 11.6104 0 17.7573 0 24.1667C0 33.5964 5.96223 42.0397 11.4577 47.8808Z"
                        fill={color}
                        // fill={isActive ? colorNames.actionGreen : color}
                        stroke="none"
                    />
                </Svg>
                { icon }
            </View>
        </BaseMarker>
    </>
}
export default React.memo(RouteMarker);