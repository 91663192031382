import keyMirror from "keymirror";
export const types = keyMirror({

    // APP OPENED
    "APP_OPENED": null,
    "APP_CLOSED": null,

    // TIMESTAMP
    "TIMESTAMP_LOOKUP": null,
    "TIMESTAMP_LOOKUP_SUCCESS": null,
    "TIMESTAMP_LOOKUP_FAILURE": null,

    // ROUTES
    "ROUTE_DATA_LOOKUP": null,
    "ROUTE_DATA_LOOKUP_SUCCESS": null,
    "ROUTE_DATA_LOOKUP_FAILURE": null,
    "SINGLE_ROUTE_LOOKUP": null,
    "SINGLE_ROUTE_LOOKUP_SUCCESS": null,
    "SINGLE_ROUTE_LOOKUP_FAILURE": null,

    // PLACES
    "PLACES_LOOKUP": null,
    "PLACES_LOOKUP_SUCCESS": null,
    "PLACES_LOOKUP_FAILURE": null,

    // IMAGES
    "IMAGES_FILE_LOAD": null,
    "IMAGES_FILE_LOAD_SUCCESS": null,
    "IMAGES_FILE_LOAD_FAILURE": null,

    // AUDIO
    "AUDIO_FILE_LOAD": null,
    "AUDIO_FILE_LOAD_SUCCESS": null,
    "AUDIO_FILE_LOAD_FAILURE": null,
    "PLAY_AUDIO": null,
    "STOP_AUDIO": null,

    // DOWNLOAD
    "QUEUE_FILE_DOWNLOAD": null,
    "FILE_DOWNLOAD_QUEUED": null,
    "FILE_DOWNLOADED": null,

    // USER
    "USER_LOCATION_CHANGE": null,
    "USER_LOCATION_LOOKUP_FAIL": null,

    "USER_SET_LOCATION_PREFERENCE": null,
    "USER_CHECK_LOCATION_PREFERENCE": null,
    "USER_CHECKED_LOCATION_PREFERENCE": null,

    // MAP
    "SET_MAP_READY": null,
    "SHOW_DETAILS_PAGE": null,
    "SHOW_FOOTER_LIST": null,
    "SET_WALKING_ROUTE_STOP": null,
    "SET_MAP_TO_POINT": null,
    "EXIT_WALKING_ROUTE": null,
    "CENTER_MAP_ON_USER": null,

    // LANG
    "LANG_LOOKUP": null,
    "LANG_LOADED": null,
    "LANG_CHANGED": null,

    // USER SIGNUP
    "MAILING_LIST_SIGNUP": null,
    "MAILING_LIST_SIGNUP_SUCCESS": null,
    "MAILING_LIST_SIGNUP_FAILURE": null,

    // OFFERINGS
    "LOAD_OFFERINGS": null,
    "LOAD_OFFERINGS_SUCCESS": null,
    "LOAD_OFFERINGS_FAILURE": null,

    // PURCHASES
    "LOAD_PURCHASES": null,
    "LOAD_PURCHASES_SUCCESS": null,
    "LOAD_PURCHASES_FAILURE": null,

    "PURCHASE_ROUTE": null,
    "PURCHASE_ROUTE_SUCCESS": null,
    "PURCHASE_ROUTE_FAILURE": null,
    "PURCHASE_ROUTE_CANCELLED": null,

    "RESTORE_PURCHASES": null,
    "RESTORE_PURCHASES_SUCCESS": null,
    "RESTORE_PURCHASES_FAILURE": null,

    // ACCESS CODES
    "ACCESS_CODE_SUBMIT": null,
    "ACCESS_CODE_SUBMIT_SUCCESS": null,
    "ACCESS_CODE_SUBMIT_FAILURE": null,
    "ACCESS_CODE_RESET": null,
    "LOAD_ACCESS_CODES": null,
    "LOAD_ACCESS_CODES_SUCCESS": null,
    "LOAD_ACCESS_CODES_FAILURE": null,
    "ACCESS_CODE_LOOKUP_FAILURE": null,
    "ACCESS_CODE_LOOKUP_SUCCESS": null,
    "ACCESS_CODE_BY_EMAIL_LOOKUP_SUCCESS": null,
    "ACCESS_CODE_BY_URL_SUCCESS": null,

    // URLS
    "OPEN_URL": null,
    "URI_CHANGED": null,

    // OFFLINE MODE
    "OFFLINE_MODE_LOOKUP": null,
    "OFFLINE_MODE_LOOKUP_SUCCESS": null,
    "OFFLINE_MODE_ENABLE": null,
    "OFFLINE_MODE_ENABLE_SUCCESS": null,
    "OFFLINE_MODE_DOWNLOAD_SUCCESS": null,
    "OFFLINE_MODE_ABORT": null,
    "OFFLINE_MODE_ABORTED": null,
    "OFFLINE_MODE_RESET": null,

});
