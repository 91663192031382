import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import { View, Dimensions, StyleSheet } from 'react-native';
// import Carousel, { Pagination } from 'react-native-snap-carousel';
import Swiper from 'react-native-web-swiper';
import { FontAwesome } from '@expo/vector-icons';

import LocalImage from "../../shared/LocalImage"
import { isRetina } from "utils/isRetina";
import { getRouteColor } from "styles/shared";

const horizontalMargin = 0;

const sliderWidth = Dimensions.get('window').width;
const slideWidth = sliderWidth;
let itemWidth = slideWidth + horizontalMargin * 2;
// const itemHeight = itemWidth;
let imageWidth = itemWidth
let imageHeight = itemWidth
if (imageWidth > 600) {
    imageWidth = 600;
    imageHeight = 600;
}


const getCarouselStyles = (color) => {
    return StyleSheet.create({
        carouselContainer: {
            // extends the carousel over the extra margin of the page header
            // marginBottom: -1 * Constants.statusBarHeight
            position: "relative",
            display: "flex",
            alignItems: "center"

        },
        carouselContainerBG: {
            position: "absolute",
            top: 0,
            bottom: 30,
            width: "100%",
            backgroundColor: `${color}1A`, // add some transparency
        },
        carouselContainerInner: {
            height: imageHeight,
            width: imageWidth
        },
        carousel: {
           
            // alignItems: "center"
            // flex: 1,
            // height: imageHeight,
            // border: "1px solid red"
        },
        slide: {
            // width: imageWidth,
            // height: imageHeight,
            height: "100%",
            // backgroundColor: `${color}1A`, // add some transparency
            // paddingHorizontal: horizontalMargin,
            // display: "flex",
            // flex: 1,
            // alignItems: "center",
            // justifyContent: "center",
            // border: "1px solid red",
            paddingBottom: 30

        },
        slideImage: {
            // height: imageHeight,
            // width: imageWidth,
            // borrder: "1px solid red",
            width: "100%",
            height: "100%",
            // flex: 1,
            // justifyContent: "center",
            // alignItems: "center",
            // other styles for the inner container
        },
        slideIcon: {
            width: "100%",
            height: "100%",
            // width: itemWidth,
            // height: itemHeight,
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
        },
        paginationContainer: {
            // paddingTop: 8,
            // paddingBottom: 0,
            // zIndex: 1
        },
        paginationDot: {
            // width: 14,
            // height: 14,
            // borderRadius: 7,
            // marginHorizontal: 0
        }
    })
}

const getImageSize = (image) => {
    return isRetina && image.applarge ? "applarge" : "appsmall";
}

const _renderImage = ({image, carouselStyles, color, timestamp}) => {
    const imageContent = <View key={image.imageNum} style={carouselStyles.slide}>
        <LocalImage
            style={carouselStyles.slideImage}
            image={image}
            imageSize={getImageSize(image)}
            timestamp={timestamp}
            placeholder={
                <View style={carouselStyles.slideIcon}>
                    <FontAwesome
                        name={"photo"}
                        size={48}
                        style={{color: color}} 
                    />
                </View>
            }
        />
    </View>

    return imageContent;
}

const ImageCarousel = () => {

    const detailsPageData = useSelector(state => state.mapData.detailsPageData);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);

    const { slug, images } = detailsPageData;
    const color = selectedRouteData ? getRouteColor(selectedRouteData.slug) : detailsPageData.color;

    const [selectedSlide, setSelectedSlide] = useState(0);
    const carouselRef = useRef(null);

    useEffect(() => {
        setSelectedSlide(0);
    }, [slug]);

    if (!images) {
        return null;
    }

    const carouselStyles = getCarouselStyles(color);

    return  <View style={{
        ...carouselStyles.carouselContainer
    }}>
        <View style={carouselStyles.carouselContainerBG} />
        <View style={{
            ...carouselStyles.carouselContainerInner
        }}>
            <Swiper

                controlsProps={{
                    slideWrapperStyle: {
                        marginBottom: 15,
                        paddingBottom: 15
                    },
                    dotsWrapperStyle: {
                    },
                    dotActiveStyle: {
                        backgroundColor: color
                    },
                    dotsTouchable: true,
                    PrevComponent: () => <></>,
                    NextComponent: () => <></>,
                    // prevPos: "left",
                    // nextPos: "right",
                    // PrevComponent: ({ onPress }) => (
                    //     <TouchableOpacity onPress={onPress}>
                    //         <FontAwesome
                    //             name={"chevron-left"}
                    //             size={36}
                    //             style={{color: color}} 
                    //         />
                    //     </TouchableOpacity>
                    // ),
                    // NextComponent: ({ onPress }) => (
                    //     <TouchableOpacity onPress={onPress}>
                    //         <FontAwesome
                    //             name={"chevron-right"}
                    //             size={36}
                    //             style={{color: color}} 
                    //         />
                    //     </TouchableOpacity>
                    // ),
                }}
            >
                { images.map((image) => _renderImage({image, color, carouselStyles, timestamp: selectedRouteData?.timestamp})) }
            </Swiper>
        </View>
    </View>
    {/* <View>
            <Pagination
                dot
                dotsLength={images.length}
                activeDotIndex={selectedSlide}
                containerStyle={carouselStyles.paginationContainer}
                dotColor={color}
                dotStyle={carouselStyles.paginationDot}
                inactiveDotColor={"#90A4AE"}
                inactiveDotOpacity={1}
                inactiveDotScale={1}
                carouselRef={carouselRef}
                tappableDots={!!carouselRef}
            />
        </View> */}
}
export default ImageCarousel;