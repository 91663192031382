import AsyncStorage from '@react-native-async-storage/async-storage';
import { SentryPlatform } from 'utils/sentry';
import queryString from 'query-string';

import config from "./apiConfig";

import { staticAccessCodesList } from "utils/accessCodes";
import { types } from "../types";
import { UserActionDuration } from "utils/userActions";
import { getUserUniqueID } from './userActionsAPI';

const getStaticAccessCodes = async () => {
    const accessCodeListRaw = await AsyncStorage.getItem('@accessCodeList');
    const savedAccessCodes = accessCodeListRaw ? JSON.parse(accessCodeListRaw) : [];
    return savedAccessCodes
}

const getPurchasedAccessCodes = async () => {
    const accessCodeListRaw = await AsyncStorage.getItem('@purchasedAccessCodeList');
    const accessCodes = accessCodeListRaw ? JSON.parse(accessCodeListRaw) : [];
    return accessCodes
}

const accessCodeLookup = async(accessCode) => {
    const userID = await getUserUniqueID();
    const apiClient = config.getInstance();
    const getCodes = () => apiClient.get(`/access-code/${accessCode}`, { headers: { "user-id": userID}});

    let errorMessage;

    const res = await getCodes().catch((e) => {
        console.error("Invalid access code");
        console.error(e);
        errorMessage = e.response?.data?.error;
        if (errorMessage !== "invalid-access-code") {
            SentryPlatform.captureMessage(`REAL Error looking up access code ${errorMessage}`);
        }
    });

    return { codeData: res?.data, errorMessage };
}

export const accessCodeAPI = () => next => action => {
    
    const accessCodeSubmit = async() => {

        const uad = new UserActionDuration();

        const lang = action.lang;
        const accessCode = action.accessCode.trim()
        const loweredAccessCode = accessCode.toLowerCase();

        // first check the list of static access codes
        // if it is a static access code, consider it a success, even if it is inactive
        let isInactive = false; // even a valid access code may be inactive
        if (Object.keys(staticAccessCodesList).indexOf(loweredAccessCode) > -1) {
            isInactive = staticAccessCodesList[loweredAccessCode].inactive == true;
            if (!isInactive) {
                let staticAccessCodes = await getStaticAccessCodes();
                if (staticAccessCodes.indexOf(loweredAccessCode) == -1) {
                    // don't add a duplicate record
                    staticAccessCodes.push(loweredAccessCode);
                }
                await AsyncStorage.setItem('@accessCodeList',`${JSON.stringify(staticAccessCodes)}`);
                const duration = uad.duration;
                return next({
                    type: types.ACCESS_CODE_SUBMIT_SUCCESS,
                    staticAccessCodes,
                    duration,
                    userActionData: { staticAccessCodes }
                });
            }
        }

        // check for existing purchased access codes
        let alreadyRedeemed = false;
        const purchasedAccessCodes = await getPurchasedAccessCodes();
        purchasedAccessCodes.forEach(purchasedAccessCode => {
            if (purchasedAccessCode.accessCode == accessCode) {
                alreadyRedeemed = true;
            }
        })

        if (alreadyRedeemed) {
            const duration = uad.duration;
            return next({
                type: types.ACCESS_CODE_LOOKUP_FAILURE,
                errorMessage: "already-redeemed",
                duration
            });
        }

        

        // if it is not a static access code, lookup the access code in the database
        const { codeData, errorMessage } = await accessCodeLookup(accessCode);
        
        if (codeData) {
            // successful lookup, add to saved access codes
            let purchasedAccessCodes = await getPurchasedAccessCodes();
            purchasedAccessCodes.push(codeData);
            await AsyncStorage.setItem('@purchasedAccessCodeList',`${JSON.stringify(purchasedAccessCodes)}`);

            const duration = uad.duration;
            return next({
                type: types.ACCESS_CODE_LOOKUP_SUCCESS,
                purchasedAccessCodes,
                duration,
                userActionData: { purchasedAccessCodes }
            });
        }

        if (errorMessage !== "invalid-access-code") {
            // if the error is "invalid-access-code" continue to lookup by email
            // otherwise, return the error
            const duration = uad.duration;
            return next({
                type: types.ACCESS_CODE_LOOKUP_FAILURE,
                errorMessage,
                duration
            });
        }

        let emailErrorMessage = null;

        // do email lookup
        const getEmail = () => config.getInstance().get(`/access-code-email/${accessCode}?lang=${lang}`);
        const resEmail = await getEmail().catch((e) => {
            console.error("Error looking up access codes by email");
            console.error(e);
            emailErrorMessage = e.response?.data?.error;
            if (errorMessage !== "no-access-codes-found") {
                SentryPlatform.captureMessage(`REAL Error looking up access code by email ${errorMessage}`);
            }
        });

        if (resEmail && resEmail.data) {
            const duration = uad.duration;
            return next({
                type: types.ACCESS_CODE_BY_EMAIL_LOOKUP_SUCCESS,
                duration
            });
        }

        const duration = uad.duration;
        return next({
            type: types.ACCESS_CODE_LOOKUP_FAILURE,
            duration,
            errorMessage: emailErrorMessage
        });
    };

    const loadAccessCodes = async() => {
        const uad = new UserActionDuration();
        try {
            const staticAccessCodes = await getStaticAccessCodes();
            const purchasedAccessCodes = await getPurchasedAccessCodes();
            const duration = uad.duration;
            return next({
                type: types.LOAD_ACCESS_CODES_SUCCESS,
                staticAccessCodes,
                purchasedAccessCodes,
                duration,
                userActionData: { staticAccessCodes, purchasedAccessCodes }
            });
        } catch {
            SentryPlatform.captureMessage("LOAD_ACCESS_CODES_FAILURE");
            const duration = uad.duration;
            return next({
                type: types.LOAD_ACCESS_CODES_FAILURE,
                duration
            });
        }
    }

    const getURLAccessCode = async() => {
        // if location isn't defined, return null
        if (typeof location === "undefined") {
            return null;
        }
        const {a} = queryString.parse(location?.search);
        if (a) {

            // there are three possibilties for the access code
            // already redeemed, just redeemed, or invalid
            const alreadyRedeemed = [];
            const justRedeemed = [];
            const invalid = [];
            let purchasedAccessCodes = await getPurchasedAccessCodes();

            const codes = a.split(',');
            await Promise.all(codes.map(async(code) => {
                console.log(`About to check ${code}...`);
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async(resolve) => {
                    // debugger;
                    let isRedeemed = false;
                    purchasedAccessCodes.filter(purchasedAccessCode => {
                        if (purchasedAccessCode.accessCode == code) {
                            alreadyRedeemed.push(purchasedAccessCode);
                            console.log(`Already redeemed ${code}`);
                            // debugger;
                            isRedeemed = true;
                            resolve();
                        }
                    });

                    if (!isRedeemed) {
                        const { codeData, errorMessage } = await accessCodeLookup(code);
                        // debugger;
                        if (codeData) {
                        // successful lookup, add to saved access codes
                            justRedeemed.push(codeData);
                            console.log(`Just redeemed ${code}`);
                            resolve();
                        } else {
                            invalid.push({code, errorMessage});
                            console.log(`Invalid ${code}`);
                            resolve();
                        }
                    }
                })
            }));

            purchasedAccessCodes = [...purchasedAccessCodes, ...justRedeemed]
            await AsyncStorage.setItem('@purchasedAccessCodeList',`${JSON.stringify(purchasedAccessCodes)}`);

            return next({
                type: types.ACCESS_CODE_BY_URL_SUCCESS,
                purchasedAccessCodes,
                alreadyRedeemed,
                justRedeemed,
                invalid
            })

        }
    }

    next(action);
    switch (action.type) {
        case types.ACCESS_CODE_SUBMIT: {
            accessCodeSubmit();
            break;
        }
        case types.LOAD_ACCESS_CODES: {
            loadAccessCodes();
            getURLAccessCode();
            break;
        }
        default:
            break;
    }
};