import { types } from "../types";

const initialState = {
    offlineStatuses: {},
    assetCount: 0,
    finishedAssets: 0,
};

export const offlineReducer = (state = initialState, action) => {
    switch (action.type) {
        // case types.OFFLINE_MODE_LOOKUP: {
        //     return { ...state, routeDataLoading: true, routeDataLoaded: false, routeDataError: false };
        // }
        case types.OFFLINE_MODE_ENABLE: {

            const { routeSlug } = action;
            const offlineStatuses = { ...state.offlineStatuses };
            offlineStatuses[routeSlug] = "downloading";
            return { ...state, offlineStatuses };
        }
        case types.OFFLINE_MODE_LOOKUP_SUCCESS: {
            const { returnString, routeSlug } = action;
            const offlineStatuses = { ...state.offlineStatuses };
            if (offlineStatuses[routeSlug] != "aborted") {
                offlineStatuses[routeSlug] = returnString;
            }
            return { ...state, offlineStatuses };
        }
        case types.OFFLINE_MODE_DOWNLOAD_SUCCESS : {
            const { returnString, routeSlug, assetCount, finishedAssets } = action;
            const offlineStatuses = { ...state.offlineStatuses };
            if (offlineStatuses[routeSlug] != "aborted") {
                offlineStatuses[routeSlug] = returnString;
            }
            return { ...state, offlineStatuses, assetCount, finishedAssets };

        }
        case types.OFFLINE_MODE_ABORTED: {
            const { returnString, routeSlug, assetCount, finishedAssets } = action;
            const offlineStatuses = { ...state.offlineStatuses };
            offlineStatuses[routeSlug] = returnString;
            return { ...state, offlineStatuses, assetCount, finishedAssets };
        }
        case types.OFFLINE_MODE_RESET: {
            return { ...state, offlineStatuses: {} };
        }
        default:
            return state;
    }
};