import React from "react";
import { useSelector } from "react-redux";
import { StyleSheet, View, Text, Platform } from "react-native";

import { colorNames } from "../../../styles/shared";
import { getLangTerm } from "lang";
import { settingsStyles } from "./styles/settingsStyles";
import { staticAccessCodesList } from "../../../utils/accessCodes";

const accessCodeStyles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    activeCodes: {
        fontSize: 16,
        color: colorNames.parksGreen,
        fontFamily: "bold",
        marginTop: 10,
        marginBottom: 5
    },
    accessCodeErrorText: {
        marginTop: 10,
        color: colorNames.actionRed,
    }
});

const AccessCodeDetails = () => {

    const lang = useSelector(state => state.settings.lang);
    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);
    const routeData = useSelector(state => state.mapData.routeData);

    const urlInvalid = useSelector(state => state.accessCodes.urlInvalid);

    const getAllUnlockedRoutes = () => {
        let allUnlockedRoutes = [];
        staticAccessCodes.forEach(accessCode => {
            staticAccessCodesList[accessCode].routes.map(routeName => {
                const title = routeData.filter(route => route.routeSlug === routeName)[0]?.title;
                allUnlockedRoutes.push(title);
            });
        });

        // need to loop through route data and find the routes that have the offering
        purchasedAccessCodes.forEach(accessCode => {
            accessCode.offerings?.forEach(offering => {
                const routeTitles = routeData.filter(route => route.offerings?.indexOf(offering) > -1).map(route => route.title);
                allUnlockedRoutes.push(...routeTitles);
            });
        });

        return Array.from(new Set(allUnlockedRoutes)); // using Set removes duplicates
    }

    if (Platform.OS === "ios") {
        return null;
    }

    if (staticAccessCodes.length === 0 && purchasedAccessCodes.length === 0 && urlInvalid.length == 0) return null;

    const unlockedRoutes = getAllUnlockedRoutes();

    return <View style={accessCodeStyles.container}>
        <Text style={accessCodeStyles.activeCodes} >{getLangTerm("premium-content-unlocked", lang)}</Text>
        <Text style={settingsStyles.purchase}>{ unlockedRoutes.join(", ") }</Text>
        { urlInvalid.length > 0 && <Text style={accessCodeStyles.accessCodeErrorText}>{ getLangTerm("access-code-error-details", lang) }</Text> }
        { urlInvalid.map((code, index) => <Text key={index}>{code.code} {getLangTerm(code.errorMessage, lang)}</Text>) }
    </View>
}

export default AccessCodeDetails;