import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyleSheet, View, TouchableWithoutFeedback } from "react-native";
import { Ionicons } from '@expo/vector-icons';

import { types } from "redux/types";
import { MarkerIcon } from "../SVGs";
import { colorNames } from "styles/shared"

import { fancylog } from "utils/fancylog";

const logLocal = false;
fancylog.log("WalkingButtons logLocal", logLocal);

const resetButton = {
    borderRadius: 24,

    width: 42,
    height: 42,
    marginRight: 8,

    backgroundColor: colorNames.actionGreen,
    justifyContent: "center",
    alignItems: "center"
}

const buttonStyles = StyleSheet.create({
    walkingButtons: {
        flexDirection: "row",
        marginRight: 12
    },
    resetToUser: {
        ...resetButton,
        marginLeft: 8
    },
    resetToDestination: {
        ...resetButton
    },
    resetIcon: {
        color: "#FFF"
    },
    buttonSpacer: {
        width: 1,
        height: 48,
    }
});

const WalkingButtons = () => {
    // const { isWalkingRoute, selectedRoute, selectedStop, setMapToPoint, inBoundaries, centerMapOnUser, userPosition } = props;

    const isWalkingRoute = useSelector(state => state.mapData.isWalkingRoute);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedStopData = useSelector(state => state.mapData.selectedStopData);
    const inBoundaries = useSelector(state => state.mapData.inBoundaries);
    const locationPreference = useSelector(state => state.user.locationPreference);

    const dispatch = useDispatch();
    const centerMapOnUser = () => {
        dispatch({
            type: types.CENTER_MAP_ON_USER
        });
    }
    const setMapToPoint = (latlng) => {
        dispatch({
            type: types.SET_MAP_TO_POINT,
            point: latlng
        });
    }

    const buttons = [];

    if (isWalkingRoute) {
        let stopRouteIndex = -1
        selectedStopData && selectedRouteData.stops.forEach((stop, i) => {
            if(stop == selectedStopData.slug) {
                stopRouteIndex = i;
            }
        });
        fancylog.log({stopRouteIndex}, logLocal);

        let destination = selectedRouteData.latlng;
        if (selectedStopData) {
            destination = selectedStopData.latlng
        }

        const resetToDestintion = <TouchableWithoutFeedback key="reset-to-destination" onPress={() => {
            setMapToPoint(destination);
        }}>
            <View style={buttonStyles.resetToDestination}>
                <MarkerIcon style={{color: "#FFF"}} />
            </View>
        </TouchableWithoutFeedback>
        buttons.push(resetToDestintion);

    }

    let resetToUser = <View key="reset-to-user" style={{width: resetButton.width}}></View>

    // need a way to bind this to people within the Vancouver area
    if(locationPreference && inBoundaries) {
        resetToUser = <TouchableWithoutFeedback key="reset-to-user" onPress={() => {
            centerMapOnUser();
        }}>
            <View style={buttonStyles.resetToUser}>
                <Ionicons name="ios-walk" size={24} style={buttonStyles.resetIcon} />
            </View>
        
        </TouchableWithoutFeedback>
    }
    buttons.push(resetToUser);

    return <View style={buttonStyles.walkingButtons}>{buttons}</View>;

}
export default WalkingButtons;