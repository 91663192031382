import AsyncStorage from '@react-native-async-storage/async-storage';
import { SentryPlatform } from 'utils/sentry';

import config from "./apiConfig";
import store from "../root";

import { types } from "../types";
import { latLngFromGeoJson, getSelectedRouteData } from "utils/map";
import { UserActionDuration } from "utils/userActions";
import { getParamsFromURI } from "utils/uri";

const getRouteDataToken = (lang) => {
    return `route-data-${lang}`;
}

const getSingleRouteDataToken = ({routeSlug, lang}) => {
    // the stanley park routes are cached together
    // { stanley-park-seawall: { ... }, stanley-park-inner: { ... }}
    const cacheToken = routeSlug.indexOf('stanley-park') > -1 ? 'stanley-park' : routeSlug;

    const routesToken = `route-${cacheToken}-${lang}`;
    return routesToken;
}

export const routesAPI = () => next => action => {

    const loadSingleRouteFromURI = ({routeData, lang}) => {
        const { routeSlug, offlineMode } = getParamsFromURI();
        if (routeSlug) {
            const selectedRoute = getSelectedRouteData({routeSlug, routeData});
            if (selectedRoute) {
                // load the cached stops
                checkSingleRouteCache({routeSlug, lang, timestamp: selectedRoute.timestamp, offlineMode})
            }
        }
    }

    const routeDataLookup = async(routesTimestamp, lang) => {
        const uad = new UserActionDuration();

        const apiClient = config.getInstance();
        const get = () => apiClient.get(`/route-slugs/${lang}`);

        const res = await get().catch((e) => {
            console.error("Error looking up routes");
            console.error(e);
            SentryPlatform.captureMessage("Error looking up routes");
        });

        
        
        if (!res || !res.data) {
            const duration = uad.duration;
            return next({
                type: types.ROUTE_DATA_LOOKUP_FAILURE,
                duration
            });
        }
        const items = res.data.items;

        const routeData = [];
        const langData = {};

        // this has to be looped twice. Once to populate all the lang content and a second to get the lang data for each item
        items.forEach((item) => {
            if (item.lang) {
                // this is a piece of the language content
                langData[item.routeRNG1] = item
            }
        });
        
        items.forEach((item) => {
            if (!item.lang) {
                // this is a start of the route
                routeData.push({
                    ...langData[`${lang}-${item.routeRNG1}`],
                    ...item,
                    latlng: latLngFromGeoJson(item.geoJson),
                    stopType: "route-start"
                });
            }
        });

        routeData.sort((a,b) => {
            return (a.order > b.order) ? 1 : -1;
        })

        const routeDataToken = getRouteDataToken(lang);
        const newRouteData = JSON.stringify({routeData, timestamp: routesTimestamp});
        await AsyncStorage.setItem(routeDataToken, newRouteData);

        loadSingleRouteFromURI({routeData,lang});

        const duration = uad.duration;

        return next({
            type: types.ROUTE_DATA_LOOKUP_SUCCESS,
            routeData,
            duration
        });
    }
    
    const checkRouteDataCache = async(routesTimestamp, lang) => {
        const uad = new UserActionDuration();

        // MAYBE TO DO: if there is no connection, load the most recent data?

        // no cached data
        if(!routesTimestamp || !lang) {
            return;
        }
        const routeDataToken = getRouteDataToken(lang);
        let allRoutesCachedData = await AsyncStorage.getItem(routeDataToken);
        allRoutesCachedData = JSON.parse(allRoutesCachedData);

        if (!allRoutesCachedData || !allRoutesCachedData.timestamp || allRoutesCachedData.timestamp < routesTimestamp) {
            routeDataLookup(routesTimestamp, lang)
        } else {

            const routeData = allRoutesCachedData.routeData;

            // once the route data is loaded, check if there is a walking route set in the URI
            loadSingleRouteFromURI({routeData, lang});

            const duration = uad.duration;
            
            return next({
                type: types.ROUTE_DATA_LOOKUP_SUCCESS,
                routeData,
                duration,
                userActionData: { fromCache: true }
            });
        }
    };

    const loadSingleRoute = async ({routeSlug, stopSlug, lang, timestamp, animationDuration, offlineMode}) => {
        if (!routeSlug || !lang) {
            return;
        }
        const uad = new UserActionDuration();

        // if the route being request is either of the stanley park routes, load both of them
        // for this reason, all single route requests are treated as arrays of lookups
        const routeToken = routeSlug.indexOf('stanley-park') > -1 ? ['stanley-park-seawall', 'stanley-park-inner'] : [routeSlug];
        const apiClient = config.getInstance();

        const responses = await Promise.all(routeToken.map(async (routeSlug) => {
            const get = () => apiClient.get(`/route/${routeSlug}/${lang}`);
            const res = await get().catch((e) => {
                console.error("Error looking up route");
                console.error(e);
                SentryPlatform.captureMessage("Error looking up route");
            });
            return { key: routeSlug, data:  res?.data?.items }
        }));

        if (!responses || !responses[0]  || !responses[0].data || responses[0].data?.length === 0) {
            const duration = uad.duration;
            return next({
                type: types.SINGLE_ROUTE_LOOKUP_FAILURE,
                routeSlug,
                duration
            });
        }

        const routeStopsData = responses.map(route => {
            const items = route.data;

            const routeStops = [];
            const langData = {};
    
            // this has to be looped twice. Once to populate all the lang content and a second to get the lang data for each item
            items.forEach((item) => {
                if (item.lang) {
                    // this is a piece of the language content
                    langData[item.routeRNG1] = item
                }
            });
            
            items.forEach((item) => {
                if (!item.lang) {
                    // this is a slug of the route
                    routeStops.push({
                        ...langData[`${lang}-${item.routeRNG1}`],
                        ...item,
                        latlng: latLngFromGeoJson(item.geoJson),
                        stopType: item.stopType || "stop",
                    });
                }
            });

            route.data = routeStops;

            return route;
        });

        const routeCacheToken = getSingleRouteDataToken({routeSlug, lang});
        const routeCacheData = JSON.stringify({routeStopsData, timestamp});

        await AsyncStorage.setItem(routeCacheToken, routeCacheData);

        const duration = uad.duration;

        return next({
            type: types.SINGLE_ROUTE_LOOKUP_SUCCESS,
            offlineMode,
            routeSlug,
            stopSlug,
            lang,
            routeStopsData,
            animationDuration,
            duration
        });

    }

    const checkSingleRouteCache = async(action) => {
        const { routeSlug, timestamp, lang, animationDuration, offlineMode } = action;
        if (!routeSlug || !lang) {
            return;
        }
        const uad = new UserActionDuration();

        const routeToken = getSingleRouteDataToken({routeSlug, lang});

        let routeCachedData = await AsyncStorage.getItem(routeToken);
        routeCachedData = JSON.parse(routeCachedData);
        if (!routeCachedData || !routeCachedData.timestamp || routeCachedData.timestamp < timestamp) {
            loadSingleRoute(action)
        } else {
            const duration = uad.duration;
            
            return next({
                type: types.SINGLE_ROUTE_LOOKUP_SUCCESS,
                offlineMode,
                routeSlug,
                lang,
                routeStopsData: routeCachedData.routeStopsData,
                animationDuration,
                duration,
                userActionData: { fromCache: true }
            });
        }
    };

    const uriChanged = async (action) => {
        // edge case: page was loaded on a place details page, and the user hit back to a route in the url
        // in this case we need to load the single route data
        const { routeSlug
        } = action.uriState;
        const storeState = store.getState();
        const { routeData, routeStops } = storeState.mapData;
        const { lang } = storeState.settings;

        if (routeSlug && !routeStops[routeSlug]) {

            loadSingleRouteFromURI({routeData,lang});
        }
    }

    next(action);
    switch (action.type) {
        case types.ROUTE_DATA_LOOKUP: {
            checkRouteDataCache(action.routesTimestamp, action.lang);
            break;
        }
        case types.LANG_CHANGED: {
            checkRouteDataCache(action.routesTimestamp, action.lang);
            if (action.routeSlug) {
                checkSingleRouteCache(action);
            }
            break;
        }
        case types.SINGLE_ROUTE_LOOKUP: {
            checkSingleRouteCache(action);
            break;
        }
        case types.URI_CHANGED: {
            uriChanged(action);
            break;
        }
        default:
            break;
    }
};