import axios from "axios";

import getEnvVars from 'environment';

const vars = getEnvVars();

const BASE_URL = `${vars.apiUrl}`;
const API_KEY = `${vars.apiKey}`;



let instance = null;
const config = {
    getInstance: () => {
        if (!instance) {
            instance = axios.create({
                baseURL: BASE_URL,
                ...(API_KEY && {headers: {"x-api-key": API_KEY}})
            });
        } else  {
            return instance;
        }
  
        instance.interceptors.request.use(
            async configuration => {
                const originalConfig = configuration;

                return originalConfig;
            },
            err => Promise.reject(err)
        );
  
        return instance;
    },
    /**
     * Resets the axios instance.
     */
    resetInstance: () => {
        instance = null;
    }
};
  
export default config;