import { getDistance, getWalkingTime } from "utils/distance";

export const isPointInRegion = (point, region) => {
    const right = region.longitude - (region.longitudeDelta / 2);
    const left = region.longitude + (region.longitudeDelta / 2);
    const top = region.latitude - (region.latitudeDelta / 2);
    const bottom = region.latitude + (region.latitudeDelta / 2);
    const inRegion = point.longitude <= left && point.longitude >= right && point.latitude <= bottom && point.latitude >= top;
    const distanceFromScreenCenter = getDistance(point, region);
    return {
        inRegion,
        distanceFromScreenCenter
    };
};

export const latLngFromGeoJson = (geoJson) => {
    const geo = JSON.parse(geoJson);
    return {
        latitude: geo.coordinates[1], 
        longitude: geo.coordinates[0]
    }
};

export const getSelectedRouteData = ({routeSlug, routeData}) => {

    const selectedRouteData = routeSlug ? routeData.filter(route => route.slug == routeSlug)[0] : null;

    return selectedRouteData;
}

export const getSelectedRouteStops = (selectedRouteData, stops) => {

    // the route stops aren't sorted, but the slug has a stops property that is in the right order
    // loop through the route's stops and find the stop data for each one

    const selectedRouteStops = [];

    // loop through the stop slugs for each route and find each one from the stops data
    selectedRouteData && selectedRouteData.stops.forEach(stopSlug => {
        const foundStop = stops.filter(routeStop => routeStop.slug == stopSlug)[0];
        selectedRouteStops.push(foundStop);
    });

    return selectedRouteStops;
}

export const getSelectedRouteStopsWithDistances = (selectedRouteData, stops, userPosition) => {
    const selectedRouteStops = getSelectedRouteStops(selectedRouteData, stops)

    if (userPosition) {
        // grab the selectedRouteStops and add a distance to each one
        return selectedRouteStops.map((stop) => {
            return { ...stop, ...getStopDistanceAndMinutes(stop, userPosition)};
        });
    } else {
        return selectedRouteStops
    }
}

export const getSelectedStopData = (stopSlug, stops) => {

    const selectedStopData = stopSlug ? stops.filter(routeStop => routeStop.slug == stopSlug)[0] : null;
    
    return selectedStopData;
}

export const getRegionFromLatLng = (latlng) => {
        
    const newRegion = {};
    newRegion.latitude = latlng.latitude;
    newRegion.longitude = latlng.longitude
    newRegion.longitudeDelta = 0.004023313522353078;
    newRegion.latitudeDelta = 0.004667889482171006;

    return newRegion;
}

export const getRegionFromTwoPoints = (latlng1, latlng2) => {
    const newRegion = {};

    const lat = (latlng1.latitude + latlng2.latitude) / 2;
    const long = (latlng1.longitude + latlng2.longitude) / 2;

    newRegion.latitude = lat;
    newRegion.longitude = long;

    // eventually have a min and max delta
    // if the delta is too small (too zoomed in), just make it bigger
    // if the delta is too big (too far apart), figure something out, might not be a problem
    // maybe need to have the destination off screen with a little arrow, depends on how far apart the stops end up being

    newRegion.latitudeDelta = (latlng1.latitude - latlng2.latitude) * 2;
    newRegion.longitudeDelta = (latlng1.longitude - latlng2.longitude) * 2;

    return newRegion;
}

export const getRegionWithUserPosition = (latlng, userPosition, regionSize) => {
    const newRegion = {};

    if (!latlng) {
        return newRegion;
    }

    const distanceFromUser = getDistance(latlng, userPosition);

    if (regionSize === "extra-small") {
        newRegion.latitude =  latlng.latitude;
        newRegion.longitude = latlng.longitude;
        newRegion.longitudeDelta = 0.000534016966833974;
        newRegion.latitudeDelta = 0.0002264462285453988;
    } else if (!distanceFromUser || distanceFromUser > 300) {
        // user is far away, just center on the point
        newRegion.latitude =  latlng.latitude;
        newRegion.longitude = latlng.longitude;
        newRegion.longitudeDelta = 0.002534016966833974;
        newRegion.latitudeDelta = 0.0032264462285453988;
    } else  {
        // newRegion.latitude = (latlng.latitude + userPosition.latitude) / 2;
        // newRegion.longitude = (latlng.longitude + userPosition.longitude) / 2;
        newRegion.latitude =  latlng.latitude;
        newRegion.longitude = latlng.longitude;
        newRegion.latitudeDelta = Math.abs(latlng.latitude - userPosition.latitude) * 2.2;
        newRegion.longitudeDelta = Math.abs(latlng.longitude - userPosition.longitude) * 2.2;
    }

    return newRegion;
}

export const getClosestDistance = (userPosition, routeData) => {
    let closestDistance = null; // keep null if there is no user position

    if(userPosition && routeData.length > 0) {
        closestDistance = 1000000;
        routeData.forEach(route => {
            const distance = getDistance(userPosition, route.latlng);
            if (distance < closestDistance) {
                closestDistance = distance;
            }
        });
    }

    return closestDistance;
}

export const getStopDistanceAndMinutes = (stop, userPosition) => {
    // takes a list of items and returns them with the the walking distance
    const stopDistances = {};

    if(!userPosition) {
        return;
    }

    const { distance, minutes } = getWalkingTime(stop.latlng, userPosition);
    stopDistances.distance = distance;
    stopDistances.minutes = minutes;

    // some stops have a landing point, which will count as arriving, if the user goes there.
    // if that distance is less than the actual stop, use that one
    if (stop.landingPoint) {
        const { distance: landingDistance, minutes: landingMinutes } = getWalkingTime(stop.landingPoint, userPosition);
        if (landingDistance < distance) {
            stopDistances.distance = landingDistance;
            stopDistances.minutes = landingMinutes;
        }
    }
    return stopDistances;
}

export const shadeColor = (color, percent) => {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export const toLatLng = (obj) => {
    // take a { latitude, longitude } object and return at { lat, lng } pbject
    return { lat: obj.latitude, lng: obj.longitude}
}

// export const getBoundsfromRegion = (region) => {
//     debugger;
//     const { latitude, longitude, latitudeDelta, longitudeDelta } = region;
//     const lat = new google.maps.LatLng({ lat: latitude - latitudeDelta / 2, lng: longitude - longitudeDelta / 2 });
//     const lat2 = new google.maps.LatLng({ lat: latitude + latitudeDelta / 2, lng: longitude + longitudeDelta / 2 });
//     const bounds = new google.maps.LatLngBounds(lat, lat2);
//     return bounds;
// }

export const calcZoom = (region) => {

    // Omit rounding intentionally for the example
    return Math.log(360 / region.longitudeDelta) / Math.LN2;
}

var panPath = [];   // An array of points the current panning action will use
var panQueue = [];  // An array of subsequent panTo actions to take
var STEPS = 20;     // The number of steps that each panTo action will undergo
let localMap = null;

export const panTo = (map, latlng, animationDuration) => {
    const { lat: newLat, lng: newLng } = latlng;
    localMap = map;
    if (panPath.length > 0) {
        // We are already panning...queue this up for next move
        panQueue.push(latlng);
    } else {
        // Lets compute the points we'll use
        panPath.push("LAZY SYNCRONIZED LOCK");  // make length non-zero - 'release' this before calling setTimeout
        var curLat = localMap.getCenter().lat();
        var curLng = localMap.getCenter().lng();
        const numSteps = animationDuration ? animationDuration/50 : STEPS
        var dLat = (newLat - curLat)/numSteps;
        var dLng = (newLng - curLng)/numSteps;
        // var dLat = (newLat - curLat)/STEPS;
        // var dLng = (newLng - curLng)/STEPS;

        for (var i=0; i < numSteps; i++) {
            panPath.push([curLat + dLat * i, curLng + dLng * i]);
        }
        panPath.push([newLat, newLng]);
        panPath.shift();      // LAZY SYNCRONIZED LOCK
        setTimeout(doPan, 10);
    }
}

const doPan = () => {
    var next = panPath.shift();
    if (next != null) {
        // Continue our current pan action
        localMap.panTo( new google.maps.LatLng(next[0], next[1]));
        setTimeout(doPan, 20 );
    } else {
        // We are finished with this pan - check if there are any queue'd up locations to pan to 
        var queued = panQueue.shift();
        if (queued != null) {
            panTo(localMap, queued);
        } else {
            // localMap.setZoom(zoom);
        }
    }
}