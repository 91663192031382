import { types } from "../types";
import {
    getSelectedRouteData,
    getSelectedRouteStopsWithDistances,
    getSelectedStopData,
    getRegionWithUserPosition,
    getClosestDistance,
    getStopDistanceAndMinutes
} from "utils/map"
import { getDistance } from "utils/distance";
import { getParamsFromURI } from "utils/uri";
import { colors } from "styles/shared"

const ARRIVED_DISTANCE = 5; // distance in meters to be near the point and be "there"
const BOUNDARIES_DISTANCE = 100000; // distance in meters to from cloests stop to show the walking route UI, this will be replaced with a boundary box

// this is the one for the actual app
export const initialRegion = {
    latitude: 49.28600721158095,
    latitudeDelta: 0.00512271396002717,
    longitude: -123.11267897486687,
    longitudeDelta: 0.004023313522353078,
};

// this is whatever for developing, keep it near to what is being worked on to save time
// const initialRegion = {
//     longitudeDelta: 0.0020116567611694336,
//     latitude: 49.28976124142498,
//     longitude: -123.1161443889141,
//     latitudeDelta: 0.0023337363797466537
// };

const initialState = {
    routeData: [],
    routeStops: {},  //since multiple sets of stops are needed, this is an object with the key being the route slug
    places: [],
    routeDataLoading: false,
    routeDataLoaded: false,
    routeDataError: false,
    routeLoading: false,
    routeLoaded: false,
    routeError: false,
    offlineMode: false,

    placesLoading: false,
    placesLoaded: false,
    placesError: false,

    renderTS: "", //keep a record of when the routes were updated, in case the route object changes, for example, when the user grants location permissions and the distance strings all update
    animateTS: "", // update this when you want to trigger an animation of the map
    animationDuration: 500, // the duration of the animation
    animationCallback: null, // a function to do something after the animation
    userPosition: null, // putting this here because it's easier than getting the user position from the user reducer
    lastUpdatedPosition: null, // the last position that was updated, so we can check if the display needs to be updated
    accuracy: null, // the accuracy of the user position
    closestDistance: null, // when we get the first userPosition, check how close they are to determine if we show the "center on user" button
    region: initialRegion, // not passed anywhere, only used here to filter the map items

    showFooterList: false,
    detailsPageData: null,

    // these are related to the user doing a guided route.
    // doing the updates here so the user position is only tracked in one place
    selectedRouteData: null, // an object of the selected route's data, so it can be referenced
    selectedRouteStops: [],
    isWalkingRoute: false, // when the user is actually walking the route
    selectedStopData: null, // an object of the selected stop's data, so it can be referenced
    reachedDestination: false,
    inBoundaries: false
};

const calculateReachedDestination = (selectedRouteData, selectedStopData) => {
    let reachedDestination = selectedRouteData?.distance < ARRIVED_DISTANCE;

    if (selectedStopData) {
        reachedDestination = selectedStopData.distance < ARRIVED_DISTANCE;
    }

    return reachedDestination
}

const populateZIndecies = ({routeData, routeStops, places, selectedRouteSlug}) => {
    let latlngs = [];
    const zIndecies = {}; // key is the slug, value is the zIndex. used to repopulate the original data by slug

    routeData.forEach(route => {
        latlngs.push({
            latlng: route.latlng,
            slug: route.slug
        })
        route.slug === selectedRouteSlug && route.points?.forEach((point, i) => {
            latlngs.push({
                latlng: { latitude: point.latitude, longitude: point.longitude },
                slug: `${route.slug}-${i}`
            })
        });
    });
    let activeRouteStops = routeStops[selectedRouteSlug];
    activeRouteStops.forEach(routeStop => {
        latlngs.push({
            latlng: routeStop.latlng,
            slug: routeStop.slug
        })
    });
    places.forEach(place => {
        latlngs.push({
            latlng: place.latlng,
            slug: place.slug
        })
    });

    latlngs = latlngs.sort((a, b) => {
        // sort the array by latitude
        return b.latlng.latitude - a.latlng.latitude;
    })

    latlngs.forEach((data, i) => {
        // use the latitude to set the index, have the southern most marker be on top, 
        zIndecies[data.slug] = i + 1;
    });

    routeData = routeData.map(route => {
        if (route.slug === selectedRouteSlug) {
            route.points = route.points?.map((point, i) => {
                return {
                    ...point,
                    zIndex: zIndecies[`${route.slug}-${i}`]
                };
            });
        }
        return { ...route, zIndex: zIndecies[route.slug]}
    });
    activeRouteStops = activeRouteStops.map(routeStop => {
        return { ...routeStop, zIndex: zIndecies[routeStop.slug]}
    });
    routeStops[selectedRouteSlug] = activeRouteStops;
    places = places.map(place => {
        return { ...place, zIndex: zIndecies[place.slug]}
    });

    return { routeData, routeStops, places };
}

const getWalkingRouteData = ({routeSlug, stopSlug, userPosition, routeData, routeStops, places, animationDuration}) => {
    let selectedRouteData = getSelectedRouteData({routeSlug, routeData});
    selectedRouteData = { ...selectedRouteData, ...getStopDistanceAndMinutes(selectedRouteData, userPosition)};
    const selectedRouteStops = getSelectedRouteStopsWithDistances(selectedRouteData, routeStops[routeSlug], userPosition);

    let selectedStopData = null;
    let newRegionPosition = selectedRouteData.latlng;

    if (stopSlug) {
        // there is a stop selected, so make that the starting point
        selectedStopData = getSelectedStopData(stopSlug, routeStops[routeSlug]);
        selectedStopData = { ...selectedStopData, ...getStopDistanceAndMinutes(selectedStopData, userPosition)};

        newRegionPosition = selectedStopData.latlng;
    }

    ({ routeData, routeStops, places } = populateZIndecies({routeData, routeStops, places, selectedRouteSlug: routeSlug}));

    const newRegion = getRegionWithUserPosition(newRegionPosition, userPosition, selectedRouteData.viewportSize);
    
    return {
        showFooterList: false,
        detailsPageData: null,
        selectedRouteData,
        selectedRouteStops,
        selectedStopData,
        routeData,
        routeStops,
        places,
        region: newRegion,
        isWalkingRoute: true,
        reachedDestination: calculateReachedDestination(selectedRouteData, selectedStopData),
        ...updateAnimationSettings({animationDuration})
    }
}

const updateAnimationSettings = ({ animationDuration, animationCallback } = {}) => {
    return {
        animateTS: new Date().getTime(),
        animationDuration: animationDuration || 500,
        animationCallback: animationCallback || null,
    }
}

const getDetailsPageDataFromWalkingRouteData = ({walkingRouteData, userPosition}) => {
    let detailsPageData = null;
    if (walkingRouteData.selectedStopData) {
        detailsPageData = {
            ...walkingRouteData.selectedStopData,
            ...(userPosition && getStopDistanceAndMinutes(walkingRouteData.selectedStopData, userPosition))
        };
    } else if (walkingRouteData.selectedRouteData) {
        detailsPageData = {
            ...walkingRouteData.selectedRouteData,
            ...(userPosition && getStopDistanceAndMinutes(walkingRouteData.selectedRouteData, userPosition))
        };
    }
    return detailsPageData;
}

export const mapDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ROUTE_DATA_LOOKUP: {
            return { ...state, routeDataLoading: true, routeDataLoaded: false, routeDataError: false };
        }
        case types.ROUTE_DATA_LOOKUP_SUCCESS: {
            let { routeData } = action;

            return {
                ...state,
                routeDataLoading: false,
                routeDataLoaded: true,
                routeData,
                renderTS: new Date().getTime()
            };
        }
        case types.ROUTE_DATA_LOOKUP_FAILURE: {
            return { ...state, routeDataLoading: false, routeDataLoaded: false, routeDataError: true };
        }
        case types.SINGLE_ROUTE_LOOKUP: {
            const { routeSlug, offlineMode } = action;

            return { ...state, offlineMode, routeLoading: routeSlug, routeLoaded: false, routeError: false };
        }
        case types.SINGLE_ROUTE_LOOKUP_SUCCESS: {
            // this is when a user has clicked on a route to start walking it
            // of when the url has a route slug in it on page load
            let { routeSlug, routeStopsData, animationDuration, offlineMode } = action;
            let { routeData, routeStops, userPosition, places } = state;

            const { stopSlug, details } = getParamsFromURI();

            routeStopsData.forEach(d => {
                routeStops[d.key] = d.data;
            });

            let inBoundaries = false;
            let closestDistance = null;

            if(userPosition) {
                closestDistance = getClosestDistance(userPosition, routeData); // only need to do this once for now, it's only used to detect if the user is close enough to display the user button
                inBoundaries = closestDistance < BOUNDARIES_DISTANCE;
            }

            const walkingRouteData = getWalkingRouteData({routeSlug, stopSlug, userPosition, routeData, routeStops, places, animationDuration});

            let detailsPageData;
            if (details) {
                detailsPageData = getDetailsPageDataFromWalkingRouteData({walkingRouteData});

            }

            return {
                ...state,
                ...walkingRouteData,
                offlineMode,
                detailsPageData,
                closestDistance,
                inBoundaries,
                routeLoading: false,
                renderTS: new Date().getTime()
            }
        }
        case types.SINGLE_ROUTE_LOOKUP_FAILURE: {
            const { routeSlug } = action;
            return { ...state, routeLoading: false, routeLoaded: false, routeError: routeSlug };
        }
        case types.PLACES_LOOKUP: {
            return { ...state, placesLoading: true, placesLoaded: false, placesError: false };
        }
        case types.PLACES_LOOKUP_SUCCESS: {
            // SMALL EDGE CASE: IF THE USER HAS STARTED A ROUTE BEFORE THE PLACES HAVE LOADED
            // for now it loads all the places, eventually, it will load the just places within the selected route
            let { places } = action;
            let { routeData, selectedRouteData, routeStops } = state;
            let newPlaces = [...places];
            let newRouteData = routeData && [...routeData];
            let newRouteStops = routeStops && {...routeStops};

            if (selectedRouteData) {
                ({ routeData: newRouteData, routeStops: newRouteStops, places: newPlaces } = populateZIndecies({routeData, routeStops, places, selectedRouteSlug: selectedRouteData.slug}));
            }


            const { placeSlug, details } = getParamsFromURI();
            let detailsPageData;
            if (details && placeSlug) {
                const place = places.find(place => place.slug === placeSlug)    
                detailsPageData = {...place, color: colors.place}
            }

            return {
                ...state,
                placesLoading: false,
                placesLoaded: true,
                places: newPlaces,
                routeData: newRouteData,
                routeStops: newRouteStops,
                detailsPageData,
                renderTS: new Date().getTime()
            }; 
        }
        case types.PLACES_LOOKUP_FAILURE: {
            return { ...state, placesLoading: false, placesLoaded: false, placesError: true };
        }
        case types.SET_MAP_READY: {
            return {
                ...state,
                renderTS: new Date().getTime()
            };
        }
        case types.SET_WALKING_ROUTE_STOP: {
            const { routeSlug, stopSlug, animationDuration } = action;
            const { routeData, routeStops, places, userPosition } = state;

            const walkingRouteData = getWalkingRouteData({routeSlug, stopSlug, userPosition, routeData, routeStops, places, animationDuration});

            return {
                ...state,
                ...walkingRouteData,
                renderTS: new Date().getTime()
            };
        }
        case types.USER_LOCATION_CHANGE: {
            const { userPosition, accuracy } = action;
            const {
                lastUpdatedPosition,
                routeDataLoaded,
                routeData,
                routeStops,
                closestDistance,
                inBoundaries,
                isWalkingRoute,
                selectedRouteData,
                selectedRouteStops,
                selectedStopData,
                reachedDestination,
                showFooterList,
                detailsPageData
            } = state;

            let newClosestDistance = closestDistance;
            let newInBoundaries = inBoundaries;
            let newSelectedRouteData = selectedRouteData && {...selectedRouteData};
            let newSelectedRouteStops = selectedRouteStops && [...selectedRouteStops];
            let newSelectedStopData = selectedStopData && {...selectedStopData};
            let newReachedDestination = reachedDestination;
            let newDetailsPageData = detailsPageData && {...detailsPageData};

            // get the distance since the last user position
            const distance = getDistance(lastUpdatedPosition,userPosition);

            if(!lastUpdatedPosition) {
                // no previous user position
                if (routeDataLoaded) {
                    // only need to do this once for now, it's only used to detect if the user is close enough to display the user button
                    newClosestDistance = getClosestDistance(userPosition, routeData);
                    newInBoundaries = closestDistance < BOUNDARIES_DISTANCE
                }
            }

            const doDistanceUpdate = distance > 5;
            let newLastUpdatedPosition = lastUpdatedPosition;

            // if they are walking a route, and have moved more than 5 meters, do a bunch of stuff
            if((!lastUpdatedPosition || doDistanceUpdate) && isWalkingRoute) {

                newSelectedRouteData = { ...selectedRouteData, ...getStopDistanceAndMinutes(selectedRouteData, userPosition)};


                if (selectedStopData) {
                    newSelectedStopData = { ...selectedStopData, ...getStopDistanceAndMinutes(selectedStopData, userPosition)};
                }

                newReachedDestination = calculateReachedDestination(selectedRouteData, selectedStopData);
                newLastUpdatedPosition = userPosition;
            }
            
            // if they are on a route, and have the footer menu open, recalculate the distance of the route stops, but not as often
            if (doDistanceUpdate && showFooterList && isWalkingRoute) {

                newSelectedRouteStops = getSelectedRouteStopsWithDistances(selectedRouteData, routeStops, userPosition);
            }
            
            // if they are looking at a stop details page, recalculate the distance of the selected stops but not as often
            if (doDistanceUpdate &&  detailsPageData) {

                newDetailsPageData = { ...detailsPageData, ...getStopDistanceAndMinutes(detailsPageData, userPosition)};
            }

            return {
                ...state,
                userPosition,
                lastUpdatedPosition: newLastUpdatedPosition,
                accuracy,
                closestDistance: newClosestDistance,
                inBoundaries: newInBoundaries,
                selectedRouteData: newSelectedRouteData,
                selectedRouteStops: newSelectedRouteStops,
                selectedStopData: newSelectedStopData,
                reachedDestination: newReachedDestination,
                detailsPageData: newDetailsPageData,
                renderTS: new Date().getTime()
            };
        }
        case types.SHOW_DETAILS_PAGE: {
            let { detailsPageData } = action;
            let { userPosition } = state;

            if (detailsPageData) {
                detailsPageData = { ...detailsPageData, ...getStopDistanceAndMinutes(detailsPageData, userPosition)};
            }

            return {
                ...state,
                detailsPageData
            }
        }
        case types.SHOW_FOOTER_LIST: {

            const { showFooterList } = action;
            const { selectedRouteData, routeStops, userPosition, isWalkingRoute } = state;

            let selectedRouteStops = [];

            if (isWalkingRoute) {
                // if the user is curently walking a route, the stop list will show the route stops
                selectedRouteStops = getSelectedRouteStopsWithDistances(selectedRouteData, routeStops[selectedRouteData.slug], userPosition);
            }

            return {
                ...state,
                showFooterList,
                selectedRouteStops,
            }
        }
        
        case types.SET_MAP_TO_POINT: {

            const { point } = action;

            const { userPosition } = state;

            const region = getRegionWithUserPosition(point, userPosition);

            return {
                ...state,
                region,
                showFooterList: false,
                detailsPageData: null,
                ...updateAnimationSettings(action)
            }
        }
        case types.EXIT_WALKING_ROUTE: {
            return {
                ...state,
                showFooterList: false,
                detailsPageData: null,
                selectedRouteData: null,
                selectedStopData: null,
                isWalkingRoute: false,
                renderTS: new Date().getTime()
            };
        }
        case types.CENTER_MAP_ON_USER: {

            const { userPosition, selectedRouteData } = state;

            // consider a way to center the map on the user, but still have the selected stop in the map
            // the first attempt at this yielded

            // if (state.isWalkingRoute) {
            //     if(state.selectedStop) {
            //         newRegion = getRegionWithUserPosition(state.selectedStop.latlng, state.userPosition, true);
            //     } else {
            //         newRegion = getRegionWithUserPosition(state.selectedRoute.latlng, state.userPosition, true);
            //     }
            // } else {
            //     newRegion = getRegionWithUserPosition(state.userPosition);

            // }

            const newRegion = getRegionWithUserPosition(userPosition, userPosition, selectedRouteData.viewportSize);

            return {
                ...state,
                region: newRegion,
                ...updateAnimationSettings()
            };
        }
        case types.URI_CHANGED: {
            const { routeSlug,
                stopSlug,
                placeSlug,
                details,
                offlineMode
            } = action.uriState;
            const { routeData, routeStops, places, userPosition } = state;

            let walkingRouteData = {
                selectedRouteData: null,
                selectedStopData: null,
                isWalkingRoute: false,
                renderTS: new Date().getTime()
            };

            if (routeSlug && routeStops[routeSlug] ) {
                // edge case: page was loaded on a place details page, and the user hit back to a route in the url
                // this will be handled by the route data api, do nothing here
                walkingRouteData = getWalkingRouteData({routeSlug, stopSlug, userPosition, routeData, routeStops, places});
            }

            let detailsPageData;
            if (details) {
                if (placeSlug) {
                    const place = places.find(place => place.slug === placeSlug)    
                    detailsPageData = {...place, color: colors.place}
                } else {
                    detailsPageData = getDetailsPageDataFromWalkingRouteData({walkingRouteData});
                }
            }

            return {
                ...state,
                ...walkingRouteData,
                detailsPageData,
                offlineMode,
                renderTS: new Date().getTime()
            };

        }
        default:
            return state;
    }
};