export const colorNames = {
    lightBlue: "#eaf5f4",
    darkBlue: "#131c2f",
    seaBlue: "#b3dbcf",
    parksGreen: "#287773",
    landBlue: "#131c2f",
    landLight: "#e8f2f6",
    placePink: "#F15946",
    highlightYellow: "#f2b73c",
    actionOrange: "#FF9A03",
    actionGreen: "#1BC860",
    actionRed: "#DF0505",

    white: "#ffffff",
    black: "#000000",

    userBlue: "#30C0D3"
}

export const colors = {

    text: colorNames.black,
    headerColor: colorNames.darkBlue,
    subtitleColor: "#777",

    headerGrey: "#777",

    bg: colorNames.white,
    lightBG: colorNames.lightBlue,
    glassBG: "#287773BF",

    place: colorNames.placePink,

    userPosition: colorNames.userBlue,
    userActions: colorNames.actionOrange,

    "route-gastown": "#E9BE30",
    "route-chinatown": "#DCABDF",
    "route-coal-harbour": "#53B3CB",
    "route-stanley-park-seawall": "#2B4162",
    "route-stanley-park-inner": "#A4036F",
    "route-bc-entertainment-hof": "#800020",
    // "route-police-museum-strathcona": "#084C61", // this one is too dark against the dark blue bg
    "route-police-museum-strathcona": "#A03E99",

    // other colors that might work: #F15946, #E01A4F, #721817, #2B4162, #A4036F
}

export const getRouteColor = (routeSlug) => {
    return colors[`route-${routeSlug}`]
}

export const defaultText = {
    fontFamily: "regular"
}

export const fullscreenView = {
    backgroundColor: colors.bg,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
}

export const screenContainer = {
    width: "100%",
    maxWidth: 800,
    marginHorizontal: "auto",
};

export const flexCenter = {
    justifyContent: "center",
    alignItems: "center"
}

export const viewRow = {
    flexDirection: "row",
    alignItems: "center"
}

export const dropShadow = (width = 0, height = 4, shadowRadius = 10, shadowOpacity = 0.45) => {
    return {
        shadowOffset: {
            width,
            height,
        },
        shadowRadius,
        shadowOpacity,
        elevation: 10
    }
};

export const textStyles = {
    centerText: {
        alignItems: "center"
    },
    header: {
        fontSize: 20,
        fontFamily: "bold",
        marginBottom: 10, 
        color: colorNames.parksGreen,
        textAlign: "center"
    },
    subHeader: {
        fontSize: 16,
        color: "#000",
        marginBottom: 10
    },
}