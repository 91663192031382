import React from "react";
import { Text, View, TouchableWithoutFeedback } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { getLangTerm } from "lang";
import {
    textStyles
} from "styles/shared";
import { settingsStyles } from "./styles/settingsStyles";
import { openURL } from "utils/sharedActions";
import { colorNames } from "../../../styles/shared";

const ContactUs = () => {

    const lang = useSelector(state => state.settings.lang);
    const dispatch = useDispatch();

    const openEmailLink = () => {
        openURL({ url: "mailto:info@vanwalks.ca", target: "mailto-contact"}, dispatch);
    };

    return <><Text style={{...textStyles.header, marginTop: 20, paddingTop: 20, borderTopColor: colorNames.parksGreen, borderTopWidth: 1}}>
        { getLangTerm("contact-us", lang) }
    </Text>
    <Text style={{...textStyles.subHeader}}>
        { getLangTerm("hear-from-you", lang) }
    </Text>
    <View style={settingsStyles.emailContainer}>
        <View style={settingsStyles.emailLabel}>
            <Text style={settingsStyles.emailLabelText}>{ getLangTerm("email", lang) }:</Text>
        </View>
        <TouchableWithoutFeedback onPress={openEmailLink}>
            <Text style={settingsStyles.emailLink}>info@vanwalks.ca</Text>
        </TouchableWithoutFeedback>
    </View>
    </>
};

export default ContactUs;