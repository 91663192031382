import { types } from "../types";

const initialState = {
    isSubmitting: false,
    didSubmit: false,
    submitError: false
};

export const mailingListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MAILING_LIST_SIGNUP: {
            return { ...state, isSubmitting: true, didSubmit: false, submitError: false };
        }
        case types.MAILING_LIST_SIGNUP_SUCCESS: {
            return { ...state, isSubmitting: false, didSubmit: true };
        }
        case types.MAILING_LIST_SIGNUP_FAILURE: {
            return { ...state, isSubmitting: false, didSubmit: false, submitError: true };
        }
        default:
            return state;
    }
};