import React from "react";
import {
    View,
    Text,
    Image
} from "react-native";

import { getLangTerm } from "lang";

const HowTo = ({aboutStyles, lang, imageWidth}) => {

    let gastownStartHeight = imageWidth * 0.75
    let waterfrontStationHeight = imageWidth * 0.419

    let walkingHeaderWidth = imageWidth;
    const walkingHeaderMaxWidth = 800;
    if (walkingHeaderWidth > walkingHeaderMaxWidth) {
        walkingHeaderWidth = walkingHeaderMaxWidth
    }
    const walkingHeaderHeight = walkingHeaderWidth * 0.191

    let waterfrontStationPageWidth = imageWidth;
    const waterfrontStationPageMaxWidth = 200;
    if (waterfrontStationPageWidth > waterfrontStationPageMaxWidth) {
        waterfrontStationPageWidth = waterfrontStationPageMaxWidth
    }
    const waterfrontStationPageHeight = waterfrontStationPageWidth * 2.165;

    return <>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-select-route", lang) }
        </Text>
        <View style={aboutStyles.routeButton}>
            <Text style={aboutStyles.routeText}>
                { getLangTerm("start-this-route", lang) }
            </Text>
        </View>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-route-start", lang) }
        </Text>
        <Image
            source={require('../../../../assets/howto/GastownStart.jpg')}
            style={{
                ...aboutStyles.howToImage,
                width: imageWidth,
                height: gastownStartHeight
            }}
            resizeMode="contain"
        />
        <Text style={aboutStyles.howToSubStep}>
            { getLangTerm("how-to-enjoy-at-home", lang) }
        </Text>
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-next-prev", lang) }
        </Text>
        <Image
            source={require('../../../../assets/howto/WalkingHeader.jpg')}
            style={{
                ...aboutStyles.howToImage,
                width: walkingHeaderWidth,
                height: walkingHeaderHeight
            }}
            resizeMode="contain"
        />
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-stop-name", lang) }
        </Text>
        <Image
            source={require('../../../../assets/howto/WaterfrontStation.jpg')}
            style={{
                ...aboutStyles.howToImage,
                width: imageWidth,
                height: waterfrontStationHeight
            }}
            resizeMode="contain"
        />
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-enjoy", lang) }
        </Text>
        <Image
            source={require('../../../../assets/howto/WaterfrontStationPage.jpg')}
            style={{
                ...aboutStyles.screenshot,
                width: waterfrontStationPageWidth,
                height: waterfrontStationPageHeight,
            }}
            resizeMode="contain"
        />
        <Text style={aboutStyles.howToStep}>
            { getLangTerm("how-to-exit", lang) }
        </Text>
        <View style={aboutStyles.exitRouteButton}>
            <Text style={aboutStyles.exitRouteText}>
                { getLangTerm("exit-route", lang) }
            </Text>
        </View>
    </>
}

export default HowTo;