import * as FileSystem from 'expo-file-system';

import { fancylog } from "utils/fancylog";

const logLocal = false;
fancylog.log("fileSystem logLocal", logLocal);

export const getImagesFilename = (timestamp) => {
    return `images-${timestamp}.txt`
}

export const getAudioFilename = (timestamp) => {
    return `audio-${timestamp}.txt`
}

export const saveJsonFile = async(fileName, fileContents) => {
    await saveFile(fileName, JSON.stringify(fileContents));
}

export const saveFile = async(fileName, fileContents) => {
    fancylog.log("saving " + fileName, logLocal);
    try {
        await FileSystem.writeAsStringAsync(`${FileSystem.documentDirectory}${fileName}`, fileContents);
    } catch (e) {
        fancylog.log(`data file ${fileName} couldn't be created`, true);
    }
}

export const loadJsonFile = async(fileName) => {
    fancylog.log("loading file " + fileName, logLocal);
    
    const file = await loadFile(fileName);
    let jsonFile;
    if (file) {
        try {
            jsonFile = JSON.parse(file);
        } catch (e) {
            fancylog.log(`data file ${fileName} was not JSON`, true);
        }
    }
    return jsonFile;
}

export const loadFile = async(fileName) => {
    try {
        return await FileSystem.readAsStringAsync(`${FileSystem.documentDirectory}${fileName}`);
    } catch (e) {
        fancylog.log(`data file ${fileName} couldn't be loaded`, true);
    }
}