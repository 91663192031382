import React from 'react';
import { ActivityIndicator, Dimensions, Text, View, Image } from 'react-native';
import { useSelector } from "react-redux";

import { colors } from "styles/shared";
import { getLangTerm } from "lang";
import { isWeb } from "utils/platform";

const win = Dimensions.get('window');
const iconRatio = win.width / 884;

const loadingStyles = {
    container: {
        backgroundColor: colors.lightBG,
        zIndex: 9999,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        width: "100%",
        height: 990 * iconRatio
    },
    logoWeb: {
        width: "100%",
        maxWidth: 500,
        height: 990 * iconRatio,
        maxHeight: 500
    },
    header: {
        fontFamily: "light",
        fontSize: 24,
        color: colors.headerColor,
        marginBottom: 10
    },
    icon: {
        marginVertical: 20
    },
    status: {
        color: colors.headerColor,
    }
};

const Loading = () => {

    const timestampLoaded = useSelector(state => state.timestamp.loaded);
    const routeDataLoaded = useSelector(state => state.mapData.routeDataLoaded);
    const placesLoaded = useSelector(state => state.mapData.placesLoaded);
    const lang = useSelector(state => state.settings.lang);

    if (!timestampLoaded || !routeDataLoaded || !placesLoaded) {
        return <View style={loadingStyles.container}>
            <Image
                source={require('../../assets/VanWalksLanding.png')}
                style={isWeb ? loadingStyles.logoWeb : loadingStyles.logo}
                resizeMode="contain"
            />
            <ActivityIndicator size="large" color="#287773" style={loadingStyles.icon} />
            <Text style={loadingStyles.status}>
                {getLangTerm("checking-for-updates", lang)}
            </Text>
        </View>
    }
    return null;
}
export default Loading;