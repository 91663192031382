import React from 'react';
import { useSelector } from "react-redux";
import { Animated, Text, View, TouchableOpacity, Image } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { routesPageStyles } from "./styles/routesPageStyles";
import { Gear } from "../../SVGs";
import { getLangTerm } from "lang";
import RoutesList from './RoutesList';
import AudioLanguageWarning from '../../shared/AudioLanguageWarning';
import LocationNotice from '../../shared/LocationNotice';
import UnlockedAccessCodes from './UnlockedAccessCodes';
import { isWeb } from "utils/platform";

const RoutesPage = ({ toggleShowSettingsPage, toggleShowAboutPage }) => {
    const insets = useSafeAreaInsets();

    const lang = useSelector(state => state.settings.lang);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);

    if (selectedRouteData) {
        return null;
    }

    return <View style={{
        ...routesPageStyles.container,
        paddingTop: insets.top
    }}>
        <Animated.ScrollView
            style={routesPageStyles.scrollView}
            scrollEventThrottle={1}
        >
            <View style={routesPageStyles.screenContainer}>
                <View style={routesPageStyles.iconsContainer}>
                    <TouchableOpacity
                        onPress={toggleShowSettingsPage}
                    >
                        <View>{ /* View is required here or no onPress fires */ }
                            <Gear style={routesPageStyles.gear} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={toggleShowAboutPage}
                    >
                        <View style={routesPageStyles.questionMarkContainer}>
                            <Text style={routesPageStyles.questionMarkText}>?</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={routesPageStyles.header}>
                    <Image
                        source={require('../../../../assets/VanWalksLogo.png')}
                        style={routesPageStyles.logo}
                        resizeMode="contain"
                    />
                    <View>
                        <Text style={routesPageStyles.title}>{ getLangTerm("welcome-to-vanwalks", lang) }</Text>
                    </View>
                    <View style={routesPageStyles.subtitle}>
                        <Text style={routesPageStyles.subtitle}>{ getLangTerm("select-a-route", lang) }</Text>
                    </View>
                </View>
                {
                    !isWeb && 
                    <View style={{marginHorizontal: 10}}>
                        <View style={routesPageStyles.warningContainer}>
                            <Text style={routesPageStyles.warningText}>{ getLangTerm("in-app-purchases-warning", lang) }</Text>
                        </View>
                    </View>
                }
                <LocationNotice />
                <UnlockedAccessCodes />
                <AudioLanguageWarning />
                <RoutesList />
            </View>
        </Animated.ScrollView>
    </View>
}
export default RoutesPage;