import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Text, View, TouchableWithoutFeedback, StyleSheet, ActivityIndicator } from 'react-native';
import { formatProductTitle, getRouteButtonTextColor } from "utils/purchases";

import { types } from "redux/types";
import { colorNames } from "styles/shared";
import { getLangTerm } from "lang";
import { getRouteIsPurchased } from "utils/purchases";
import { getRouteColor } from '../../styles/shared';
import { isWeb } from 'utils/platform';
import { openURL } from "utils/sharedActions";

const buttonStyles = StyleSheet.create({
    premiumContainer: {
        display: "flex",
        alignItems: "center",
        paddingHorizontal: 30
    },
    premiumContainerInner: {
        width: "100%",
        maxWidth: 600,
    },
    offeringContainer: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        marginBottom: 5,
        borderRadius: 15,
        backgroundColor: "#C4C4C444"
    },
    premiumTitle: {
        fontFamily: "bold",
        fontSize: 16,
        marginBottom: 5
    },
    premiumDescription: {
        fontSize: 13,
        marginBottom: 7
    },
    purchaseButton: {
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 15,
    },
    premiumPrice: {
        fontSize: 16,
        color: "#000",
        textAlign: "center"
    },
    bold: {
        fontFamily: "bold"
    },
    makingPurchaseContainer: {
        paddingHorizontal: 10,
        paddingVertical: 5,
        display: "flex",
        alignItems: "center"
    },
    purchaseErrorContainer: {
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginBottom: 5,
        borderWidth: 2,
        borderColor: colorNames.actionRed,
        borderRadius: 15,
        display: "flex",
        alignItems: "center"
    },
    webLinkButton: {
        flexDirection: "row",
        backgroundColor: colorNames.actionOrange,
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginHorizontal: "auto",
        marginBottom: 10,
        cursor: "pointer"
    },
    webLinkButtonText: {
        color: "#FFFFFF",
        fontFamily: "bold",
        fontSize: 16,
        textAlign: "center",
        cursor: "pointer"
    },
});

const PurchaseButtons = ({ route, routeSlug}) => {

    const lang = useSelector(state => state.settings.lang);
    const routeData = useSelector(state => state.mapData.routeData);
    const storeOfferings = useSelector(state => state.purchases.offerings);
    const offeringsLoadedError = useSelector(state => state.purchases.offeringsLoadedError);
    const purchases = useSelector(state => state.purchases.purchases);
    const makingPurchase = useSelector(state => state.purchases.makingPurchase);
    const purchaseError = useSelector(state => state.purchases.purchaseError);
    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);

    if (routeSlug) {
        // this is if the purchase button is displayed on a stop page
        // the stop doesn't have any purchase data, get it from the parent route
        route = routeData.filter(route => route.slug == routeSlug)[0];
    }

    const { offerings, slug, isPremium, published } = route;
    const color = getRouteColor(route.slug);

    const dispatch = useDispatch();
    const purchaseRoute = (purchasePackage) => {
        dispatch({
            type: types.PURCHASE_ROUTE,
            purchasePackage,
            routeSlug: route.slug
        });
    }

    if (!isPremium || !published) {
        return null;
    }

    const isPurchased = getRouteIsPurchased({ route, purchases, staticAccessCodes, purchasedAccessCodes });
    const buttonColor = getRouteButtonTextColor(slug);

    const openPurchaseLink = () => {

        // make a plausible pageview here
        if (typeof plausible !== 'undefined') {
            // eslint-disable-next-line no-undef
            plausible('PurchaseButtonClick', {props: {route: slug}})
        }

        openURL({ url: `https://vanwalks.ca/${ lang !== "en" ? `${lang}/` : "" }routes/${slug}`, target: `web-purchase-${slug}`}, dispatch)
    }

    if (isPurchased) {
        return null;
    }

    if (isWeb) {
        return <View style={{
            ...buttonStyles.premiumContainer
        }}>
            <View style={ buttonStyles.premiumContainerInner }>
                {/* <View style={buttonStyles.offeringContainer}>
                    <Text style={buttonStyles.premiumTitle}>{ getLangTerm("purchase-web-soon", lang) }</Text>
                    <Text style={buttonStyles.premiumDescription}>{ getLangTerm("purchase-web-beta", lang) }</Text>
                </View> */}
                <TouchableWithoutFeedback onPress={() => openPurchaseLink()}>
                    <View style={{
                        ...buttonStyles.webLinkButton,
                        backgroundColor: color
                    }}>
                        <Text style={{
                            ...buttonStyles.webLinkButtonText,
                            color: buttonColor
                        }}>{ getLangTerm("view-purchase-info", lang) }
                        </Text>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        </View>
    }

    return <View style={{
        ...buttonStyles.premiumContainer
    }}>
        <View style={ buttonStyles.premiumContainerInner }>
            { purchaseError && <View style={buttonStyles.purchaseErrorContainer}>
                <Text>{ getLangTerm("purchase-error", lang) }</Text>
            </View> }
            { offeringsLoadedError && <View style={buttonStyles.purchaseErrorContainer}>
                <Text>{ getLangTerm("offerings-error", lang) }</Text>
            </View> }
            { makingPurchase === route.slug && <View style={buttonStyles.makingPurchaseContainer}>
                <Text>{getLangTerm("purchasing", lang)}</Text>
                <ActivityIndicator size="large" color={color} style={buttonStyles.activityIcon} />
            </View> }
            { makingPurchase !== route.slug && storeOfferings && offerings && offerings.map(offering => {
                const storeOffering = storeOfferings[offering];

                if (storeOffering) {
                    const purchasePackage = storeOffering.availablePackages[0];
                    const product = purchasePackage.product;
                    return <View key={offering} style={buttonStyles.offeringContainer}>
                        <Text style={buttonStyles.premiumTitle}>{ formatProductTitle(product.title) }</Text>
                        { product.title.indexOf("Bundle") > -1 && <Text style={buttonStyles.premiumDescription}>{ product.description }</Text> }
                        <TouchableWithoutFeedback key={offering} onPress={() => purchaseRoute(purchasePackage)}>
                            <View style={{
                                ...buttonStyles.purchaseButton,
                                backgroundColor: color
                            }}>
                                <Text style={{
                                    ...buttonStyles.premiumPrice,
                                    color: buttonColor
                                }}>{ getLangTerm("purchase", lang) } <Text style={{
                                        ...buttonStyles.bold,
                                        color: buttonColor
                                    }}>{ product.priceString } { product.currencyCode }</Text></Text>
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                }
            })}
        </View>
    </View>
}
export default PurchaseButtons;