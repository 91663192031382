export const en = {
    "min-away": "{0} min away",
    "mins-away": "{0} mins away",
    "2-hours-away": "2+ hours away",
    "hours": "hours",
    "routes": "Routes",
    "stops": "Stops",
    "show-route-stops": "Show Route Stops",
    "near-me": "Near Me",
    "back-to-map": "Back To Map",
    "featured-in": "Featured In",
    "audio-loading": "Loading Audio Content",
    "start-here": "Start Here",
    "walk-here-to-start": "Walk Here To Start",
    "you-are-here": "You are here!",
    "press-for-details": "Press for details",
    "press-next-stop": "Press Next to Continue",
    "finished-route": "Finished Route!",
    "walk-to": "Walk To",
    "start": "Start",
    "next": "Next",
    "previous": "Previous",
    "start-this-route": "Start This Route",
    "preview-this-route": "Preview This Route",
    "offline-mode-button": "View in Offline Mode",
    "show-on-map": "Show on Map",
    "make-next-stop": "Make Next Stop",
    "switch-to-this-route": "Switch To This Route",
    "get-directions-in-google-maps": "Get Directions in Google Maps",
    "exit-route": "Exit Route",
    "welcome-to-vanwalks": "Welcome to VanWalks",
    "select-a-route": "Select a route to get started.",

    "left-turn": "Turn left",
    "right-turn": "Turn right",
    "on": " on",
    "cross": "Cross ",
    "cross-both": "Cross Both Streets",
    "traffic": "Watch for Traffic",
    "bikes": "Watch for Bikes",
    "down-stairs": "Down the Stairs",
    "fish-traps": "Indigenous Fish Traps",
    "zig-zags": "Zig zags are approximate",
    "high-five-point": "High Five Point",
    "hollow-tree": "Hollow Tree",
    "traffic-bikes": "Watch for Traffic and Bikes",
    "bamboo-grove": "Bamboo Grove",
    "chehalis-cross": "Chehalis Cross",
    "north-shore-mountains": "North Shore Mountains Lookout",
    "shakespeare": "Shakespeare Statue",
    "raven-spirit-of-transformation": "Raven: Spirit of Transformation",

    "checking-for-updates": "Checking for Updates",
    "fetching-route-data": "Fetching Route Data",
    "loading-cached-images": "Loading Cached Images",
    "building-map": "Building Map",
    "routes-loading-error": "There was an error loading the routes.",
    "route-loading-error": "There was an error loading the route.",

    "welcome-header": "Welcome to VanWalks!",
    "follow-us": "Follow us for all our adventures and updates!",
    "on-the-web": "Check out VanWalks on the web:",
    "contact-us": "Contact Us",
    "hear-from-you": "We want to hear from you! Feel free to send us comments and feedback.",
    "email": "Email",
    "sign-up-for-mailing-list": "Sign up for our mailing list",
    "wont-spam": "We promise we won't spam you.",
    "email-is-required": "Valid email is required.",
    "first-name": "First Name",
    "last-name": "Last Name",
    "sign-up": "Sign Up",
    "signup-error": "There was an error with your sign up. Please try again.",
    "signup-thanks": "Thanks! We'll send you updates about the Vanwalks app and new content.",
    "purchases": "Purchases",
    "in-app-purchases": "In-App Purchases",
    "in-app-purchases-warning": "VanWalks is migrating to our web based platform at map.vanwalks.ca. Please contact VanWalks to transfer an in-app purchase.",
    "in-app-purchases-web": "VanWalks doesn't use in-app purchases anymore. If you have an old in-app purchase please contact VanWalks to transfer here.",
    "access-codes": "Access Codes",
    "access-code-description": "Enter your Access Code to unlock premium content. Enter your Email Address to receive your access codes.",
    "submit": "Submit",
    "enter-access-code-or-email": "Enter Access Code or Email",
    "redemption-limit-reached": "You have reached the redemption limit for this access code.",
    "already-redeemed": "This access code has already been redeemed in this browser. Content is unlocked.",
    "access-code-redemption-success": "Access Code has been redeemed. Content has been unlocked.",
    "access-codes-emailed": "Access codes have been emailed to you. Please check your email.",
    "valid-access-code": "Access Code is valid. Content has been unlocked",
    "invalid-access-code": "Invalid Access Code",
    "inactive-access-code": "Access Code is inactive.",
    "no-access-codes-found": "Invalid access code or email address. Please try again.",
    "error-loading-access-codes": "There was an error loading your access codes.",
    "generic-error": "There was an error. Please try again or contact VanWalks.",
    "premium-content-unlocked": "Premium Content Unlocked",
    "access-code-error": "There was an error with an access code. Check the settings menu.",
    "access-code-error-details": "These access codes had an error:",
    "enjoy": "Enjoy!",
    "unlocks": "Unlocks",
    "view-purchase-info": "View Purchase Info",
    "coming-soon": "Coming Soon",

    "location-services": "Location Services",
    "location-header": "VanWalks is better if you enable your location.",
    "location-subheader": "Your phone will ask for permissions next.",
    "update-location-settings": "Location preference can be changed on the settings page.",
    "location-is-enabled": "Location is enabled. To conserve battery, you can turn it off.",
    "allow": "Allow",
    "turn-off": "Turn Off",
    "location-error": "There was an error with location services. Please check in your device settings that this browser is allowed to access location services.",

    "chris-intro": "Hi! I'm Chris King, creator of VanWalks. I've always felt that the best way to explore a city is on foot. I've wandered around some of the greatest cities in the world! Now it's my turn to share my home with the world! Twenty years of playing in bands, working odd jobs, biking to softball, and general wandering has taken me to many nooks and crannies of this great city. I've met artists, musicians, neurosurgeons, billionaires and students. I've assembled these routes to give a great introduction to the city I love, my home, Vancouver.",
    "how-to-use": "How to use VanWalks",
    "how-to-select-route": "Select a route from the list",
    "how-to-route-start": "Make your way to the route start",
    "how-to-enjoy-at-home": "Routes can also be enjoyed from the comfort of your home",
    "how-to-next-prev": "Use the Next and Previous arrows to navigate through the route",
    "how-to-stop-name": "Tap on the stop name to learn more about each location",
    "how-to-enjoy": "Enjoy!",
    "how-to-exit": "Tap Exit Route to get back to the route menu",
    "tips-for-success": "Tips for Success",
    "location": "Location",
    "location-body": "Enabling location is a great way to get the most out of VanWalks, but there are some downsides. Even in a high tech place like Vancouver, your phone location is often an approximation. Signals are bouncing around buildings and your phone may be switching between different towers and hotspots. Even in Google Maps, you may find you may find your location across the street or inside a building. It’s best to also use the street names and intersections to make sure you are in the right spot.",
    "battery": "Battery",
    "battery-body": "Any app that displays your location on a map will use a lot of battery. Even apps with huge budgets like Google Maps or Pokemon Go use a lot of battery. VanWalks does everything we can to reduce battery use, but we still recommend using an external battery pack. When you are on a long stretch of the route, you can make a note of where the next stop or turn is and turn your screen off. You can save battery and enjoy the city at the same time!",
    "data": "Data",
    "data-body-1": "VanWalks works best with a data connection. Luckily, the routes are heavily optimized to not use much data. A whole VanWalks route uses less data than accidentally opening Instagram.",
    "data-body-2": "We also have Offline Mode. You can download the route in advance using wifi, then enjoy without a data connection using static maps. You can also use Offline Mode to save data even with a data connection.",
    "data-body-3": "The City of Vancouver also offers a free Wifi network. It might be a bit spotty, but it’s plenty for VanWalks’ data needs. Many of the big chain restaurants offer wifi, but that doesn’t mean you have to eat there.",
    "city-of-vancouver-wifi-info": "City of Vancouver Wifi Info",
    "traffic-tip": "Traffic",
    "traffic-body-1": "If you are from Europe, you may notice how large and inefficient North American vehicles are. Our large “light duty” trucks are especially problematic. These trucks often have hoods that are as tall as a full grown person and they are more likely to kill or injure people. Please pay attention to the traffic while walking around and follow the signals. In Vancouver, cars are also very likely to take a left turn on a yellow light, long after the light has changed, and they often aren’t checking for pedestrians in their way. Lastly, don’t blindly follow people who jump the lights, they may be prepared to dodge between the cars, but if you casually start crossing the street after them, you might be walking into the path of a car.",
    "traffic-body-2": "Having said that, downtown Vancouver is also one of the most accessible places for pedestrians and cyclists. Many visitors from other places in North America are surprised to learn that over 50% of trips in Vancouver are done without a car. Vancouver is a great example of how mobile a city can be when you don’t prioritize cars over everything else.",

    "premium-route": "Premium Route",
    "free-route": "Free Route",
    "this-is-a-premium-route": "is a Premium Route",
    "purchase": "Purchase",
    "purchasing": "Purchasing",
    "error-loading-purchases": "There was an error loading your purchases.",
    "purchase-error": "There was an error making your purchase.\nPlease try again.",
    "purchase-successful": "||title|| purchase was successful",
    "show-purchase-information": "Show Purchase Information",
    "no-purchases": "You don't have any purchased routes.",
    "missing-purchases": "If you are missing a purchase, try 'restore purchases'.",
    "restore-purchases": "Restore Purchases",
    "purchases-restored": "Your purchases have been restored",
    "purchases-restore-error": "There was an error restoring your purchases",
    "offerings-error": "There was an error loading premium offerings.",
    "visitor-information": "Visitor Information",
    "and-more": "& More",

    "vanwalks-offline-mode" : "VanWalks Offline Mode",
    "offline-desc": "Enable Offline Mode when connected to wifi. This will allow you to use VanWalks without a data connection.",
    "data-saving-desc": "You can also use Offline Mode to save data while using the regular map. Download first with wifi to save data later.",
    "enable-offline-mode": "Enable Offline Mode - ||title||",
    "assets": "Assets",
    "verifying": "Verifying",
    "abort": "Abort",
    "offline-warning": "Please ensure you have a wifi connection when enabling offline mode. Vanwalks doesn't use much data, each route is about the same as accidentally opening Instagram.",
    "offline-mode-ready": "Offline Mode is ready to use for this route. Please test with airplane mode enabled before you leave wifi.",
    "offline-settings": "Offline Settings",
    "no-offline-downloads": "You don't have any offline downloads.",
    "offline-status-done": "Downloaded",
    "offline-status-aborted": "Cancelled",
    "reset-offline-status": "Reset Offline Status",
    "offline-notice": "Data connection is required to load the dynamic map. Try offline mode when you have wifi.",
}