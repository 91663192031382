export const routeMocks = {
    "gastown_route": {
        "serverDescription": "Gastown Route",
        "lifetime": {
            "offeringIdentifier": "gastown_route",
            "product": {
                "description": "A walking tour of Gastown, Vancouver",
                "introPrice": null,
                "price_string": "$5.99",
                "intro_price_period_unit": null,
                "currency_code": "USD",
                "title": "Gastown Premium Route",
                "price": 5.989999771118164,
                "intro_price": null,
                "identifier": "gastown_route",
                "intro_price_period_number_of_units": null,
                "intro_price_period": null,
                "intro_price_string": null,
                "discounts": [],
                "intro_price_cycles": null
            },
            "packageType": "LIFETIME",
            "identifier": "$rc_lifetime"
        },
        "availablePackages": [
            {
                "offeringIdentifier": "gastown_route",
                "product": {
                    "description": "A walking tour of Gastown, Vancouver",
                    "introPrice": null,
                    "price_string": "$5.99",
                    "intro_price_period_unit": null,
                    "currency_code": "USD",
                    "title": "Gastown Premium Route",
                    "price": 5.989999771118164,
                    "intro_price": null,
                    "identifier": "gastown_route",
                    "intro_price_period_number_of_units": null,
                    "intro_price_period": null,
                    "intro_price_string": null,
                    "discounts": [],
                    "intro_price_cycles": null
                },
                "packageType": "LIFETIME",
                "identifier": "$rc_lifetime"
            }
        ],
        "identifier": "gastown_route"
    },
    "stanleypark_bundle": {
        "serverDescription": "Stanley Park Bundle",
        "lifetime": {
            "offeringIdentifier": "stanleypark_bundle",
            "product": {
                "description": "Two Routes around Stanley Park in Vancouver",
                "introPrice": null,
                "price_string": "$9.99",
                "intro_price_period_unit": null,
                "currency_code": "USD",
                "title": "Stanley Park Bundle",
                "price": 9.989999771118164,
                "intro_price": null,
                "identifier": "stanleypark_bundle",
                "intro_price_period_number_of_units": null,
                "intro_price_period": null,
                "intro_price_string": null,
                "discounts": [],
                "intro_price_cycles": null
            },
            "packageType": "LIFETIME",
            "identifier": "$rc_lifetime"
        },
        "availablePackages": [
            {
                "offeringIdentifier": "stanleypark_bundle",
                "product": {
                    "description": "Two Routes around Stanley Park in Vancouver",
                    "introPrice": null,
                    "price_string": "$9.99",
                    "intro_price_period_unit": null,
                    "currency_code": "USD",
                    "title": "Stanley Park Bundle",
                    "price": 9.989999771118164,
                    "intro_price": null,
                    "identifier": "stanleypark_bundle",
                    "intro_price_period_number_of_units": null,
                    "intro_price_period": null,
                    "intro_price_string": null,
                    "discounts": [],
                    "intro_price_cycles": null
                },
                "packageType": "LIFETIME",
                "identifier": "$rc_lifetime"
            }
        ],
        "identifier": "stanleypark_bundle"
    },
    "vancouver_bundle": {
        "serverDescription": "Vancouver Bundle",
        "lifetime": {
            "offeringIdentifier": "vancouver_bundle",
            "product": {
                "description": "All the Vancouver Premium Routes",
                "introPrice": null,
                "price_string": "$24.99",
                "intro_price_period_unit": null,
                "currency_code": "USD",
                "title": "Vancouver Bundle",
                "price": 24.989999771118164,
                "intro_price": null,
                "identifier": "Vancouver Bundle",
                "intro_price_period_number_of_units": null,
                "intro_price_period": null,
                "intro_price_string": null,
                "discounts": [],
                "intro_price_cycles": null
            },
            "packageType": "LIFETIME",
            "identifier": "$rc_lifetime"
        },
        "availablePackages": [
            {
                "offeringIdentifier": "vancouver_bundle",
                "product": {
                    "description": "All the Vancouver Premium Routes",
                    "introPrice": null,
                    "price_string": "$24.99",
                    "intro_price_period_unit": null,
                    "currency_code": "USD",
                    "title": "Vancouver Bundle",
                    "price": 24.989999771118164,
                    "intro_price": null,
                    "identifier": "Vancouver Bundle",
                    "intro_price_period_number_of_units": null,
                    "intro_price_period": null,
                    "intro_price_string": null,
                    "discounts": [],
                    "intro_price_cycles": null
                },
                "packageType": "LIFETIME",
                "identifier": "$rc_lifetime"
            }
        ],
        "identifier": "vancouver_bundle"
    }
}