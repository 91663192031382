import React from "react";
import { ScrollView } from 'react-native';
import {
    wrapScrollView,
} from 'react-native-scroll-into-view';

const CustomScrollView = wrapScrollView(ScrollView);

const SettingsScrollView = ({children}) => {

    return <CustomScrollView>
        { children }
    </CustomScrollView>
};

export default SettingsScrollView;
