import {
    StyleSheet
} from "react-native";
import {
    colors,
    colorNames,
    fullscreenView,
    screenContainer
} from "styles/shared";

export const settingsStyles = StyleSheet.create({
    container: {
        ...fullscreenView,
        zIndex: 3,
        backgroundColor: "#ffffffbb",
    },
    containerInner: {
        ...screenContainer,
        flex: 1,
        alignItems: "center",
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
        // marginHorizontal: 8,
        marginBottom: 8,
        borderRadius: 25,
        backgroundColor: "#ffffff",
    },
    closeButton: {
        alignSelf: "flex-start",
        margin: 13
    },
    closeIcon: {
        color: colors.headerGrey
    },
    content: {
        maxWidth: 800,
        paddingHorizontal: 20,
        paddingBottom: 30
    },
    emailContainer: {
        flexDirection: "row",
    },
    emailLabel: {
        marginRight: 10
    },
    emailLabelText: {
        fontSize: 16,
    },
    emailLink: {
        fontSize: 16,
        color: colorNames.parksGreen,
    },
    signupContainer: {
        borderTopColor: colorNames.parksGreen,
        borderTopWidth: 1,
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
    signupHeader: {
        fontFamily: "bold",
        fontSize: 20,
        marginTop: 20,
        marginBottom: 5,
        color: colorNames.parksGreen,
    },
    signupSubHeader: {
        fontSize: 14
    },
    labelStyles: {
        color: colors.headerGrey,
    },
    labelRequired: {
        color: colorNames.actionRed
    },
    requiredWarning: {
        color: colorNames.actionRed,
        marginBottom: 10
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        backgroundColor: `#1D9CAD`,
        flexDirection: "row",
        alignItems: "center",
        height: 36,
        borderRadius: 55,
        paddingHorizontal: 20,
        marginTop: 20
    },
    buttonText: {
        color: "#FFF",
        fontFamily: "bold",
        fontSize: 16
    },
    error: {
        fontSize: 12,
        color: colorNames.actionRed,
        marginTop: 20
    },
    submitThanks: {
        fontSize: 18,
        fontFamily: "bold", 
        color: colorNames.parksGreen,
        marginTop: 20
    },
    puchasesHeader: {
        textAlign: "center",
        fontFamily: "bold",
        fontSize: 24,
        marginTop: 20,
        marginBottom: 5,
        color: colorNames.parksGreen,
    },
    purchaseContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10
    },
    purchaseIcon: {
        color: colorNames.parksGreen,
        marginLeft: 10,
        marginRight: 10
    },
    purchase: {
        fontSize: 16
    },
    restorePurchasesContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20
    },
    restorePurchasesButton: {
        backgroundColor: colorNames.parksGreen,
        marginTop: 10,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 55,
        display: "flex",
        flexDirection: "row"
    },
    restorePurchasesText: {
        color: "#fff",
        fontSize: 18,
        fontFamily: "bold"
    },
    offlineContainer: {
        borderTopColor: colorNames.parksGreen,
        borderTopWidth: 1,
        marginTop: 10,
        paddingTop: 10,
    },
    offlineHeader: {
        textAlign: "center",
        fontFamily: "bold",
        fontSize: 24,
        marginBottom: 5,
        color: colorNames.parksGreen,
    },
    offlineText: {
        textAlign: "center",
        fontSize: 16,
    },
    resetOfflineButton: {
        backgroundColor: colorNames.parksGreen,
        marginTop: 10,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderRadius: 55,
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        cursor: "pointer"
    },
    resetOfflineButtonText: {
        color: "#fff",
        fontSize: 16,
        fontFamily: "bold"
    },
    warningContainer: {
        marginBottom: 50,
        padding: 10,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.actionRed,
    },
    warningText: {
    }
});