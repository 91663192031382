import { en } from "./en";
import { fr } from "./fr";
import { fancylog } from "utils/fancylog";

export const langList = [
    {
        slug: "en",
        text: "English"
    },
    {
        slug: "fr",
        text: "Français"
    }
];

const terms = {
    "en": en,
    "fr": fr
}

export const getLangTerm = (term, lang) => {
    if (!terms[lang]) {
        fancylog.log(`MISSING LANG: term ${term}, lang ${lang}`, true);
        return "";
    }
    if (!terms[lang][term]) {
        fancylog.log(`MISSING LANG TERM: term ${term}, lang ${lang}`, true);
        return "";
    }
    return terms[lang][term];
}