import React from "react";
import { StyleSheet, View, Text, TouchableWithoutFeedback } from "react-native"
import { useDispatch, useSelector } from "react-redux";
import { Text as UIText } from '@ui-kitten/components';

import { getLangTerm } from "lang";
import { colorNames, colors } from "styles/shared";
import { types } from "redux/types";

const toggleStyles = StyleSheet.create({
    label: {
        color: colors.headerGrey,
        marginTop: 10,
        marginBottom: 2,
    },
    container: {
        borderWidth: 1,
        backgroundColor: "#FFFFFF",
        borderColor: colorNames.parksGreen,
        borderRadius: 15,
        paddingHorizontal: 10,
        paddingTop: 12,
        marginBottom: 10,
        display: "flex",
        alignItems: "center"
    },
    header: {
        color: "#000000",
        fontSize: 16,
        marginBottom: 12
    },
    button: {
        backgroundColor: colorNames.parksGreen,
        paddingHorizontal: 40,
        paddingVertical: 10,
        marginBottom: 12,
        borderRadius: 20
    },
    buttonText: {
        fontSize: 18,
        fontFamily: "bold",
        color: colorNames.white,
    },
    errorContainer: {
        marginBottom: 50,
        padding: 10,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.actionRed,
    },
    errorText: {
        fontSize: 16,
    }
});

const LocationToggle = () => {

    const lang = useSelector(state => state.settings.lang);
    const userLocationError = useSelector(state => state.user.locationLookupError);
    const locationPreference = useSelector(state => state.user.locationPreference);

    const dispatch = useDispatch();
    const toggleLocationPreference = () => {
        dispatch({
            type: types.USER_SET_LOCATION_PREFERENCE,
            locationPreference: !locationPreference,
            userActionData: { locationPreference: !locationPreference }
        });
    }

    const toggleHeaderText = locationPreference ? getLangTerm("location-is-enabled", lang) : getLangTerm("location-header", lang);
    const toggleButtonText = locationPreference ? getLangTerm("turn-off", lang) : getLangTerm("allow", lang);

    return <>
        <UIText category='label' style={toggleStyles.label}>{ getLangTerm("location-services", lang) }</UIText>
        <View style={toggleStyles.container}>
            <Text style={toggleStyles.header}>{ toggleHeaderText }</Text>
            <TouchableWithoutFeedback onPress={toggleLocationPreference}>
                <View style={toggleStyles.button}>
                    <Text style={toggleStyles.buttonText}>{ toggleButtonText }</Text>
                </View>
            </TouchableWithoutFeedback>
        </View>
        { userLocationError && <View style={toggleStyles.errorContainer}>
            <Text style={toggleStyles.errorText}>{ getLangTerm("location-error", lang) }</Text>
        </View> }
    </>
}

export default LocationToggle;