import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import * as FileSystem from 'expo-file-system';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isWeb } from "utils/platform";


import { getImagesFilename } from "utils/fileSystem";
import getEnvVars from 'environment';

const envVars = getEnvVars();

const DevMenu = () => {

    const imagesTimestamp = useSelector(state => state.timestamp.images);
    const accuracy = useSelector(state => state.mapData.accuracy);

    const triggerError = () => {
        throw new Error("Triggered error on purpose");
    }

    const deleteImageCache = async() => {
        // would need to loop through all the images and delete them
    }

    const deleteCacheData = async() => {
        await AsyncStorage.clear();
    }

    const unsetLocationPreference = async() => {
        await AsyncStorage.removeItem('@locationPreference');
    }

    const clearAccessCodes = async () => {
        await AsyncStorage.removeItem('@accessCodeList');
        await AsyncStorage.removeItem('@purchasedAccessCodeList');
    }

    const unsetUserID = async () => {
        await AsyncStorage.removeItem('@userUniqueID');
    }

    return <>
        <Text style={{fontSize: 8, marginTop:20, marginBottom: 5}}>
            { Updates.channel === "production" || isWeb ? "VanWalks" : Updates.channel } - {Constants.manifest.version} - b36
        </Text>
        <Text style={{fontSize: 8, marginTop:0, marginBottom: 20}}>
            Location Accuracy: { accuracy }
        </Text>
        { (envVars.env == "dev" || envVars.env == "staging") && <View>
            <Text>Dev Menu</Text>
            <Text style={{marginTop:20, marginBottom: 20}}>Channel: { Updates.channel }</Text>
            <TouchableOpacity onPress={triggerError}>
                <Text style={{marginTop:10}}>Trigger Error</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={deleteCacheData}>
                <Text style={{marginTop:20}}>Delete Cache Data</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity onPress={deleteImageCache}>
                <Text style={{marginTop:20}}>Delete Image Cache</Text>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={unsetLocationPreference}>
                <Text style={{marginTop:20}}>Unset Location Preference</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={unsetUserID}>
                <Text style={{marginTop:20}}>Unset User ID</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={clearAccessCodes}>
                <Text style={{marginTop:20}}>Clear Access Codes</Text>
            </TouchableOpacity>
        </View>}
    </>
};

export default DevMenu;