
import { types } from "../types";
import { getWalkingTime } from "utils/distance";

const initialState = {
    userPosition: null,
    locationLookupError: false,
    userPermissionsChecked: false,
    locationPreferenceChecked: false,
    locationPreference: null // starts as null before the user sets it to true or false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_SET_LOCATION_PREFERENCE:
        case types.USER_CHECKED_LOCATION_PREFERENCE:
            return {
                ...state,
                locationPreferenceChecked: true,
                locationPreference: action.locationPreference
            }
        case types.USER_LOCATION_ASK_SUCCESS:
            return { ...state,
                locationPermissionAsked: true,
                locationPermissionGranted: action.locationPermissionGranted
            };
        case types.USER_LOCATION_LOOKUP_FAIL:
            return { ...state, locationLookupError: true };

        case types.USER_LOCATION_CHANGE: {

            let { userPosition } = action;
            let { ...newState } = state;        

            const { distance } = getWalkingTime(newState.userPosition,userPosition);
            
            // always update the first time, after that only populate if the user has moved 5 meters
            if (!newState.userPosition || distance > 5) {
                newState.userPosition = userPosition;
            }

            return newState;
        }
        default:
            return state;
    }
};