import React from 'react';
import { useSelector } from "react-redux";
import { Text, View, TouchableWithoutFeedback } from 'react-native';

import { getLangTerm } from "lang";
import { isWeb } from 'utils/platform';

export const MarkerText = ({ title, isActive, onPress, routeMarkerStyles }) => {

    const lang = useSelector(state => state.settings.lang);
    const reachedDestination = useSelector(state => state.mapData.reachedDestination);
    let showText = true;

    // if (
    //   renderDistance > 10 ||
    //   (longitudeDelta > 0.004023313522353078 && renderDistance > 6)
    // ) {
    //   showText = false;
    // }

    let subHeader = null; //<Text style={routeMarkerStyles.subHeader}>{getLangTerm("start-here", lang)}</Text>;

    if (isActive) {
        // this is the selected route we are currently walking
        if (!reachedDestination) {
            subHeader = <Text style={routeMarkerStyles.subHeader}>{getLangTerm("walk-here-to-start", lang)}</Text>;
        } else  {
            subHeader = <Text style={routeMarkerStyles.subHeader}>{getLangTerm("you-are-here", lang)}</Text>;
        }
    }

    if (showText) {
        return <View style={ isWeb ? routeMarkerStyles.flyoutContainerWeb : routeMarkerStyles.flyoutContainer}>
            <TouchableWithoutFeedback onPress={onPress}>
                <View style={routeMarkerStyles.textContainer}>
                    <Text style={routeMarkerStyles.header}>{title}</Text>
                    { subHeader }
                </View>
            </TouchableWithoutFeedback>
        </View>
    }
}