import AsyncStorage from '@react-native-async-storage/async-storage';

import config from "./apiConfig";
import { UserActionDuration } from "@utils/userActions";

import { types } from "../types";

export const timestampAPI = () => next => action => {
    
    const timestampLookup = async() => {
        const uad = new UserActionDuration();
        
        const timestampKey = "@timestamp_data";
        let timestampData;

        const apiClient = config.getInstance();
        const get = () => apiClient.get("/timestamp");

        const res = await get().catch(e => {
            console.error("Error looking up timestamp");
            console.error(e);
        });
        
        if (!res || !res.data) {
            //check if there is a previously saved timestamp and use that
            try {
                const timestampDataRaw = await AsyncStorage.getItem(timestampKey);
                timestampData = JSON.parse(timestampDataRaw);
            } catch (e) {
                console.error(e, true);
                console.error("data file doesn't exist");
            }
        } else {
            timestampData = res.data;
        }

        if (!timestampData) {
            const duration = uad.duration;
            return next({
                type: types.TIMESTAMP_LOOKUP_FAILURE,
                duration
            });
        }

        try {
            await AsyncStorage.setItem(timestampKey, JSON.stringify(timestampData))
        } catch (e) {
            console.error(e, true);
            console.error("data couldn't be stored", true);
        }

        const duration = uad.duration;
        
        return next({
            type: types.TIMESTAMP_LOOKUP_SUCCESS,
            ...timestampData,
            duration
        });
    };

    next(action);
    switch (action.type) {
        case types.TIMESTAMP_LOOKUP: {
            timestampLookup();
            break;
        }
        default:
            break;
    }
};