import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { BaseMarker } from './BaseMarker';
import { isWeb } from 'utils/platform';

import { colorNames, getRouteColor } from 'styles/shared';
import { MarkerContext } from './MarkerContext';

const LandingPointMarker = ({ stop }) => {

    const { slug, zIndex = 1, landingPoint, routeID } = stop;

    const {
        getIsActive
    } = useContext(MarkerContext);

    let isActive = getIsActive(slug);

    const landingPointStyles = StyleSheet.create({
        position: {
            width: 14,
            height: 14,
            borderRadius: 7,
            borderWidth: 3,
            borderColor: isActive ? colorNames.actionGreen : getRouteColor(routeID),
            backgroundColor: "#EEFCFF",
            justifyContent: "center",
            alignItems: "center"
        },
        positionWeb: {
            top: -7,
        }
    });

    return <BaseMarker
        key={`${slug}-landing-point`}
        coordinate={landingPoint}
        anchor={{x: 0.5, y: 0.5}}
        style={{
            zIndex: (zIndex * 10) + 4 
        }}
        tracksViewChanges={false}
    >
        <View style={{...landingPointStyles.position, ...(isWeb && landingPointStyles.positionWeb)}}>
            <View>
            </View>
        </View>
    </BaseMarker>
}
export default React.memo(LandingPointMarker);