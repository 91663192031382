import React from 'react';
import { useSelector } from "react-redux";
import { Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import { Ionicons, FontAwesome } from '@expo/vector-icons';

import { routesListStyles } from "./styles/routesPageStyles";
import { getRouteColor } from "styles/shared";
import { getLangTerm } from "lang";
import RouteStartButton from "./RouteStartButton";
import PurchaseButtons from "../../shared/PurchaseButtons";
import PurchaseSuccess from "../../shared/PurchaseSuccess";
import LocalImage from '../../shared/LocalImage';
import { isRetina } from "utils/isRetina";
import { getRouteIsPurchased } from "utils/purchases";

const headerThumbSize = isRetina ? "thumblarge" : "thumbmedium";

const renderStopMetaData = (metaData, lang) => {
    return <View>
        {
            metaData && metaData[lang] && metaData[lang].map((datum,i) => <View key={i} style={routesListStyles.metadataItem}>
                <FontAwesome name="circle" style={routesListStyles.metadataIcon} />
                <Text style={routesListStyles.metadataText}>{ datum }</Text>
            </View>)
        }
    </View>
}

const RoutesList = () => {

    const lang = useSelector(state => state.settings.lang);
    const routeData = useSelector(state => state.mapData.routeData);
    const routeDataError = useSelector(state => state.mapData.routeDataError);
    const routeError = useSelector(state => state.mapData.routeError);
    const purchases = useSelector(state => state.purchases.purchases);
    const staticAccessCodes = useSelector(state => state.accessCodes.staticAccessCodes);
    const purchasedAccessCodes = useSelector(state => state.accessCodes.purchasedAccessCodes);

    const renderPremiumLabel = ({isPremium, isPurchased}) => {
        if (isPremium) {
            return <View style={routesListStyles.premiumContainer}>
                <Image
                    source={require('../../../../assets/diamond.png')}
                    style={routesListStyles.premiumIcon}
                    resizeMode="contain"
                />
                <Text style={routesListStyles.premiumText}>
                    { getLangTerm(isPurchased ? "premium-content-unlocked": "premium-route", lang) }
                </Text>
            </View>
        }
        return <View style={routesListStyles.premiumContainer}>
            <Text style={routesListStyles.premiumText}>
                { getLangTerm("free-route", lang) }
            </Text>
        </View>
    }

    return <View style={routesListStyles.routeList}>
        <View style={routesListStyles.routeListInner}>
            {
                routeDataError && <View>
                    <Text>{ getLangTerm("routes-loading-error", lang) }</Text>
                </View>
            }
            {
                routeData && routeData?.map((route, i) => {

                    // check if t=test exists in the querystring and set that to a variable
                    // const isTest = window?.location?.search?.indexOf("t=test") > -1;

                    // if (!route.published && !isTest) {
                    //     return null;
                    // }

                    const isPurchased = getRouteIsPurchased({ route, purchases, staticAccessCodes, purchasedAccessCodes });

                    return <View key={`${route.slug}-${i}`}>
                        <View
                            style={{
                                ...routesListStyles.borderContainer,
                                backgroundColor: getRouteColor(route.slug)
                            }}></View>
                        <TouchableWithoutFeedback
                            onPress={() => {
                            }}
                        >
                            <View style={{
                                ...routesListStyles.listItem,
                                flexDirection: "column",
                                borderWidth: 0
                            }}>
                                <View style={routesListStyles.itemRow}>
                                    { route.thumbnail && <LocalImage
                                        style={routesListStyles.thumb}
                                        image={route.thumbnail}
                                        imageSize={headerThumbSize}
                                        timestamp={route.timestamp}
                                    /> }
                                    <View style={routesListStyles.textContainer}>
                                        <Text style={routesListStyles.itemHeader}>
                                            {route.title}
                                        </Text>
                                        { renderPremiumLabel({ isPremium: route.isPremium, isPurchased}) } 
                                        <View style={routesListStyles.walkingTime}>
                                            <View style={routesListStyles.walkingIcon}>
                                                <Ionicons
                                                    name="ios-walk"
                                                    size={routesListStyles.walkingIconSize.height}
                                                    style={{color: "#000000"}}
                                                />
                                            </View>
                                            <Text style={routesListStyles.walkingText}>
                                                {`${route.walkingTime} ${getLangTerm("hours", lang)}`} 
                                            </Text>
                                        </View>
                                        { renderStopMetaData(route.metaData, lang) }
                                    </View>
                                </View>
                                <Text style={routesListStyles.description}>
                                    {route.summary}
                                </Text>
                                <RouteStartButton
                                    route={route}
                                />
                                <PurchaseSuccess routeSlug={route.routeID} />
                                <PurchaseButtons route={route} />
                                { routeError === route.slug && <View>
                                    <Text style={routesListStyles.error}>{ getLangTerm("route-loading-error", lang) }</Text>
                                </View> }
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                    ;
                })
            }
        </View>
    </View>
}
export default RoutesList