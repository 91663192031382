import { Dimensions } from "react-native";

import { colorNames, colors } from "./shared";

const win = Dimensions.get('window');

export const listStyles = {
    list: {
        position: "absolute",
        width: win.width,
        height: win.height,
        zIndex: 0,
        // marginHorizontal: -12,
        paddingHorizontal: 10,
        backgroundColor: "#ffffffbb",
        // borderWidth: 2,
        // borderColor: "red"
        // marginTop: -160,
    },
    listSpacerBottom: {
        height: 70,
    },
    listItem: {
        flexDirection: "row",
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
    },
    thumb: {
        height: 93,
        width: 93,
        borderRadius: 5,
        margin: 10,
        marginRight: 14,
    },
    textContainer: {
        flex: 1,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
    },
    itemHeader: {
        fontFamily: "bold",
        fontSize: 18,
    },
    itemDistance: {
        fontSize: 16,
        color: colors.subtitleColor,
        marginBottom: 8,
        justifyContent: "center"
    },
    subtitle: {
        fontSize: 16,
        color: colors.subtitleColor,
        fontFamily: "semi-bold",
        marginBottom: 8,
    },
    walkingIcon: {
        marginRight: 5,
    },
    walkingIconSize: {
        height: 16
    },
}