import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyleSheet, Text, View, TouchableWithoutFeedback, ScrollView } from "react-native";
import { Ionicons } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isArray } from 'lodash';

import { types } from "redux/types";
import { ListIcon, MarkerIcon } from "../SVGs";

import { viewRow, getRouteColor, colorNames, screenContainer } from "styles/shared";
import { listStyles } from "styles/listStyles";
import { getLangTerm } from "lang";
import { getDistanceStr } from "utils/distance";
import { isRetina } from "utils/isRetina";
import WalkingButtons from "./WalkingButtons";
import { getStopImageorIcon } from "../../utils/stops";

const footerThumbSize = isRetina ? "thumblarge" : "thumbmedium";

const footerStyles = StyleSheet.create({
    ...listStyles,
    container: {
        position: "absolute",
        width: "100%",
        zIndex: 1,
        paddingVertical: 12,
        bottom: 0,
        backgroundColor: "#fff",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    screenContainer: {
        ...screenContainer,
        ...viewRow,
    },
    bigButtonContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    bigButton: {
        zIndex: 2,
        // flex: 1,
        backgroundColor: `#1D9CAD`,
        flexDirection: "row",
        alignItems: "center",
        height: 42,
        width: "auto",
        flexShrink: 1,
        borderRadius: 55,
        paddingHorizontal: 20
    },
    buttonText: {
        color: "#fff",
        fontFamily: "bold",
        fontSize: 16,
        marginLeft: 10
    },
    thumb: {
        ...listStyles.thumb,
        width: 50,
        height: 50
    },
    iconThumb: {
        ...listStyles.thumb,
        width: 50,
        height: 50,
        justifyContent: "center",
        alignItems: "center"
    },
    icon: {
        color: "#fff"
    }
});

const MapFooter = () => {
    
    const lang = useSelector(state => state.settings.lang);
    const showFooterList = useSelector(state => state.mapData.showFooterList);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);

    const dispatch = useDispatch();
    const setShowFooterList = (showFooterList) => {
        dispatch({
            type: types.SHOW_FOOTER_LIST,
            showFooterList,
            userActionData: { showFooterList }
        });
    }

    let textLabel = selectedRouteData ? getLangTerm("show-route-stops", lang)  : getLangTerm("near-me", lang);
    if(showFooterList) {
        textLabel = getLangTerm("back-to-map", lang);
    }

    const icon = showFooterList ? <MarkerIcon style={{color: "#FFF"}} /> : <ListIcon />

    return <>
        <FooterList />
        <View style={footerStyles.container}> 
            <View style={footerStyles.screenContainer}>
                <TouchableWithoutFeedback onPress={() => {
                    setShowFooterList(!showFooterList)
                }}>
                    <View style={footerStyles.bigButtonContainer}>
                        <View style={footerStyles.bigButton}>
                            <View style={footerStyles.icon}>
                                { icon }
                            </View>
                            <Text style={footerStyles.buttonText}>{textLabel}</Text>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
                <WalkingButtons />
            </View>
        </View>
    </>
};
export default MapFooter

const FooterList = () => {

    const showFooterList = useSelector(state => state.mapData.showFooterList);

    if(showFooterList) {
        return <ScrollView style={{
            ...footerStyles.list
        }}>
            <SelectedRouteStops />
        </ScrollView>
    }
    return null;
}

const SelectedRouteStops = () => {
    const insets = useSafeAreaInsets();

    const lang = useSelector(state => state.settings.lang);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedRouteStops = useSelector(state => state.mapData.selectedRouteStops);
    const selectedStopData = useSelector(state => state.mapData.selectedStopData);

    const dispatch = useDispatch();
    const setWalkingRoute = (routeSlug, stopSlug) => {
        dispatch({
            type: types.SET_WALKING_ROUTE_STOP,
            routeSlug,
            stopSlug,
            lang,
            userActionData: { routeSlug, stopSlug }
        });
    };

    const renderHOFExtraContent = (stop) => {
        if (selectedRouteData.routeSlug === "bc-entertainment-hof" && isArray(stop.description)) {
            const subtitles = [];
            stop.description.forEach((p) => {
                if (p.indexOf("{") > -1) {
                    // convert the json object to a url link
                    // maybe other types will come
                    const jsonP = JSON.parse(p);
                    if (jsonP.type == "header" && stop.title.indexOf(jsonP.label) < 0) {
                        subtitles.push(jsonP.label)
                    }
                }
            })
            return <Text style={ footerStyles.subtitle }>Also Featuring: { subtitles.join(", ") }</Text>;
        }
    }

    return <>
        <View style={{paddingTop: insets.top + 60}}></View>
        { selectedRouteStops && selectedRouteStops.map(stop => {
            const isActiveStop = selectedStopData && selectedStopData.slug == stop.slug;
            const image = getStopImageorIcon({ stop, styles: footerStyles, thumbSize: footerThumbSize, timestamp: selectedRouteData.timestamp });

            return <TouchableWithoutFeedback key={stop.slug}
                onPress={() => {
                    setWalkingRoute(selectedRouteData.slug, stop.slug);
                }}
            >
                <View style={{
                    ...footerStyles.listItem,
                    borderColor: getRouteColor(stop.routeID),
                    ...(isActiveStop && { borderWidth: 5, borderColor: colorNames.actionGreen})
                }}>
                    { image }
                    <View style={footerStyles.textContainer}>
                        <Text style={footerStyles.itemHeader}>{stop.title}</Text>
                        { renderHOFExtraContent(stop) }
                        { stop.minutes && <Text style={footerStyles.itemDistance}>
                            <View style={footerStyles.walkingIcon}>
                                <Ionicons name="ios-walk" size={footerStyles.walkingIconSize.height} />
                            </View>
                            { getDistanceStr(stop.minutes, lang) }
                        </Text>}
                    </View>
                </View>
            </TouchableWithoutFeedback>
        })}
        <View style={footerStyles.listSpacerBottom}></View>
    </>;
}