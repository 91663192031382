import React, { useContext } from 'react';
import { Animated, Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import Constants from 'expo-constants';

import { getLangTerm } from "lang";
import { viewRow } from "styles/shared";
import { getDistanceStr } from "utils/distance";
import { DetailsPageContext } from "./DetailsPageContext";
import { isWeb } from 'utils/platform';

const DetailsPageHeader = () => {
    const {
        lang,
        detailsPageData,
        detailsStyles,
        onCloseButtonPress,
        headerElevation,
        headerDropShadowOpacity,
        markerIconScale,
        isInfoPage,
        isPlacePage,
        isPremium,
        offlineMode
    } = useContext(DetailsPageContext);

    const { title, subtitle, distance, minutes } = detailsPageData;

    const renderPremiumLabel = () => {
        if (isPremium) {
            return <View style={detailsStyles.premiumContainer}>
                <Image
                    source={require('../../../../assets/diamond.png')}
                    style={detailsStyles.premiumIcon}
                    resizeMode="contain"
                />
                <Text style={detailsStyles.premiumText}>
                    { getLangTerm("premium-route", lang) }
                </Text>
            </View>
        }
    }

    const renderDistanceStr = () => {
        if (isPlacePage()) {
            return <View style={viewRow}>
                { subtitle && <Text style={detailsStyles.subtitle}>{subtitle}</Text> }
            </View>
        }
        if (offlineMode) {
            return null;
        }
        return <View style={viewRow}>
            { distance ? <>
                <Ionicons
                    name="ios-walk"
                    size={detailsStyles.walkingIconSize.height}
                    style={{color: "#000000"}}
                />
                <Text style={detailsStyles.subtitle}>{ getDistanceStr(minutes, lang) }</Text>
            </> : <Text style={detailsStyles.subtitle}> </Text> }
        </View>
    }

    return <Animated.View style={{
        ...detailsStyles.headerContainer,
        elevation: headerElevation,

        backgroundColor: "white",
        paddingTop: isInfoPage() ? isWeb ? 0 : 20 : Constants.statusBarHeight,
        zIndex: 1
    }}>
        <Animated.View style={{
            ...detailsStyles.headerContainerShadow,
            opacity: headerDropShadowOpacity,
        }} >
        </Animated.View>
        <View style={{
            ...detailsStyles.headerContainerInner,
            backgroundColor: "white"
        }} >
            <TouchableWithoutFeedback onPress={onCloseButtonPress}>
                <View style={{...detailsStyles.headerCloseContainer}} >
                    <FontAwesome
                        name="chevron-left"
                        size={detailsStyles.headerCloseSize.height}
                        style={{color: "#000000"}}
                    />
                </View>
            </TouchableWithoutFeedback>
            <View style={detailsStyles.headerText} >
                <Text style={{...detailsStyles.header}}>{title}</Text>
                { renderPremiumLabel() }
                { renderDistanceStr() } 
            </View>
            <Animated.View style={{
                ...detailsStyles.markerIcon,
                transform: [{
                    scale: markerIconScale
                },
                ]
            }}>
                <FontAwesome name="map-marker" size={detailsStyles.iconSize.height} style={detailsStyles.icon} />
            </Animated.View>
        </View>
    </Animated.View> 
};
export default DetailsPageHeader