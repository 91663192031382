import React from "react";
import { Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
    Select,
    SelectItem,
    IndexPath
} from '@ui-kitten/components';

import {
    langList
} from "lang"
import { types } from "redux/types";
import { settingsStyles } from "./styles/settingsStyles";

const LanguageSelector = () => {

    const routesTimestamp = useSelector(state => state.timestamp.routes);
    const placesTimestamp = useSelector(state => state.timestamp.places);
    const lang = useSelector(state => state.settings.lang);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);

    const dispatch = useDispatch();

    let selectedLanguageIndex = null;
    let selectedValue = null;

    langList.forEach((l, i) => {
        if(l.slug === lang) {
            selectedLanguageIndex = new IndexPath(i);
            selectedValue = l.text;
        }
    });

    const onChangeLang = (e) => {
        const newLang = langList[e.row];
        dispatch({
            type: types.LANG_CHANGED,
            routesTimestamp,
            placesTimestamp,
            lang: newLang.slug,
            routeSlug: selectedRouteData?.slug,
            userActionData: { newLang: newLang.slug }
        });
    }

    return <Select
        label={evaProps => <Text {...evaProps} style={[evaProps.style, settingsStyles.labelStyles]}>Choose your language</Text>}
        selectedIndex={selectedLanguageIndex}
        value={selectedValue}
        // selectedOption={langList.filter(l => l.slug == lang)[0]}
        onSelect={onChangeLang}
        status='success'
    >
        { langList.map(lang => <SelectItem key={lang.slug} title={lang.text} />) }
    </Select>
};

export default LanguageSelector;