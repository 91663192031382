import React, { useRef } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Input,
} from '@ui-kitten/components';
import {
    useScrollIntoView,
} from 'react-native-scroll-into-view';

import { getLangTerm } from "lang";
import { types } from "redux/types";


import {
    colorNames,
} from "styles/shared";
import { ActivityIndicator } from "react-native";

const accessCodeStyles = StyleSheet.create({

    header: {
        textAlign: "center",
        fontFamily: "bold",
        fontSize: 24,
        marginTop: 15,
        marginBottom: 5,
        color: colorNames.parksGreen,
    },
    description: {
        textAlign: "center",
        fontSize: 16,
        marginBottom: 5,
    },
    emailDescription: {
        textAlign: "center",
        fontSize: 12,
        marginVertical: 8,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        backgroundColor: `#1D9CAD`,
        flexDirection: "row",
        alignItems: "center",
        height: 36,
        borderRadius: 55,
        paddingHorizontal: 20,
        marginTop: 20
    },
    buttonText: {
        color: "#FFF",
        fontFamily: "bold",
        fontSize: 16
    },
    accessCodeValid: {
        fontSize: 16,
        color: colorNames.parksGreen,
        marginTop: 10,
        textAlign: "center"
    },
    accessCodeInvalid: {
        fontSize: 16,
        color: colorNames.actionRed,
        marginTop: 10,
        textAlign: "center"
    }
});

const AccessCodeInput = () => {

    const scrollIntoView = useScrollIntoView();
    const accessCodesRef = useRef();
    const [entryError, setEntryError] = React.useState(false);

    const lang = useSelector(state => state.settings.lang);

    const dispatch = useDispatch();
    const accessCodesLoadFailure = useSelector(state => state.accessCodes.accessCodesLoadFailure);
    const submitting = useSelector(state => state.accessCodes.submitting);
    const isInvalid = useSelector(state => state.accessCodes.isInvalid);
    const isValid = useSelector(state => state.accessCodes.isValid);
    const isInactive = useSelector(state => state.accessCodes.isInactive);
    const didRedemption = useSelector(state => state.accessCodes.didRedemption);
    const didEmail = useSelector(state => state.accessCodes.didEmail);
    const errorMessage = useSelector(state => state.accessCodes.errorMessage);

    const { control, handleSubmit } = useForm();

    const accessCodeSubmit = (data) => {
        if (data.accessCode.trim().length > 0) {
            setEntryError(false);
            dispatch({
                type: types.ACCESS_CODE_SUBMIT,
                lang,
                ...data
            });
        } else {
            setEntryError(true);
        }
    };

    const inputOnFocus = (target) => {
        // scroll so the top of the form is at the top of the screen
        scrollIntoView(target, {
            align: "top",

        })
    }

    if (Platform.OS === "ios") {
        return null;
    }

    return <View ref={accessCodesRef}>
        <Text style={accessCodeStyles.header}>{ getLangTerm("access-codes", lang) }</Text>
        <Text style={accessCodeStyles.description}>{ getLangTerm("access-code-description", lang) }</Text>
        <Controller
            control={control}
            render={({ field: { onChange, onBlur }, value }) => (
                <Input
                    size="large"
                    onFocus={() => inputOnFocus(accessCodesRef.current)}
                    onBlur={() => {
                        // handleSubmit(accessCodeSubmit)()
                        onBlur()
                    }}
                    onChangeText={value => {
                        setEntryError(false);
                        onChange(value)
                    }}
                    value={value}
                />
            )}
            name="accessCode"
            defaultValue=""
        />
        { didRedemption && <View>
            <Text style={accessCodeStyles.accessCodeValid}>
                { getLangTerm("access-code-redemption-success", lang) }
            </Text>
        </View>}
        { didEmail && <View>
            <Text style={accessCodeStyles.accessCodeValid}>
                { getLangTerm("access-codes-emailed", lang) }
            </Text>
        </View>
        }
        { isValid && <View>
            <Text style={accessCodeStyles.accessCodeValid}>
                { getLangTerm("valid-access-code", lang) }
            </Text>
        </View> }
        { entryError && <View>
            <Text style={accessCodeStyles.accessCodeInvalid}>
                { getLangTerm("enter-access-code-or-email", lang) }
            </Text>
        </View>}
        { !!errorMessage && <View>
            <Text style={accessCodeStyles.accessCodeInvalid}>
                { getLangTerm(errorMessage, lang) }
            </Text>
        </View>}
        { isInactive && <View>
            <Text style={accessCodeStyles.accessCodeInvalid}>{getLangTerm("inactive-access-code", lang)}</Text>
        </View>}
        { isInvalid && !isInactive && <View>
            <Text style={accessCodeStyles.accessCodeInvalid}>{getLangTerm("invalid-access-code", lang)}</Text>
        </View>}
        { accessCodesLoadFailure && <View>
            <Text style={accessCodeStyles.accessCodeInvalid}>{getLangTerm("error-loading-access-codes", lang)}</Text>
        </View> }
        <View style={accessCodeStyles.buttonContainer}>
            <TouchableOpacity onPress={handleSubmit(accessCodeSubmit)}>
                <View style={{
                    ...accessCodeStyles.button
                }}>
                    <Text style={accessCodeStyles.buttonText}>{ getLangTerm("submit", lang)}</Text>
                    { submitting && <ActivityIndicator size="small" color="#FFF" style={{ marginLeft: 10 }} /> }
                </View>
            </TouchableOpacity>
        </View>
    </View>
};

export default AccessCodeInput;