import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as SplashScreen from 'expo-splash-screen';
import * as Font from "expo-font";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { SentryPlatform } from 'utils/sentry';

import { types } from "redux/types";
import { setCustomText } from 'utils/setCustomText';
import { isWeb } from "utils/platform";

import Loading from "./components/Loading";
import WalkingMap from "./components/map/WalkingMap";
import MapHeader from "./components/map/MapHeader";
import MapFooter from "./components/map/MapFooter";
import DetailsPage from "./components/pages/detailsPage/DetailsPage";
import SettingsPage from "./components/pages/settingsPage/SettingsPage";
import AboutPage from "./components/pages/aboutPage/AboutPage";
import RoutesPage from "./components/pages/routesPage/RoutesPage";
import { getUserUniqueID } from "./redux/apis/userActionsAPI";
import OfflineMode from "./components/offlineMode/OfflineMode";

const loadFonts = async(setFontsLoaded) => {
    await Font.loadAsync({
        "light": require("../assets/fonts/HKGrotesk-Light.ttf"),
        "regular": require("../assets/fonts/HKGrotesk-Regular.ttf"),
        "semi-bold": require("../assets/fonts/HKGrotesk-SemiBold.ttf"),
        "bold": require("../assets/fonts/HKGrotesk-Bold.ttf")
    });

    const customTextProps = {
        style: {
            fontFamily: 'regular',
            color: "#000"
        }
    }
    setCustomText(customTextProps);

    setFontsLoaded(true);
}

const AppRoot = () => {
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const [showSettingsPage, setShowSettingsPage] = useState(false);
    const [showAboutPage, setShowAboutPage] = useState(false);

    const toggleShowSettingsPage = () => {
        setShowSettingsPage(!showSettingsPage);
    }

    const toggleShowAboutPage = () => {
        setShowAboutPage(!showAboutPage);
    }

    const timestampLoaded = useSelector(state => state.timestamp.loaded);
    const routesTimestamp = useSelector(state => state.timestamp.routes);
    const placesTimestamp = useSelector(state => state.timestamp.places);

    const lang = useSelector(state => state.settings.lang);
    const langLoaded = useSelector(state => state.settings.langLoaded);

    const routeDataLoading = useSelector(state => state.mapData.routeDataLoading);
    const routeDataLoaded = useSelector(state => state.mapData.routeDataLoaded);
    const routeDataError = useSelector(state => state.mapData.routeDataError);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const offlineMode = useSelector(state => state.mapData.offlineMode);

    const placesLoading = useSelector(state => state.mapData.placesLoading);
    const placesLoaded = useSelector(state => state.mapData.placesLoaded);
    const placesError = useSelector(state => state.mapData.placesError);
    
    const dispatch = useDispatch();
    // on initial render
    useEffect(() => {
        const initApp = async () => {
            await getUserUniqueID(); // getting this first so it's populated for all the other ones;
            await SplashScreen.preventAutoHideAsync();
            dispatch({ type: types.APP_OPENED });
            dispatch({ type: types.LANG_LOOKUP });
            dispatch({ type: types.TIMESTAMP_LOOKUP });
            dispatch({ type: types.USER_CHECK_LOCATION_PREFERENCE });
            if (!isWeb) {
                dispatch({ type: types.LOAD_OFFERINGS });
                dispatch({ type: types.LOAD_PURCHASES });
            }
            dispatch({ type: types.LOAD_ACCESS_CODES });

            loadFonts(setFontsLoaded);
        }
        initApp();
        return () => {
            dispatch({ type: types.APP_CLOSED });
        }
    }, [])

    // when the language is loaded and the newest timestamp is retrieved, load data from the cache
    // separating them into different useEffects for simplicity
    useEffect(() => {
        if (timestampLoaded && langLoaded) {
            // load the routes
            if (!routeDataLoading && !routeDataLoaded && !routeDataError) {
                dispatch({
                    type: types.ROUTE_DATA_LOOKUP,
                    routesTimestamp,
                    lang
                });
            }
        }
    }, [timestampLoaded, langLoaded, routesTimestamp, routeDataLoading, routeDataLoaded, routeDataError, lang]);

    useEffect(() => {
        if (timestampLoaded && langLoaded) {
            // load the places (mostly businesses)
            if (!placesLoading && !placesLoaded && !placesError) {
                dispatch({
                    type: types.PLACES_LOOKUP,
                    placesTimestamp,
                    lang
                });
            }
        }
    }, [timestampLoaded, langLoaded, placesTimestamp, placesLoading, placesLoaded, placesError, lang]);

    useEffect(() => {
        const hideSplash = async () => {
            await SplashScreen.hideAsync();
        }
        if (lang && fontsLoaded) {
            hideSplash();
        }
    }, [lang, fontsLoaded]);

    if (!lang || !fontsLoaded) {
        return null;
    }

    return <SentryPlatform.ErrorBoundary fallback={"An error has occurred"}>
        <SafeAreaProvider>
            { selectedRouteData && !offlineMode && <>
                <WalkingMap />
                <MapHeader
                    toggleShowSettingsPage={toggleShowSettingsPage}
                    toggleShowAboutPage={toggleShowAboutPage}
                />
                <MapFooter />
            </>}
            { selectedRouteData && offlineMode && <>
                <MapHeader
                    toggleShowSettingsPage={toggleShowSettingsPage}
                    toggleShowAboutPage={toggleShowAboutPage}
                />
                <OfflineMode />
            </>}
            <RoutesPage
                toggleShowSettingsPage={toggleShowSettingsPage}
                toggleShowAboutPage={toggleShowAboutPage}
            />
            <DetailsPage />
            <SettingsPage
                showSettingsPage={showSettingsPage}
                toggleShowSettingsPage={toggleShowSettingsPage}
            />
            <AboutPage
                showAboutPage={showAboutPage}
                toggleShowAboutPage={toggleShowAboutPage}
            />
            <Loading />
        </SafeAreaProvider>
    </SentryPlatform.ErrorBoundary>
}

export default AppRoot;