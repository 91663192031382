import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from "react-redux";

import { getLangTerm } from "lang";
import { colorNames } from "styles/shared";

const warningStyles = StyleSheet.create({
    container: {
        marginHorizontal: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    containerInner: {
        width: "100%",
        maxWidth: 600,
        borderWidth: 1,
        backgroundColor: "#FFFFFF",
        borderColor: colorNames.actionOrange,
        borderRadius: 15,
        // paddingHorizontal: 10,
        paddingVertical: 12,
        // marginHorizontal: "auto",
        marginBottom: 10
    },
    warningText: {
        color: "#000000",
        fontFamily: "bold",
        fontSize: 14,
        textAlign: "center",
        
    },
});

const AudioLanguageWarning = () => {

    const lang = useSelector(state => state.settings.lang);

    return lang !== "en" && <View style={warningStyles.container}>
        <View style={warningStyles.containerInner}>
            <Text style={warningStyles.warningText}>{ getLangTerm("audio-content-english", lang) }</Text>
        </View>
    </View>
};
export default AudioLanguageWarning