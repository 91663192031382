import React, { useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Animated, Text, View, TouchableWithoutFeedback } from 'react-native';
import { isArray } from 'lodash';
import { LinearGradient } from 'expo-linear-gradient';

import { types } from "redux/types";

import { Close } from "../../SVGs";
import AudioPlayer from "../../AudioPlayer";
import RouteActionButtons from "./RouteActionButtons";
import PurchaseButtons from "../../shared/PurchaseButtons";
import PurchaseSuccess from "../../shared/PurchaseSuccess";
import ImageCarousel from "./ImageCarousel";
import DetailsPageHeader from "./DetailsPageHeader";
import VisitorInformation from "./VisitorInformation";
import DetailsPageContextProvider, { DetailsPageContext } from "./DetailsPageContext";

import { getLangTerm } from "lang";
import { isRetina } from "utils/isRetina";
import LocalImage from '../../shared/LocalImage';
import { openURL } from "utils/sharedActions";
import { screenContainer } from 'styles/shared';

const stopThumbSize = isRetina ? "thumblarge" : "thumbmedium";

const DetailsPage = () => {

    const detailsPageData = useSelector(state => state.mapData.detailsPageData);

    if (detailsPageData) {
        const { stopType, slugIndex } = detailsPageData
        return <DetailsPageContextProvider
            stopType={stopType}
            slugIndex={slugIndex}
        >
            <DetailsPageWContext />
        </DetailsPageContextProvider>
    }
    return null;
}

const DetailsPageWContext = () => {

    const {

        detailsPageData,
        detailsStyles,
        isPremium,
        isPurchased,
        setCarouselHeight,
        handleScroll,
        color
    } = useContext(DetailsPageContext);

    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);

    if (!detailsPageData) {
        return null;
    }
    const { slug, stops: detailStops, images: detailsImage, content, description, audioURL, articles, stopType, timestamp } = detailsPageData;

    const onCarouselLayout = (e) => {
        setCarouselHeight(e.nativeEvent.layout.height);
    }

    const renderAudioPlayer = () => {
        let showAudioPlayer = true;
        if (isPremium && stopType !== "route-start" && !isPurchased && slug !== "steam-clock" && slug !== "nine-o-clock-gun-stanley-park") {
            showAudioPlayer = false;
        }
        const thisTimestamp = timestamp || selectedRouteData?.timestamp;
        return showAudioPlayer && <AudioPlayer slug={slug} routeSlug={selectedRouteData?.slug} audioURL={audioURL} color={color} timestamp={thisTimestamp} />
    }

    return <View style={{
        ...detailsStyles.container
    }} key="details-page">
        <Animated.ScrollView
            stickyHeaderIndices={[1]}
            style={detailsStyles.scrollView}
            scrollEventThrottle={1}
            onScroll={handleScroll}
        >
            <View style={screenContainer}>
                <View style={{
                    zIndex: 11
                }} onLayout={onCarouselLayout}>
                    <ImageCarousel images={detailsImage} />
                </View>            
                <CloseButton />
            </View>
            <View style={screenContainer}>
                <DetailsPageHeader />
            </View>
            <View style={screenContainer}>

                <PurchaseSuccess routeSlug={detailsPageData.routeID} />
                { (isPremium && !isPurchased && stopType == "route-start") && <PurchaseButtons route={detailsPageData} /> }
                { (!isPremium || isPurchased) && <RouteActionButtons /> }
                <Articles
                    articles={articles}
                    detailsStyles={detailsStyles}
                />
                <VisitorInformation />
                { renderContent(content || description, detailsStyles) }
                { (isPremium && !isPurchased && stopType == "stop" && slug !== "steam-clock" && slug !== "nine-o-clock-gun-stanley-park" ) && <PurchaseButtons routeSlug={detailsPageData.routeID} /> }
                <RouteStops
                    detailStops={detailStops}
                    detailsStyles={detailsStyles}
                />
            </View>
        </Animated.ScrollView>
        { renderAudioPlayer() }
    </View>
}
export default DetailsPage;

const CloseButton = () => {
    const {
        detailsStyles,
        onCloseButtonPress,
        isInfoPage
    } = useContext(DetailsPageContext);

    if (isInfoPage()) {
        return null;
    }

    return <TouchableWithoutFeedback onPress={onCloseButtonPress}>
        <View style={{...detailsStyles.closeButton}}>
            <Close size={14} />
        </View>
    </TouchableWithoutFeedback>
}

const RouteStops = ({detailStops, detailsStyles}) => {
    const {
        lang,
        detailsPageData
    } = useContext(DetailsPageContext);

    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedRouteStops = useSelector(state => state.mapData.selectedRouteStops);
    
    const dispatch = useDispatch();
    const setMapToPoint = (latlng) => {
        dispatch({
            type: types.SET_MAP_TO_POINT,
            point: latlng
        });
    }

    const renderHOFExtraContent = (stop) => {
        if (detailsPageData.routeSlug === "bc-entertainment-hof" && isArray(stop.description)) {
            const subtitles = [];
            stop.description.forEach((p) => {
                if (p.indexOf("{") > -1) {
                    // convert the json object to a url link
                    // maybe other types will come
                    const jsonP = JSON.parse(p);
                    if (jsonP.type == "header" && stop.title.indexOf(jsonP.label) < 0) {
                        subtitles.push(jsonP.label)
                    }
                }
            })
            return <Text style={ detailsStyles.stopListItemSubtitle }>Also Featuring: { subtitles.join(", ") }</Text>;
        }
    }

    if (detailsPageData.stopType !== "route-start") {
        return null;
    }

    if (detailStops && isArray(detailStops)) {
        return <View style={detailsStyles.stopList}>
            <Text style={detailsStyles.stopListHeader}>{ getLangTerm("stops", lang) }</Text>
            { selectedRouteStops.map((stop) => {
                if (stop && stop.stopType == "stop") {
                    let targetImage = stop.images ? stop.images[0]: null;
                    if (stop.thumbnail) {
                        targetImage = stop.images.filter(image => {
                            return stop.thumbnail === `${image.imageSlug}-${image.imageNum}`
                        })[0];
                    }
                    return <TouchableWithoutFeedback key={stop.slug} onPress={() => setMapToPoint(stop.latlng)}>
                        <View style={detailsStyles.stopListItem}>
                            <LocalImage
                                style={detailsStyles.stopListItemThumb}
                                image={targetImage}
                                imageSize={stopThumbSize}
                                timestamp={selectedRouteData.timestamp}
                            />
                            <View style={detailsStyles.stopListContent}>
                                <Text style={detailsStyles.stopListItemTitle}>{ stop.title }</Text>
                                { renderHOFExtraContent(stop) } 
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                }
            }) }
        </View>
    }

    return null;
}

const Articles = ({articles, detailsStyles}) => {
    const {
        lang,
    } = useContext(DetailsPageContext);

    if(articles && articles.length > 0) {
        return <View>
            <Text style={detailsStyles.articleHeader}>{getLangTerm("featured-in", lang)}</Text>
            {
                articles.map((article, i) => {
                    return <View key={i}>
                        <Text style={detailsStyles.articleTitle}>{article.articleTitle}</Text>
                    </View>
                })
            }
        </View>
    }
    return null;
};

const renderContent = (displayContent, detailsStyles) => {
    const {
        detailsPageData,
        isPremium,
        isPurchased
    } = useContext(DetailsPageContext);
    const dispatch = useDispatch();

    const { slug, stopType } = detailsPageData;

    let content;

    if (isArray(displayContent)) {
        content = displayContent.map((p, i) => {

            if (p.indexOf("{") > -1) {
                // convert the json object to a url link
                // maybe other types will come
                const jsonP = JSON.parse(p);
                if (jsonP.type == "link") {
                    return <TouchableWithoutFeedback key={i} onPress={()=> {
                        openURL({ url: jsonP.url, target: slug}, dispatch);
                    }}>
                        <Text key={i} style={ detailsStyles.link}>
                            { jsonP.label }
                        </Text>
                    </TouchableWithoutFeedback>
                }
                if (jsonP.type == "header") {
                    return <Text key={i} style={ detailsStyles.contentHeader}>
                        { jsonP.label }
                    </Text>
                }
            }

            return <Text key={i} style={ detailsStyles.description}>
                { p }
            </Text>
        })
    } else {
        content = <Text style={ detailsStyles.description}>
            { displayContent }
        </Text>;
    }
    // if this is a unpurchased premium route and isn't the slug, cut off the content
    if (isPremium && !isPurchased && stopType !== "route-start" && slug !== "steam-clock" && slug !== "nine-o-clock-gun-stanley-park") {

        return <View style={{
            position: "relative",
            height: 100,
            overflow: "hidden",
        }}>
            <LinearGradient
                // Background Linear Gradient
                colors={["#ffffff00", '#fff']}
                locations={[0, 0.9]}
                style={{
                    position: "absolute",
                    height: 100,
                    zIndex: 1
                }}
            >
                <View style={{
                    opacity: 0
                }}>
                    { content }
                </View>
            </LinearGradient>
            { content }
        </View>
    }

    return <View>
        { content }
    </View>
}