import * as Updates from 'expo-updates';

const ENV = {
    dev: {
        env: "dev",
        apiUrl: "https://api-dev.vanwalks.ca/v1",
        apiKey: "zrXZ9xydEK2FkUo5YJsVz8qH7fzpZX559SW4WAiq"
        // apiUrl: "http://localhost:3000/dev/v1" // using local dev (turn off apiKey)
    },
    staging: {
        env: "staging",
        apiUrl: "https://api-dev.vanwalks.ca/v1", // just pointing at dev API for now
        apiKey: "zrXZ9xydEK2FkUo5YJsVz8qH7fzpZX559SW4WAiq"
    },
    prod: {
        env: "prod",
        apiUrl: "https://api.vanwalks.ca/v1",
        apiKey: "7XMEYteyAsvcS6sN0GSr4gFPvnyt8tm8lnJYX8hj"
    }
};

const getEnvVars = () => {

    const releaseChannel = Updates.releaseChannel;

    if (__DEV__) {
        return ENV.dev;
    } else if (releaseChannel === "staging") {
        return ENV.staging
    } else {
        return ENV.prod;
    }
};

export default getEnvVars;