import { StyleSheet } from "react-native";

import { defaultText, colors, colorNames } from "./shared"


const markerHeight = 58;
const markerWidth = 48;
const markerImageSize = 40;

const smallMarkerHeight = 45;
const smallMarkerWidth = 37;
const smallMarkerImageSize = 30;

export const getMarkerStyles = ({ markerSize, displayDir, isActive }) => {

    const isLeft = displayDir == "left";

    let textPaddingInside = markerSize == "small" ? 22 : 35;
    let textPaddingOutside = 10;

    let activeBGStyles = {}

    // the overall size of the marker has to be the same between active and inactive. On Android, the marker won't get bigger unless "active" is added to the key
    // but on IOS, there is a signifigant flicker when the marker is changed
    // if the overall size is the same, it's ok
    // so when the border comes in, 

    if (isActive) {
        activeBGStyles = {
            paddingTop: 4,
            paddingBottom: 1,
            borderWidth: 5,
            borderColor: colorNames.actionGreen
        };
        textPaddingOutside = textPaddingOutside - 5;
        textPaddingInside = textPaddingInside - 5;
    }

    return StyleSheet.create({
        flyoutContainer: {
            height: markerHeight-1,
            ...(markerSize == "small" && { 
                height : smallMarkerHeight-2,
            })
        },
        flyoutContainerWeb: {
            position: "absolute",
            height: markerHeight-1,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            ...(markerSize == "small" && { 
                height : smallMarkerHeight-2,
            }),
            ...(isLeft && {
                right: 0
            })
        },
        textContainer: {
            paddingLeft: 35,
            paddingRight: 10,
            paddingTop: 9,
            paddingBottom: 6,
            // marginBottom: "auto",
            // backgroundColor: "#ffffaa",
            backgroundColor: "#ffffff",
            ...activeBGStyles,
            ...(isLeft ? {
                paddingLeft: textPaddingOutside,
                paddingRight: textPaddingInside,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10
            } : {
                paddingLeft: textPaddingInside,
                paddingRight: textPaddingOutside,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10
            })
        },
        header: {
            fontFamily: "bold",
            color: "#000000",
            fontSize: 16,
            lineHeight: 16,
            fontWeight: "400",
            whiteSpace: "nowrap"
        },
        subHeader: {
            ...defaultText,
            color: "#333333",
            fontSize: 14,
            lineHeight: 14,
            whiteSpace: "nowrap"
        },
        marker: {
            zIndex: 1,
            alignItems: "center"
        },
        markerContainer: {
            height: markerHeight,
            width: markerWidth,
            ...(markerSize == "small" && { 
                height : smallMarkerHeight,
                width : smallMarkerWidth
            }),
            alignItems: "center",
        },
        markerContainerWeb: {
            // position: "relative",
            position: "absolute",
            bottom: 0,
            right: -(markerWidth / 2),
            height: markerHeight,
            width: markerWidth,
            ...(markerSize == "small" && { 
                right: -(smallMarkerWidth / 2),
                height : smallMarkerHeight,
                width : smallMarkerWidth
            }),
            alignItems: "center",
        },
        markerBG: {
            position: "absolute",
            height: markerHeight,
            width: markerWidth,
            ...(markerSize == "small" && {
                height : smallMarkerHeight,
                width : smallMarkerWidth
            })
        },
        thumb: {
            top: 4,
            width: markerImageSize,
            height: markerImageSize,
            borderRadius: markerImageSize / 2,
            ...(markerSize == "small" && {
                height : smallMarkerImageSize,
                width : smallMarkerImageSize,
                borderRadius: smallMarkerImageSize / 2
            }),
            overflow: "hidden"
        }
    });
}

export const getRouteMarkerStyles = (options) => {

    const {markerSize, isActive, displayDir} =  options;

    const isLeft = displayDir == "left";

    let textPaddingInside = markerSize == "small" ? 22 : 35;
    let textPaddingOutside = 10;

    let iconSize = markerSize == "small" ? 24 : 32;
    let iconTop = markerSize == "small" ? 8 : 9;

    let premiumIconWidth = markerSize == "small" ? 30 : 40;
    let premiumIconHeight = markerSize == "small" ? 24 : 32
    let premiumIconTop = markerSize == "small" ? 9 : 11;

    let headerFontSize = 16;
    let subheaderFontSize = markerSize == "small" ? 11 : 14;

    // the overall size of the marker has to be the same between active and inactive. On Android, the marker won't get bigger unless "active" is added to the key
    // but on IOS, there is a signifigant flicker when the marker is changed
    // if the overall size is the same, it's ok
    // so when the border comes in, 

    // if (isActive) {
    //     activeBGStyles = {
    //         paddingTop: 4,
    //         paddingBottom: 1
    //     };
    //     headerFontSize = 16;
    //     subheaderFontSize = 12;
    // }

    const styles = getMarkerStyles({...options, isActive});
    return {
        ...styles,
        header: {
            ...styles.header,
            fontSize: headerFontSize,
            lineHeight: headerFontSize,
            paddingVertical: 2
        },
        subHeader: {
            ...styles.subHeader,
            fontSize: subheaderFontSize,
            lineHeight: subheaderFontSize + 1
        },
        iconSize: {
            height: iconSize
        },
        icon: {
            position: "absolute",
            top: iconTop,
            color: '#ffffff'
        },
        premiumIcon: {
            position: "absolute",
            color: "#fff",
            top: premiumIconTop,
            width: premiumIconWidth,
            height: premiumIconHeight,
        },
        textContainer: {
            ...styles.textContainer,
            paddingTop: 4,
            paddingBottom: 3,
        }
    }

}

export const getDirectionMarkerStyles = (options) => {

    const markerStyles = getMarkerStyles(options);

    let iconSize = options.markerSize == "small" ? 24 : 32;
    let iconTop = options.markerSize == "small" ? 8 : 9;

    return StyleSheet.create({
        ...markerStyles,
        iconSize: {
            height: iconSize
        },
        icon: {
            top: iconTop,
            color: '#ffffff',
            position: "absolute"
        }
    });
}

const placeMarkerHeight = 36;

export const getPlaceMarkerStyles = (displayDir) => {

    const isLeft = displayDir == "left";
    
    const markerStyles = getMarkerStyles({});

    return StyleSheet.create({
        ...markerStyles,
        markerContainer: {
            ...markerStyles.markerContainer,
            height: placeMarkerHeight
        },
        markerContainerWeb: {
            ...markerStyles.markerContainerWeb,
            height: placeMarkerHeight
        },
        markerBG: {
            ...markerStyles.markerBG,
            height: placeMarkerHeight,
            color: colors.place
        },
        iconSize: {
            height: 18
        },
        icon: {
            top: 5,
            color: '#ffffff',
            position: "absolute"
        },
        flyoutContainer: {
            ...markerStyles.flyoutContainer,
            height: placeMarkerHeight-1,
            ...(isLeft && {
                right: 0
            })
        },
        flyoutContainerWeb: {
            ...markerStyles.flyoutContainerWeb,
            height: placeMarkerHeight-1,
            ...(isLeft && {
                right: 0
            })
        },
        textContainer: {
            paddingLeft: 35,
            paddingRight: 10,
            paddingTop: 3,
            paddingBottom: 2,
            backgroundColor: "#ffffff",
            marginBottom: "auto",
            // ...activeBGStyles,
            ...(isLeft ? {
                paddingLeft: 10,
                paddingRight: 18,
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8
            } : {
                paddingLeft: 18,
                paddingRight: 10,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8
            })
        },
        header: {
            ...markerStyles.header,
            fontSize: 10,
            lineHeight: 10
        },
        subHeader: {
            ...markerStyles.subHeader,
            fontSize: 9,
            lineHeight: 9
        },
        logoFlyoutContainer: {

        },
        logoFlyoutContainerWeb: {
            position: "absolute",
            bottom: 0,
        },
        logoContainer: {
            paddingLeft: 20,
            paddingRight: 8,
            paddingTop: 6,
            paddingBottom: 6,
            marginBottom: 12,
            backgroundColor: "#ffffff",
            borderRadius: 8,
            
        },
        logo: {
            height: 40,
            marginBottom: 4
        }
    });
};

export const getPointMarkerStyles = ({color, displayDir}) => {

    const placeStyles = getPlaceMarkerStyles(displayDir);

    return StyleSheet.create({
        ...placeStyles,
        markerBG: {
            ...placeStyles.markerBG,
            color: color
        }
    });
};