import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StyleSheet, Text, View, TouchableWithoutFeedback, Dimensions } from "react-native";
import { FontAwesome5, Ionicons } from '@expo/vector-icons';

import { types } from "redux/types";
import { getDistanceStr } from "utils/distance";
import {
    viewRow,
    colorNames
} from "styles/shared";
import { getLangTerm } from "lang";

const win = Dimensions.get('window');

const sharedArrowButtonStyles = {
    width: 70,
    alignItems: "center",
    justifyContent: "center"
}

const walkingHeaderStyles = StyleSheet.create({
    walkingDirectionsHeader: {
        display: "flex",
        alignItems: "center"
    },
    walkingDirectionsHeaderInner: {
        maxWidth: 600,
        flexDirection: "row",
        width: win.width - 20,
        backgroundColor: colorNames.actionGreen,
        marginHorizontal: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 10,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    walkingDirections: {
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    previousButton: {
        ...sharedArrowButtonStyles
    },
    nextButton: {
        ...sharedArrowButtonStyles
    },
    buttonSpacer: {
        width: sharedArrowButtonStyles.width
    },
    icon: {
        color: "#000",
        marginBottom: 5
    },
    iconSize: {
        height: 16
    },
    buttonText: {
        color: "#000",
        fontSize: 13,
        textAlign: "center",
        justifyContent: "space-between"
    },
    walkingHeader: {
        // marginBottom: 5,
        color: "#FFFFFF",
        fontSize: 20,
        fontFamily: "bold",
        textAlign: "center"
    },
    walkingSubheader: {
        ...viewRow
    }
});

export const WalkingHeader = () => {

    const lang = useSelector(state => state.settings.lang);

    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const selectedStopData = useSelector(state => state.mapData.selectedStopData);
    const showFooterList = useSelector(state => state.mapData.showFooterList);
    const isWalkingRoute = useSelector(state => state.mapData.isWalkingRoute);
    const reachedDestination = useSelector(state => state.mapData.reachedDestination);
    const inBoundaries = useSelector(state => state.mapData.inBoundaries);
    const locationPreference = useSelector(state => state.user.locationPreference);

    const dispatch = useDispatch();
    const setWalkingRoute = (routeSlug, stopSlug) => {
        dispatch({
            type: types.SET_WALKING_ROUTE_STOP,
            routeSlug,
            stopSlug,
            lang,
            userActionData: { routeSlug, stopSlug }
        });
    }
    const showDetailsPage = (markerData) => {
        dispatch({
            type: types.SHOW_DETAILS_PAGE,
            detailsPageData: markerData,
            userActionData: { slug: markerData.slug }
        });
    }
    const setMapToPoint = (latlng) => {
        dispatch({
            type: types.SET_MAP_TO_POINT,
            point: latlng
        });
    }

    if(!showFooterList && isWalkingRoute) {

        let onPress, content;

        if(selectedStopData) {
            // THIS IS IF A ROUTE STOP IS SELECTED
            let { title } = selectedStopData;
            if (reachedDestination) {
                onPress = () => {
                    showDetailsPage(selectedStopData)
                };
                content = <>
                    <Text style={walkingHeaderStyles.walkingHeader} >{ title }</Text>
                    <Text style={walkingHeaderStyles.walkingSubheader} >{ `${getLangTerm("you-are-here", lang)} ${getLangTerm("press-for-details", lang)}` }</Text>
                </>;
            } else {
                onPress = () => {
                    setMapToPoint(selectedStopData.latlng);
                    showDetailsPage(selectedStopData);
                }
                const distanceStr = getDistanceStr(selectedStopData.minutes, lang)
                content = <>
                    <Text style={walkingHeaderStyles.walkingHeader} >{ title }</Text>
                    { locationPreference && inBoundaries && selectedStopData?.distance && <View style={walkingHeaderStyles.walkingSubheader} >
                        <Ionicons name="ios-walk" size={walkingHeaderStyles.iconSize.height} style={walkingHeaderStyles.walkingIcon} />
                        <Text>{`${(Math.ceil(selectedStopData.distance))}m${distanceStr ? ` - ${distanceStr}` : ""}`}</Text>
                    </View> }
                </>
            }
        } else {
            // THIS IS IF THE ROUTE START IS SELECTED
            const { title } = selectedRouteData;
            if(reachedDestination) {
                onPress = () => {
                    setMapToPoint(selectedRouteData.latlng);
                    showDetailsPage(selectedRouteData);
                }
                content = <>
                    <Text style={walkingHeaderStyles.walkingHeader} >{ title }</Text>
                    <Text style={walkingHeaderStyles.walkingSubheader} >{ `${getLangTerm("you-are-here", lang)} ${getLangTerm("press-for-details", lang)}` }</Text>
                </>
            } else {
                onPress = () => {
                    setMapToPoint(selectedRouteData.latlng);
                    showDetailsPage(selectedRouteData);
                };
                content = <>
                    <Text style={walkingHeaderStyles.walkingSubheader} >{ getLangTerm("walk-to", lang) }</Text>
                    <Text style={walkingHeaderStyles.walkingHeader} >{ title }</Text>
                    <Text style={walkingHeaderStyles.walkingSubheader} >{ getLangTerm("start", lang) }</Text>
                    { locationPreference && inBoundaries && selectedRouteData?.distance && <View style={walkingHeaderStyles.walkingSubheader} >
                        <Ionicons name="ios-walk" size={walkingHeaderStyles.iconSize.height} style={walkingHeaderStyles.walkingIcon} />
                        <Text>{getDistanceStr(selectedRouteData.minutes, lang)}</Text>
                    </View> }
                </>
            }
        }

        let prevButton = <View style={walkingHeaderStyles.buttonSpacer}></View>;
        let nextButton = <View style={walkingHeaderStyles.buttonSpacer}></View>;

        if (isWalkingRoute) {
            let stopRouteIndex = -1
            selectedStopData && selectedRouteData.stops.forEach((stop, i) => {
                if(stop == selectedStopData.slug) {
                    stopRouteIndex = i;
                }
            });

            if (stopRouteIndex > -1) {
                prevButton = <TouchableWithoutFeedback key="previous" onPress={() => {
                    const previousStopSlug = selectedRouteData.stops[stopRouteIndex - 1];
                    setWalkingRoute(selectedRouteData.slug, previousStopSlug);
                }}>
                    <View style={walkingHeaderStyles.previousButton}>
                        <FontAwesome5 name="arrow-left" size={walkingHeaderStyles.iconSize.height} style={walkingHeaderStyles.icon} />
                        <Text style={walkingHeaderStyles.buttonText}>{ getLangTerm("previous", lang) }</Text>
                    </View>
                </TouchableWithoutFeedback>;
            }

            if (stopRouteIndex < selectedRouteData.stops?.length - 1) {
                nextButton = <TouchableWithoutFeedback key="next" onPress={() => {
                    const nextStopSlug = selectedRouteData.stops[stopRouteIndex + 1];
                    setWalkingRoute(selectedRouteData.slug, nextStopSlug);
                }}>
                    <View style={walkingHeaderStyles.nextButton}>
                        <FontAwesome5 name="arrow-right" size={walkingHeaderStyles.iconSize.height} style={walkingHeaderStyles.icon} />
                        <Text style={walkingHeaderStyles.buttonText}>{ getLangTerm("next", lang) }</Text>
                    </View>
                </TouchableWithoutFeedback>;
            }
        }



        return <TouchableWithoutFeedback onPress={onPress}>
            <View style={walkingHeaderStyles.walkingDirectionsHeader}>
                <View style={walkingHeaderStyles.walkingDirectionsHeaderInner}>
                    { prevButton }
                    <View style={walkingHeaderStyles.walkingDirections}>
                        { content }
                    </View>
                    { nextButton }
                </View>
            </View>
        </TouchableWithoutFeedback>
    }

    return null;
}