import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { types } from "redux/types";
import { shadeColor } from "utils/map";
import RouteMarker from "../markers/RouteMarker";
import StopMarker from "../markers/StopMarker";
import DirectionMarker from "../markers/DirectionMarker";
import PlaceMarker from "../markers/PlaceMarker";
import PointMarker from "../markers/PointMarker";
import LandingPointMarker from "../markers/LandingPointMarker";
import UserPosition from "../markers/UserPosition";
import { initialRegion } from "redux/reducers/mapDataReducer";
import MarkerContextProvider from "../markers/MarkerContext";
import { toLatLng, panTo, calcZoom } from "utils/map";
import { getRouteColor, colorNames } from "styles/shared";
import { getLangTerm } from "lang";

const mapStyle = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#131c2f"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#287773"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#DDDDDD"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#b3dbcf"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#b3dbcf"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },

    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made.building",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];

const offlineNoticeContainer = {
    position: "absolute",
    bottom: 100,
    width: "100%",
    marginHorizontal: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
}

const offlineNotice = {
    backgroundColor: "#fff",
    borderWidth: 2,
    borderColor: colorNames.actionRed,
    padding: 5,
    borderRadius: 10
}

/* *********** */
/* This IS the web map. */
/* *********** */

const WalkingMap = (() => {

    const [fixedElements, setFixedElements] = useState([]);
    const [dynamicElements, setDynamicElements] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapReady, setMapReady] = useState(false);
    const [prevAnimationTS, setPrevAnimationTS] = useState(0);
    const [prevSelectedRouteSlug, setPrevSelectedRouteSlug] = useState(null);

    const insets = useSafeAreaInsets();

    const lang = useSelector(state => state.settings.lang);

    const routeDataLoaded = useSelector(state => state.mapData.routeDataLoaded);
    const routeData = useSelector(state => state.mapData.routeData);
    const routeStops = useSelector(state => state.mapData.routeStops);
    const placesLoaded = useSelector(state => state.mapData.placesLoaded);
    const places = useSelector(state => state.mapData.places);
    const animateTS = useSelector(state => state.mapData.animateTS);
    const animationDuration = useSelector(state => state.mapData.animationDuration);
    const region = useSelector(state => state.mapData.region);
    const selectedRouteData = useSelector(state => state.mapData.selectedRouteData);
    const isWalkingRoute = useSelector(state => state.mapData.isWalkingRoute);
    

    const dispatch = useDispatch();

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyD_8viZi14GplCN-MQvvEgtegVUhmXGgps"
    })

    const onMapReady = (map) => {
        // console.log("ONMAPREADY");
        map.setCenter(toLatLng(initialRegion));
        // setMapWidth(screen.width); // trigger a re-render in google maps that displays everything better
        setMapInstance(map);
        setMapReady(true);
        
        // trigger a rerender so the map will have the newest data
        dispatch({
            type: types.SET_MAP_READY
        });
    }

    useEffect(() => {
        if (mapReady && region && animateTS > prevAnimationTS) {
            setPrevAnimationTS(animateTS);

            // if it's a new route, don't animate, just jump to the new region
            if (selectedRouteData && selectedRouteData.slug !== prevSelectedRouteSlug) {
                setPrevSelectedRouteSlug(selectedRouteData.slug);
                // mapInstance.setCenter(toLatLng(region));
                // const newBounds = getBoundsfromRegion(region);
                // mapInstance.panToBounds(newBounds);
                mapInstance.setZoom(calcZoom(region));
                mapInstance.setCenter(toLatLng(region));
            } else {
                panTo(mapInstance, toLatLng(region), animationDuration);
                // mapInstance.setZoom(calcZoom(region));
            }
        }
    }, [prevAnimationTS, animateTS, animationDuration, region, mapInstance, mapReady]);

    useEffect(() => {
        const mapElements = [];
        if (routeDataLoaded) {
            const isTest = window?.location?.search?.indexOf("t=test") > -1;
            routeData?.forEach((route) => {
                if (route.published === false && !isTest) {
                    return;
                }
                mapElements.push(
                    <RouteMarker
                        route={route}
                        key={route.slug}
                    />
                );
    
                if (route.extraPathsArray) {
                    route.extraPathsArray.forEach((extraPath, i) => {
                        mapElements.push(<Polyline
                            key={`path-${route.routeSlug}-extra-lightened-${i}`}
                            path={extraPath.map(i => toLatLng(i))}
                            options={{
                                strokeWidth: 6,
                                strokeColor: shadeColor(getRouteColor(route.slug), 80),
                            }}
                        />);
                        // mapElements.push(<Polyline
                        //     key={`path-${route.routeSlug}-extra-${i}`}
                        //     path={extraPath.map(i => toLatLng(i))}
                        //     options={{
                        //         strokeWidth: 6,
                        //         strokeColor: getRouteColor(route.slug),
                        //         lineDashPattern: [5, 5],
                        //     }}
                        // />);
                    })
                }
    
                if (route.pathArray) {
                    mapElements.push(<Polyline
                        key={`path-${route.routeSlug}`}
                        path={route.pathArray.map(i => toLatLng(i))}
                        options={{
                            strokeWidth: 6,
                            strokeColor: getRouteColor(route.slug)
                        }}
                    />);
                }
            });
            setFixedElements(mapElements);
        }
    }, [routeDataLoaded, routeData]);

    useEffect(() => {
        let mapElements = [];
        if (routeStops && selectedRouteData && placesLoaded) {
            // if it's Stanley Park, show the other Stanley Park route stops as well
            const selectedRouteStops = []
            if (selectedRouteData.slug.includes("stanley-park")) {
                selectedRouteStops.push(...routeStops["stanley-park-seawall"]);
                selectedRouteStops.push(...routeStops["stanley-park-inner"]);
            } else {
                selectedRouteStops.push(...routeStops[selectedRouteData.slug]);
            }

            // Render the route stops for the selected routes
            selectedRouteStops?.forEach((stop) => {

                // a quick hack to show both of the Stanley Park routes stops at the same time
                const validStanleyPark = selectedRouteData?.slug.includes("stanley-park") && stop.routeID.includes("stanley-park")

                if(selectedRouteData?.slug !== stop.routeID && !validStanleyPark) {
                    return null;
                }

                if (stop.stopType == "endpoint" || stop.stopType == "direction" || stop.stopType == "info") {
                    mapElements.push(
                        <DirectionMarker
                            key={stop.slug}
                            stop={stop}
                        />
                    );
                } else {
                    mapElements.push(
                        <StopMarker
                            key={`${lang}-${stop.routeRNG1}`}
                            stop={stop}
                        />
                    );

                    if (stop.landingPoint) {
                        mapElements.push(
                            <LandingPointMarker
                                key={`${lang}-${stop.routeRNG1}-landing-point`}
                                stop={stop}
                            />
                        )
                    }
                }
            });

            // render the points from here, they are dynamic
            routeData?.forEach((route) => {
                // Render the route points
                const validStanleyPark = selectedRouteData?.slug.includes("stanley-park") && route.slug.includes("stanley-park")
                if ( isWalkingRoute && route.points && (route.slug == selectedRouteData.slug || validStanleyPark)) {
                    route.points.forEach((point, i) => {
                        mapElements.push(
                            <PointMarker
                                key={`${route.slug}-point-${i}`}
                                i={i}
                                route={route}
                                point={point}
                            />
                        );
                    })
                }
            });

            // Render the places
            places?.forEach(place => {
                mapElements.push(
                    <PlaceMarker
                        key={`${lang}-${place.slug}`}
                        place={place}
                    />
                );
            });

            setDynamicElements(mapElements);
        }
    }, [routeData, routeStops, selectedRouteData, placesLoaded, places]);

    return <MarkerContextProvider>

        { isLoaded && <GoogleMap
            mapContainerStyle={{
                // width: mapWidth,
                height: "100%",
                paddingTop: insets.top + 50
            }}
            zoom={16}
            //set the center using the onMapReady function
            options={{
                styles: mapStyle,
                streetViewControl: false,
                zoomControl: false,
                fullscreenControl: false,
                mapTypeControl: false
            }}
            onLoad={onMapReady}
        >
            { fixedElements }
            { dynamicElements}
            <UserPosition />
        </GoogleMap> }
        { !window.navigator.onLine && <View style={offlineNoticeContainer}>
            <View style={offlineNotice}>
                <Text>{ getLangTerm("offline-notice", lang) }</Text>
            </View>
        </View> }
    </MarkerContextProvider>
});

export default WalkingMap