import React from "react";
import { InfoBox } from '@react-google-maps/api';

import { toLatLng } from "utils/map";
import { TouchableWithoutFeedback, View } from "react-native";

/* **** */
/* This IS the web version of BaseMarker. */
/* **** */

export const BaseMarker = ({ coordinate, onPress, style, children }) => {
    // onPress isn't needed here, the touch event is handled by the textmarker
    return <InfoBox
        position={toLatLng(coordinate)}
        zIndex={style.zIndex}
        options={{
            disableAutoPan: true,
            closeBoxURL: ""
        }}
    >
        <TouchableWithoutFeedback
            onPress={onPress && onPress}
        >
            <View style={{ ...style }}>
                { children }
            </View>
        </TouchableWithoutFeedback>

    </InfoBox>;
}