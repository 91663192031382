import AsyncStorage from "@react-native-async-storage/async-storage";
import config from "./apiConfig";

import { types } from "../types";
import { UserActionDuration } from "utils/userActions";

const getPlacesDataToken = (lang) => {
    return `places-${lang}`;
}

export const placesAPI = () => next => action => {

    const placesLookup = async(placesTimestamp, lang) => {
        const uad = new UserActionDuration();

        const apiClient = config.getInstance();
        const get = () => apiClient.get(`/places/${lang}`);

        const res = await get().catch((e) => {
            console.log("Error looking up places");
            console.log(e); 
        });
        
        if (!res || !res.data) {
            const duration = uad.duration;
            return next({
                type: types.PLACES_LOOKUP_FAILURE,
                duration
            });
        }
        
        const placeData = res.data.placeSlugs;
        const placeLangData = res.data.placeLang;
        const places = [];
        const langSlugs = {};
        const langArticles = {};
        
        placeLangData.forEach((langItem) => {
            const { content, slug, subtitle, title, slugRNG, articleTitle } = langItem;
            if(slugRNG.indexOf("place#v#0#") > -1) {
                langSlugs[slug] = {
                    content,
                    slug,
                    subtitle,
                    title
                };
            } else {
                const placeSlug = slug;
                langArticles[placeSlug] = langArticles[placeSlug] || [];
                langArticles[placeSlug].push({
                    content,
                    slug,
                    articleTitle
                })
            }
        });
        placeData.forEach((placeItem) => {

            if (placeItem.isParent) return;

            const slug = placeItem.slug;
            let articles = langArticles[slug] || [];
            if (placeItem.parentPlace) {
                articles = [...articles, ...langArticles[placeItem.parentPlace]]
            }
            places.push({
                ...placeItem,
                ...langSlugs[slug],
                articles
            })
        })

        // const placesToken = `places-${lang}-${placesTimestamp}`;
        const placesToken = getPlacesDataToken(lang);

        await AsyncStorage.setItem(placesToken, JSON.stringify({places, timestamp: placesTimestamp}));

        const duration = uad.duration;
        return next({
            type: types.PLACES_LOOKUP_SUCCESS,
            places,
            duration
        });
    }
    
    const checkPlacesCache = async(placesTimestamp, lang) => {
        const uad = new UserActionDuration();

        // if there is no connection, load the most recent data?
        if(!placesTimestamp || !lang) {
            return;
        }
        const placesToken = getPlacesDataToken(lang);
        let placesCachedData = await AsyncStorage.getItem(placesToken);
        placesCachedData = JSON.parse(placesCachedData);

        if (!placesCachedData || !placesCachedData.timestamp || placesCachedData.timestamp < placesTimestamp) {
            placesLookup(placesTimestamp, lang);
        } else {
            const duration = uad.duration;
            
            return next({
                type: types.PLACES_LOOKUP_SUCCESS,
                places: placesCachedData.places,
                duration,
                userActionData: { fromCache: true }
            });
        }
    };

    next(action);
    switch (action.type) {
        case types.PLACES_LOOKUP: {
            checkPlacesCache(action.placesTimestamp, action.lang);
            break;
        }
        case types.LANG_CHANGED: {
            placesLookup(action.placesTimestamp, action.lang);
            break;
        }
        default:
            break;
    }
};