import { types } from "../types";
import { getParamsFromURI } from "utils/uri";
import store from "../root";

export const uriAPI = () => next => action => {

    const startURIWatcher = async () => {
        // watch the uri for changes
        // if the uri changes, dispatch an action to update the state
        window.onpopstate = async () => {
            // get the new state from the URI and dispatch an action to update the app state
            const uriState = await getParamsFromURI();
            store.dispatch({
                type: types.URI_CHANGED,
                uriState
            });
        };
    }

    const prepareUrl = (params) => {
        const url = new URL(location.href)
        const queryParams = new URLSearchParams(location.search)
        let customUrl = url.protocol + "//" + url.hostname + url.pathname.replace(/\/$/, '')
        // console.log(url)
        // console.log(queryParams)
        // console.log(`posting url ${customUrl}`)
        for (const paramName of params) {
            const paramValue = queryParams.get(paramName)
            if (paramValue) customUrl = customUrl + '/' + paramValue
        }
        return customUrl
    }

    const updateURI = async (action) => {

        // Destructure the action object to get routeSlug and stopSlug
        const { routeSlug, stopSlug, placeSlug, lang, details, offlineMode } = action;
        // console.log("updateURI", action);
      
        // update the uri to include the route
        // nothing is selected, use /
        // if it's just a route, use /routes/:routeSlug
        // if it has selected stop, use /routes/:routeSlug/:stopSlug
        // if the user has a language selected, add that to the uri as well
      
        let uri = '/';

        if (placeSlug) {
            uri = `/place/${placeSlug}`;
        }
        if (routeSlug) {
            uri = `/route/${routeSlug}`;
        }
        if (stopSlug) {
            uri += `/${stopSlug}`;
        }
        if (lang && lang !== 'en') {
            uri = `/${lang}${uri}`;
        }

        if (details) {
            uri += "/details";
        }

        if (offlineMode) {
            uri = `/offline${uri}`;
        }
      
        // const search = new URLSearchParams(window.location.search).toString();
        // const url = `${uri}${search ? `?${search}` : ''}`;
        const url = `${uri}`;

        if (url !== window.location.pathname) {
            // console.log("About to pushState")
            // console.log(window.location.pathname)
            // console.log(url)
            window.history.pushState({},"",url)
            console.log(url)
        }
      
        // make a plausible pageview here
        if (typeof plausible === 'undefined') return
        // eslint-disable-next-line no-undef
        plausible('pageview', { u: prepareUrl(["repID"]) })

    }

    const updateURIWithNewDetails = async (action) => {

        let newURIState = {};
        const storeState = store.getState();
        newURIState.lang = storeState.settings.lang;
        newURIState.offlineMode = action.offlineMode;
        if (action.detailsPageData) {
            // this might not be the same as the selected stop, if they have just clicked one off the map
            const details = action.detailsPageData;
            newURIState.details = true;
            if (details.slugIndex === "place") {
                newURIState.placeSlug = details.slug;
            } else {
                newURIState.routeSlug = details.routeID;
                if (details.stopType !== "route-start") {
                    newURIState.stopSlug = details.slug;
                }
            }
        } else {
            const mapData = storeState.mapData;
            if (mapData.selectedRouteData) {
                newURIState.routeSlug = mapData.selectedRouteData.slug;
                if (mapData.selectedStopData) {
                    newURIState.stopSlug = mapData.selectedStopData.slug;
                }
            }
        }

        updateURI(newURIState);
    }

    const updateURIWithNewRoute = async (action) => {
        const { routeSlug, offlineMode } = action;
        const otherParams = getParamsFromURI();

        updateURI({ ...otherParams, routeSlug, offlineMode });
    }

    const updateURIWithNewRouteAndStop = async (action) => {
        const { routeSlug, stopSlug } = action;
        const { lang } = getParamsFromURI();

        updateURI({ lang, routeSlug, stopSlug });
    }

    const updateURIWithNewLanguage = async (action) => {
        const { lang } = action;
        const otherParms = getParamsFromURI();

        updateURI({ ...otherParms, lang });
    }

    next(action);
    switch (action.type) {
        case types.ROUTE_DATA_LOOKUP_SUCCESS:
        {
            startURIWatcher();
            break;
        }
        case types.SINGLE_ROUTE_LOOKUP_SUCCESS:
        {
            updateURIWithNewRoute(action);
            break;
        }
        case types.SET_WALKING_ROUTE_STOP:
        case types.EXIT_WALKING_ROUTE:
        {
            updateURIWithNewRouteAndStop(action);
            break;
        }
        case types.SET_MAP_TO_POINT:
        case types.SHOW_DETAILS_PAGE:
        {
            updateURIWithNewDetails(action);
            break;
        }
        case types.LANG_CHANGED:
        case types.LANG_LOADED:
        {
            updateURIWithNewLanguage(action);
            break;
        }
        default:
            break;
    }
};