import { StyleSheet } from 'react-native';
import { colors, colorNames, fullscreenView, screenContainer } from "styles/shared";

export const routesPageStyles = StyleSheet.create({
    screenContainer,
    container: {
        ...fullscreenView,
        zIndex: 2,
        backgroundColor: colorNames.landLight
    },
    scrollView: {
        flex: 1,
        backgroundColor: colorNames.landLight
    },
    header: {
        display: "flex",
        marginBottom: 15
    },
    iconsContainer: {
        position: "absolute",
        zIndex: 1,
        width: 80,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 5,
        marginLeft: 12,
    },
    gear: {
        width: 32,
        height: 32,
        color: colors.headerGrey
    },
    questionMarkContainer: {
        // marginLeft: 30,
    },
    questionMarkText: {
        fontSize: 32,
        fontFamily: "bold",
        color: colors.headerGrey
    },
    logo: {
        height: 150,
        width: "auto",
        marginTop: 10
    },
    title: {
        fontSize: 28,
        fontFamily: "semi-bold",
        color: colorNames.parksGreen,
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 18,
        color: colorNames.parksGreen,
        textAlign: 'center'
    },

    warningContainer: {
        maxWidth: 600,
        marginHorizontal: "auto",
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.actionRed,
        backgroundColor: "#fff"
    },
    warningText: {
    }
});

export const routesListStyles = StyleSheet.create({
    routeList: {
        alignItems: "center",
    },
    routeListInner: {
        maxWidth: 600,
        marginHorizontal: 10,
    },
    borderContainer: {
        height: 30,
        borderRadius: 8,
        marginBottom: -22
    },
    listItem: {
        flexDirection: "row",
        marginBottom: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        borderWidth: 2,
        borderColor: colorNames.parksGreen,
    },
    itemRow: {
        display: "flex",
        flexDirection: "row"
    },
    thumb: {
        height: 93,
        width: 93,
        borderRadius: 5,
        margin: 10,
        marginRight: 14,
    },
    textContainer: {
        flex: 1,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
    },
    itemHeader: {
        fontFamily: "bold",
        fontSize: 22,
    },
    walkingTime: {
        marginBottom: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    walkingIcon: {
        marginRight: 5,
    },
    walkingIconSize: {
        height: 18
    },
    walkingText: {
        fontSize: 18,
        color: colors.subtitleColor
    },
    metadataItem: {
        display: "flex",
        flexDirection: "row"
    },
    metadataIcon: {
        fontSize: 6,
        margin: 5,
    },
    metadataText: {
        fontSize: 16
    },
    description: {
        paddingHorizontal: 10,
        paddingBottom: 10,
        fontSize: 18
    },
    premiumContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 5,
        marginBottom: 5
    },
    premiumIcon: {
        width: 20,
        height: 18,
        marginRight: 7
    },
    premiumText: {
        fontSize: 15
    },
    showPurchaseButtonContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10
    },
    showPurchaseButton: {
        borderRadius: 15,
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    showPurchaseButtonText: {
        color: "#fff",
        fontSize: 18,
        fontFamily: "bold",
        cursor: "pointer"
    },
    error: {
        fontSize: 12,
        color: colorNames.actionRed,
        marginVertical: 10,
        textAlign: "center"
    },
});