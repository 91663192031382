import React from "react";
import { View } from "react-native";
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

import { getRouteColor } from "styles/shared";
import LocalImage from "../components/shared/LocalImage";

export const getStopImageorIcon = ({stop, styles, thumbSize, timestamp}) => {
    let image = <></>;
    switch(stop.stopType) {
        case "direction":
            image = <View style={{
                ...styles.iconThumb,
                backgroundColor: getRouteColor(stop.routeID)
            }}>
                <FontAwesome name={"map-signs"} size={24} style={styles.icon} />
            </View>;
            break;
        case "info":
            image = <View style={{
                ...styles.iconThumb,
                backgroundColor: getRouteColor(stop.routeID)
            }}>
                <FontAwesome name={"info"} size={24} style={styles.icon} />
            </View>;
            break;
        case "endpoint":
            image = <View style={{
                ...styles.iconThumb,
                backgroundColor: getRouteColor(stop.routeID)
            }}>
                <FontAwesome5 name={"flag-checkered"} size={24} style={styles.icon} />
            </View>;
            break;
        case "stop": {
            let targetImage = stop.images && stop.images[0];
            if (stop.thumbnail) {
                targetImage = stop.images.filter(image => {
                    return stop.thumbnail === `${image.imageSlug}-${image.imageNum}`
                })[0];
            }
            image = <LocalImage
                style={styles.thumb}
                image={targetImage}
                imageSize={thumbSize}
                timestamp={timestamp}
            />
            break;
        }
    }
    return image;
}