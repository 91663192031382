import React from "react";
import { StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { getLangTerm } from "lang";
import { colorNames, colors } from "styles/shared";
import { types } from "redux/types";
import { Close } from "../SVGs";

const noticeStyles = StyleSheet.create({
    container: {
        alignItems: "center",
    },
    containerInner: {
        maxWidth: 600,
        marginHorizontal: 10,
        flexDirection: "row",
    },
    containerBorder: {
        flex: 1,
        borderWidth: 1,
        backgroundColor: "#FFFFFF",
        borderColor: colorNames.parksGreen,
        borderRadius: 15,
        paddingTop: 24,
        paddingBottom: 10,
        paddingHorizontal: 10,
        marginBottom: 10,
        display: "flex",
        alignItems: "center",
    },
    header: {
        color: "#000000",
        fontFamily: "bold",
        fontSize: 16,
        marginBottom: 12
    },
    button: {
        backgroundColor: colorNames.parksGreen,
        paddingHorizontal: 40,
        paddingVertical: 10,
        marginBottom: 12,
        borderRadius: 20,
        cursor: "pointer"
    },
    buttonText: {
        fontSize: 18,
        fontFamily: "bold",
        color: colorNames.white,
    },
    subHeader: {
        fontSize: 14,
        color: "#000",
        marginBottom: 12,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        top: 8,
        left: 8
    },
    closeIcon: {
        color: colors.headerGrey
    }
});

const LocationNotice = () => {

    const lang = useSelector(state => state.settings.lang);
    const locationPreferenceChecked = useSelector(state => state.user.locationPreferenceChecked);
    const locationPreference = useSelector(state => state.user.locationPreference);

    const dispatch = useDispatch();

    const setLocationPreference = (locationPreference) => {
        dispatch({
            type: types.USER_SET_LOCATION_PREFERENCE,
            locationPreference,
            userActionData: { locationPreference}
        });

    }
    const userLocationAllow = () => {
        setLocationPreference(true);
    };

    const dismissNotice = () => {
        setLocationPreference(false);
    }

    if (!locationPreferenceChecked || locationPreference !== null) {
        return null;
    }

    return <View style={noticeStyles.container}>
        <View style={noticeStyles.containerInner}>
            <View style={noticeStyles.containerBorder}>
                <Text style={noticeStyles.header}>{ getLangTerm("location-header", lang) }</Text>
                <Text style={noticeStyles.subHeader}>{ getLangTerm("update-location-settings", lang) }</Text>
                <Text style={noticeStyles.subHeader}>{ getLangTerm("location-subheader", lang) }</Text>
                <TouchableWithoutFeedback onPress={() => userLocationAllow()}>
                    <View style={noticeStyles.button}>
                        <Text style={noticeStyles.buttonText}>{ getLangTerm("allow", lang) }</Text>
                    </View>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={dismissNotice}>
                    <View style={{...noticeStyles.closeButton}}>
                        <Close style={{...noticeStyles.closeIcon}} size={16} />
                    </View>
                </TouchableWithoutFeedback>
            </View>
        </View>
    </View>
}
export default LocationNotice